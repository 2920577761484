import React from 'react'

export default function EmptyTaxIcon() {
  return (
    <svg
      width='354'
      height='208'
      viewBox='0 0 354 208'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M182.861 48.9456L150.908 90.2674C149.904 91.773 147.897 91.9403 146.391 90.7693L106.575 59.4852C105.069 58.4814 104.902 56.4739 106.073 54.9682L147.562 2.2704C148.566 0.764743 150.574 0.597446 152.079 1.76851L181.021 24.5206L182.861 48.9456Z'
        fill='white'
        stroke='#D6DCE8'
        stroke-width='2'
        stroke-miterlimit='10'
      />
      <path
        d='M169.417 29.0349L150.178 13.9784C149.843 13.6438 149.676 13.1419 150.011 12.4727C150.345 12.1381 150.847 11.9708 151.516 12.3054L170.755 27.362C171.09 27.6965 171.257 28.1984 170.922 28.8676C170.42 29.3695 169.919 29.3695 169.417 29.0349Z'
        fill='#D6DCE8'
      />
      <path
        d='M154.036 24.3493L146.842 18.494C146.507 18.1594 146.34 17.6576 146.675 16.9884C147.009 16.4865 147.511 16.4865 148.18 16.8211L155.374 22.6764C155.708 23.011 155.876 23.5129 155.541 24.182C155.039 24.6839 154.37 24.6839 154.036 24.3493Z'
        fill='#D6DCE8'
      />
      <path
        d='M181.088 24.5234L172.222 35.7322C170.883 37.4051 171.385 39.7473 172.891 40.9183L183.096 48.9485'
        fill='#D6DCE8'
      />
      <path
        d='M170.572 51.4499L160.534 43.7544L150.162 35.5569L140.626 28.0287C139.623 27.1922 137.95 27.3595 137.113 28.5305L123.395 45.9292C122.558 46.933 122.726 48.6059 123.73 49.4424L153.843 73.1982C154.846 74.0347 156.519 73.8674 157.356 72.6963L171.074 55.2977C171.91 53.792 171.743 52.4537 170.572 51.4499ZM138.786 51.2826L141.797 47.2675L150.999 54.4612L147.987 58.4763L138.786 51.2826ZM147.151 59.6474L144.307 63.3278L135.106 56.1342L137.95 52.4537L147.151 59.6474ZM155.014 49.4424L152.002 53.4575L142.801 46.2638L145.812 42.2487L155.014 49.4424ZM146.649 41.0776L149.995 37.0626L159.196 44.2562L156.185 48.2713L146.649 41.0776ZM163.378 62.3241L153.173 54.2939L156.185 50.2789L166.39 58.309L163.378 62.3241ZM141.63 45.26L131.76 37.5645L134.771 33.5494L144.641 41.2449L141.63 45.26ZM130.756 38.7355L140.626 46.4311L137.615 50.4462L127.745 42.7506L130.756 38.7355ZM152.337 55.465L162.542 63.4951L159.531 67.5102L149.326 59.4801L152.337 55.465ZM169.903 54.1266L167.393 57.3052L157.188 49.2751L160.2 45.26L169.568 52.621C170.237 52.9556 170.237 53.6247 169.903 54.1266ZM139.623 28.8651L148.656 36.0588L145.645 40.0739L135.775 32.3783L138.284 29.1997C138.619 28.6978 139.288 28.6978 139.623 28.8651ZM124.566 46.5984L126.741 43.9217L136.611 51.6172L133.767 55.2977L124.566 48.104C124.231 47.7694 124.231 46.933 124.566 46.5984ZM154.846 71.5253L145.478 64.1643L148.322 60.4838L158.527 68.514L156.185 71.358C155.85 72.0272 155.181 72.0272 154.846 71.5253Z'
        fill='#D5DDEA'
      />
      <path
        d='M260.917 75.3457H80.7294C77.0237 75.3457 73.7812 78.5892 73.7812 82.2961V198.599C73.7812 202.305 77.0237 200.915 80.7294 200.915H260.454C264.159 200.915 267.402 202.305 267.402 198.599V82.2961C267.865 78.5892 264.623 75.3457 260.917 75.3457Z'
        fill='#F1F3F9'
        stroke='#D6DCE8'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linejoin='round'
      />
      <path
        d='M254.88 82.1074H87.1927C83.7441 82.1074 80.7266 85.0111 80.7266 88.3297V192.448C80.7266 195.767 83.7441 194.522 87.1927 194.522H254.449C257.898 194.522 260.915 195.767 260.915 192.448V88.3297C261.346 85.0111 258.329 82.1074 254.88 82.1074Z'
        fill='white'
      />
      <path
        d='M294.683 194.426V198.133C294.683 202.303 290.977 206.01 286.808 206.01H54.7417C50.5728 206.01 46.8672 202.303 46.8672 198.133V194.426H294.683Z'
        fill='#F1F3F9'
        stroke='#D6DCE8'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linejoin='round'
      />
      <path
        d='M192.307 194.426V195.816C192.307 198.133 190.454 200.449 187.675 200.449H153.398C151.082 200.449 148.766 198.596 148.766 195.816V194.426H192.307Z'
        fill='#F1F3F9'
        stroke='#D6DCE8'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linejoin='round'
      />
      <path
        d='M189.065 136.042H173.779V120.751C173.779 118.898 172.389 117.508 170.536 117.508C168.684 117.508 167.294 118.898 167.294 120.751V136.042H152.008C150.155 136.042 148.766 137.432 148.766 139.286C148.766 141.139 150.155 142.529 152.008 142.529H167.294V157.82C167.294 159.673 168.684 161.063 170.536 161.063C172.389 161.063 173.779 159.673 173.779 157.82V142.529H189.065C190.917 142.529 192.307 141.139 192.307 139.286C192.307 137.432 190.917 136.042 189.065 136.042Z'
        fill='#D6DCE8'
        stroke='#AAB2C5'
        stroke-width='2'
        stroke-miterlimit='10'
        stroke-linejoin='round'
      />
      <path
        d='M294.301 134.441L243.058 162.181C240.096 163.767 236.421 162.695 234.803 159.726L208.32 110.66C206.736 107.695 207.809 104.015 210.775 102.395L262.018 74.655C264.98 73.0687 268.655 74.1411 270.273 77.1099L296.79 126.18C298.374 129.145 297.263 132.855 294.301 134.441Z'
        fill='#F1F3F9'
        stroke='#D6DCE8'
        stroke-width='2'
        stroke-miterlimit='10'
      />
      <path
        d='M287.504 128.625L242.449 153.013C241.187 153.708 239.606 153.218 238.911 151.956L217.016 111.383C216.322 110.12 216.812 108.537 218.073 107.841L263.128 83.4536C264.389 82.7579 265.971 83.2481 266.666 84.5107L288.561 125.084C289.255 126.346 288.765 127.93 287.504 128.625Z'
        fill='white'
      />
      <path d='M257.566 124.977L241.116 133.875L244.288 120.114L257.566 124.977Z' fill='#B0B7C4' />
      <path d='M271.172 117.599L247.494 130.431L252.073 110.622L271.172 117.599Z' fill='#CED6E2' />
      <path
        d='M238.38 118.193C240.317 118.41 242.062 117.013 242.279 115.073C242.495 113.134 241.101 111.387 239.164 111.171C237.227 110.955 235.481 112.351 235.264 114.291C235.048 116.23 236.443 117.977 238.38 118.193Z'
        fill='#B0B7C4'
      />
      <path
        d='M299.862 144.999L241.603 146.151C238.244 146.203 235.47 143.564 235.393 140.184L234.36 84.4365C234.312 81.0753 236.952 78.2973 240.331 78.2172L298.589 77.0649C301.949 77.0135 304.723 79.6518 304.8 83.0321L305.862 138.799C305.909 142.16 303.221 144.948 299.862 144.999Z'
        fill='#F1F3F9'
        stroke='#D6DCE8'
        stroke-width='2'
        stroke-miterlimit='10'
      />
      <path
        d='M296.477 136.709L245.255 137.72C243.815 137.76 242.634 136.599 242.596 135.158L241.744 89.0622C241.706 87.6217 242.868 86.4393 244.307 86.3995L295.529 85.3886C296.969 85.3487 298.15 86.5096 298.188 87.9501L299.04 134.046C299.078 135.487 297.916 136.669 296.477 136.709Z'
        fill='white'
      />
      <path d='M271.572 119.749L252.872 120.113L262.002 109.339L271.572 119.749Z' fill='#B0B7C4' />
      <path d='M287.021 119.438L260.095 119.983L273.247 104.48L287.021 119.438Z' fill='#CED6E2' />
      <path
        d='M257.638 104.923C259.26 106.004 261.452 105.563 262.533 103.939C263.615 102.315 263.177 100.123 261.554 99.0422C259.932 97.9618 257.74 98.4025 256.659 100.027C255.577 101.651 256.015 103.843 257.638 104.923Z'
        fill='#B0B7C4'
      />
      <path
        d='M54.3138 85.5762L87.0247 123.303C88.1256 124.606 87.8871 126.537 86.5559 127.666L48.555 159.901C47.2238 161.03 45.2338 160.888 44.1829 159.645L2.7485 110.61C1.64758 109.308 1.88607 107.377 3.21731 106.248L30.7498 82.8927L54.3138 85.5762Z'
        fill='white'
        stroke='#D6DCE8'
        stroke-width='2'
        stroke-miterlimit='10'
      />
      <g clip-path='url(#clip0_146_1218)'>
        <path
          d='M22.0234 90.6745L19.7266 92.6191L21.0633 94.198L23.3601 92.2534L22.0234 90.6745Z'
          fill='#D6DCE8'
        />
        <path
          d='M25.6797 90.2898L23.3828 92.2344L24.7195 93.8132L27.0164 91.8686L25.6797 90.2898Z'
          fill='#D6DCE8'
        />
        <path
          d='M24.6797 93.8093L22.3828 95.7539L23.7195 97.3327L26.0164 95.3881L24.6797 93.8093Z'
          fill='#D6DCE8'
        />
        <path
          d='M28.3359 93.4245L26.0391 95.3691L27.3758 96.948L29.6726 95.0034L28.3359 93.4245Z'
          fill='#D6DCE8'
        />
        <path
          d='M27.3516 96.9675L25.0547 98.9121L26.3914 100.491L28.6883 98.5463L27.3516 96.9675Z'
          fill='#D6DCE8'
        />
        <path
          d='M31.0078 96.5827L28.7109 98.5273L30.0476 100.106L32.3445 98.1616L31.0078 96.5827Z'
          fill='#D6DCE8'
        />
        <path
          d='M30.0078 100.102L27.7109 102.047L29.0476 103.626L31.3445 101.681L30.0078 100.102Z'
          fill='#D6DCE8'
        />
        <path
          d='M33.6641 99.7175L31.3672 101.662L32.7039 103.241L35.0008 101.296L33.6641 99.7175Z'
          fill='#D6DCE8'
        />
        <path
          d='M32.6797 103.26L30.3828 105.205L31.7195 106.784L34.0164 104.839L32.6797 103.26Z'
          fill='#D6DCE8'
        />
        <path
          d='M36.3359 102.876L34.0391 104.82L35.3758 106.399L37.6726 104.455L36.3359 102.876Z'
          fill='#D6DCE8'
        />
        <path
          d='M35.3359 106.397L33.0391 108.342L34.3758 109.921L36.6726 107.976L35.3359 106.397Z'
          fill='#D6DCE8'
        />
        <path
          d='M38.9922 106.012L36.6953 107.957L38.032 109.536L40.3289 107.591L38.9922 106.012Z'
          fill='#D6DCE8'
        />
        <path
          d='M38.0078 109.553L35.7109 111.498L37.0476 113.077L39.3445 111.132L38.0078 109.553Z'
          fill='#D6DCE8'
        />
        <path
          d='M41.6406 109.147L39.3438 111.092L40.6805 112.671L42.9773 110.726L41.6406 109.147Z'
          fill='#D6DCE8'
        />
        <path
          d='M40.6641 112.69L38.3672 114.635L39.7039 116.214L42.0008 114.269L40.6641 112.69Z'
          fill='#D6DCE8'
        />
        <path
          d='M44.3203 112.305L42.0234 114.25L43.3601 115.829L45.657 113.884L44.3203 112.305Z'
          fill='#D6DCE8'
        />
        <path
          d='M41.0391 117.791L47.2896 125.174L51.9056 121.266L45.6551 113.883L41.0391 117.791ZM50.6307 121.162L47.3972 123.899L45.5899 121.764L48.8233 119.027L50.6307 121.162Z'
          fill='#D6DCE8'
        />
      </g>
      <defs>
        <clipPath id='clip0_146_1218'>
          <rect
            width='20.3654'
            height='60.3128'
            fill='white'
            transform='translate(14.3047 97.207) rotate(-40.2525)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
