import React, {useState} from 'react'
import {ActionsCell} from '../../../componet/Menu'
import {Box, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Switch from '../../../componet/Toastr/Switch'
import Media from '../../../Api/media'
import SimpleLoading from '../../customTable/SimpleLoading'
import PopUp from '../../customTable/PopUp'
import Invoice from '../../servesFees/invoce/View'
import MainBtn from '../../customTable/MainBtn'
import Detailes from './Detailes'
import FixedNumber from '../../customTable/FixedNumber'

export default function Row({
  item,
  i,
}: {
  item: any
  i: number
  setIsLoading: any
  rows: any
  setRows: any
}) {
  const [state, setState] = useState(item.status)
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const handelEdit = () => {
    setOpen(true)
  }
  const [isLoading, setIsLoadingStatus] = useState(false)

  const [isDeleted, setDeleted] = useState(true)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  const store = useSelector((state: RootState) => state.vender)
  const [openInvice, setOpenInvice] = React.useState(false)
  console.log(item, 'xxxx')

  return (
    <>
      {isDeleted && (
        <>
          {' '}
          <tr className='fw-bold text-muted '>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.num}</span>{' '}
            </td>
            <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>{editItem.type}</td>
            <td style={{textAlign: store.isRtl === 0 ? 'start' : 'start'}}>
              {' '}
              <FixedNumber num={editItem && editItem.total && editItem.total} />
            </td>

            <td style={{textAlign: 'start'}}>{editItem.created_at}</td>
            <td style={{textAlign: 'start'}} className='text-start'>
              <MainBtn text='show' handelSubmit={() => setOpenInvice(true)} />
            </td>
          </tr>
        </>
      )}
      <PopUp width={'sm'} open={openInvice} setOpen={setOpenInvice}>
        <Detailes item={editItem} />
      </PopUp>
    </>
  )
}
