import {Stack} from '@mui/material'
import React from 'react'

export default function NoData() {
  return (
    <svg
      width='161'
      height='147'
      viewBox='0 0 161 147'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M77.8725 130.34C110.482 130.34 136.917 103.751 136.917 70.9531C136.917 38.1547 110.482 11.5664 77.8725 11.5664C45.2632 11.5664 18.8281 38.1547 18.8281 70.9531C18.8281 103.751 45.2632 130.34 77.8725 130.34Z'
        fill='#405189'
        fill-opacity='0.1'
      />
      <g filter='url(#filter0_d_12406_88881)'>
        <path
          d='M22.8672 40.3643V48.0117V108.952C22.8672 111.7 25.1244 113.97 27.8569 113.97H126.106C128.838 113.97 131.095 111.7 131.095 108.952V48.0117V40.6033C131.095 37.616 128.719 35.3457 125.868 35.3457H27.8569L22.8672 40.3643Z'
          fill='url(#paint0_linear_12406_88881)'
        />
      </g>
      <path
        d='M98.9002 45.6219H22.8672V40.3643C22.8672 37.616 25.1244 35.3457 27.8569 35.3457H126.106C128.838 35.3457 131.095 37.616 131.095 40.3643V45.6219H114.344H98.9002Z'
        fill='#405189'
        fill-opacity='0.1'
      />
      <path
        d='M29.2843 42.3955C30.2029 42.3955 30.9475 41.6466 30.9475 40.7227C30.9475 39.7988 30.2029 39.0498 29.2843 39.0498C28.3657 39.0498 27.6211 39.7988 27.6211 40.7227C27.6211 41.6466 28.3657 42.3955 29.2843 42.3955Z'
        fill='#405189'
      />
      <path
        d='M34.0343 42.3955C34.9529 42.3955 35.6975 41.6466 35.6975 40.7227C35.6975 39.7988 34.9529 39.0498 34.0343 39.0498C33.1157 39.0498 32.3711 39.7988 32.3711 40.7227C32.3711 41.6466 33.1157 42.3955 34.0343 42.3955Z'
        fill='#405189'
      />
      <path
        d='M38.9054 42.3955C39.824 42.3955 40.5686 41.6466 40.5686 40.7227C40.5686 39.7988 39.824 39.0498 38.9054 39.0498C37.9868 39.0498 37.2422 39.7988 37.2422 40.7227C37.2422 41.6466 37.9868 42.3955 38.9054 42.3955Z'
        fill='#405189'
      />
      <path
        d='M72.4075 75.1357L53.0428 75.0162C52.2112 75.0162 51.6172 74.2993 51.6172 73.5823C51.6172 72.7459 52.33 72.1484 53.0428 72.1484L72.4075 72.2679C73.2391 72.2679 73.8331 72.9849 73.8331 73.7018C73.8331 74.4188 73.2391 75.1357 72.4075 75.1357Z'
        fill='#405189'
        fill-opacity='0.3'
      />
      <path
        d='M118.381 75.1357L96.0467 75.0162C95.2151 75.0162 94.6211 74.2993 94.6211 73.5823C94.6211 72.7459 95.3339 72.1484 96.0467 72.1484L118.263 72.2679C119.094 72.2679 119.688 72.9849 119.688 73.7018C119.807 74.4188 119.213 75.1357 118.381 75.1357Z'
        fill='#405189'
        fill-opacity='0.1'
      />
      <path
        d='M103.53 103.217L81.1951 103.097C80.3635 103.097 79.7695 102.38 79.7695 101.663C79.7695 100.827 80.4823 100.229 81.1951 100.229L103.53 100.349C104.361 100.349 104.955 101.066 104.955 101.783C104.955 102.619 104.243 103.217 103.53 103.217Z'
        fill='#405189'
        fill-opacity='0.3'
      />
      <path
        d='M89.7522 75.0166L78.9412 74.8971C78.1096 74.8971 77.5156 74.1801 77.5156 73.4632C77.5156 72.6267 78.2284 72.0293 78.9412 72.0293L89.7522 72.1488C90.5838 72.1488 91.1778 72.8657 91.1778 73.5827C91.2966 74.4191 90.5838 75.0166 89.7522 75.0166Z'
        fill='#405189'
        fill-opacity='0.6'
      />
      <path
        d='M45.3189 74.8971H37.3592C36.5276 74.8971 35.9336 74.1801 35.9336 73.4632C35.9336 72.6267 36.5276 72.0293 37.3592 72.0293H45.3189C46.1505 72.0293 46.7445 72.7462 46.7445 73.4632C46.7445 74.2996 46.1505 74.8971 45.3189 74.8971Z'
        fill='#405189'
        fill-opacity='0.6'
      />
      <path
        d='M76.2088 84.456L64.5662 84.3365C63.7346 84.3365 63.1406 83.6196 63.1406 82.9026C63.1406 82.0662 63.8534 81.4688 64.5662 81.4688L76.2088 81.5882C77.0404 81.5882 77.6344 82.3052 77.6344 83.0221C77.7532 83.7391 77.0404 84.456 76.2088 84.456Z'
        fill='#405189'
        fill-opacity='0.3'
      />
      <path
        d='M112.324 84.695L82.742 84.3365C81.9104 84.3365 81.3164 83.6196 81.3164 82.9026C81.3164 82.0662 82.0292 81.4688 82.742 81.4688L112.324 81.8272C113.155 81.8272 113.749 82.5442 113.749 83.2611C113.749 83.978 113.036 84.695 112.324 84.695Z'
        fill='#405189'
        fill-opacity='0.3'
      />
      <path
        d='M56.8427 84.3365H37.3592C36.5276 84.3365 35.9336 83.6196 35.9336 82.9026C35.9336 82.0662 36.6464 81.4688 37.3592 81.4688H56.8427C57.6743 81.4688 58.2683 82.1857 58.2683 82.9026C58.2683 83.6196 57.6743 84.3365 56.8427 84.3365Z'
        fill='#405189'
        fill-opacity='0.1'
      />
      <path
        d='M91.5342 93.7763L64.5662 93.6568C63.7346 93.6568 63.1406 92.9399 63.1406 92.2229C63.1406 91.3865 63.8534 90.7891 64.5662 90.7891L91.653 90.9086C92.4846 90.9086 93.0786 91.6255 93.0786 92.3424C93.0786 93.1789 92.3658 93.7763 91.5342 93.7763Z'
        fill='#405189'
        fill-opacity='0.6'
      />
      <path
        d='M56.8427 93.6568H37.3592C36.5276 93.6568 35.9336 92.9399 35.9336 92.2229C35.9336 91.3865 36.6464 90.7891 37.3592 90.7891H56.8427C57.6743 90.7891 58.2683 91.506 58.2683 92.2229C58.2683 92.9399 57.6743 93.6568 56.8427 93.6568Z'
        fill='#405189'
        fill-opacity='0.3'
      />
      <path
        d='M73.7133 103.097L58.8631 102.977C58.0315 102.977 57.4375 102.26 57.4375 101.543C57.4375 100.707 58.1503 100.109 58.8631 100.109L73.7133 100.229C74.5449 100.229 75.1389 100.946 75.1389 101.663C75.1389 102.499 74.5449 103.097 73.7133 103.097Z'
        fill='#405189'
        fill-opacity='0.1'
      />
      <path
        d='M49.3582 102.977H37.3592C36.5276 102.977 35.9336 102.26 35.9336 101.543C35.9336 100.707 36.6464 100.109 37.3592 100.109H49.3582C50.1898 100.109 50.7838 100.826 50.7838 101.543C50.9026 102.38 50.1898 102.977 49.3582 102.977Z'
        fill='#405189'
        fill-opacity='0.6'
      />
      <path
        d='M92.0079 60.678L37.3592 60.4391C36.5276 60.4391 35.9336 59.7221 35.9336 59.0052C35.9336 58.1687 36.6464 57.5713 37.3592 57.5713L92.0079 57.8103C92.8395 57.8103 93.4335 58.5272 93.4335 59.2442C93.4335 60.0806 92.8395 60.678 92.0079 60.678Z'
        fill='#405189'
        fill-opacity='0.6'
      />
      <path
        d='M3.20764 54.3686C3.44524 54.6076 3.56405 54.8466 3.56405 55.205C2.37603 58.6702 2.01963 58.7897 3.56405 59.3872C3.68285 59.3872 3.80165 59.6262 3.68285 59.7457C3.32644 60.7016 3.20764 61.4185 2.85123 61.299C-2.73244 59.2677 2.97004 55.444 0.1188 54.3686C-1.81454e-06 54.3686 0 54.2491 0 54.1296L0.594008 52.3373C0.594008 52.2178 0.712806 52.2178 0.831607 52.2178C2.25723 52.6957 2.37603 51.6203 2.85123 50.3059C3.44524 48.3941 4.98966 47.7966 6.77168 48.3941C7.12809 48.5136 7.48449 48.6331 7.36569 48.872C6.77168 50.6644 6.89048 50.5449 6.17768 50.3059C4.39565 49.7085 4.87086 54.4881 3.20764 54.3686Z'
        fill='#405189'
        fill-opacity='0.6'
      />
      <path
        d='M156.579 54.726C156.46 54.726 156.46 54.8455 156.579 54.726C156.816 54.965 156.935 55.204 156.935 55.5625C155.747 59.0277 155.391 59.1472 156.935 59.7446C157.054 59.7446 157.173 59.9836 157.054 60.1031C156.698 61.059 156.579 61.7759 156.222 61.6565C150.639 59.6251 156.341 55.8014 153.49 54.726C153.371 54.726 153.371 54.6065 153.371 54.487L153.965 52.6947C153.965 52.5752 154.084 52.5752 154.203 52.5752C155.628 53.0532 155.747 51.9777 156.222 50.6634C156.816 48.7515 158.361 48.1541 160.143 48.7515C160.499 48.871 160.856 48.9905 160.737 49.2295C160.143 51.0218 160.262 50.9023 159.549 50.6634C157.648 50.1854 158.242 54.8455 156.579 54.726Z'
        fill='#405189'
        fill-opacity='0.6'
      />
      <path
        d='M87.1991 3.10675C87.0803 2.98726 87.0803 2.98726 87.1991 3.10675C86.9615 3.34573 86.7239 3.58471 86.4863 3.58471C82.9222 2.98726 82.6846 2.62879 82.3282 4.18216C82.3282 4.30165 82.2094 4.42114 81.9718 4.42114C80.9026 4.18216 80.3086 4.30165 80.3086 3.82369C81.3778 -2.15083 86.1299 2.98726 86.6051 0.11949C86.6051 -6.85509e-07 86.7239 0 86.8427 0L88.6247 0.35847C88.7435 0.35847 88.7435 0.477961 88.7435 0.597451C88.5059 2.03133 89.4563 2.03133 91.0007 2.27031C92.9016 2.62879 93.852 3.94318 93.4956 5.85502C93.3768 6.21349 93.3768 6.57196 93.1392 6.57196C91.3571 6.21349 91.3571 6.45247 91.5947 5.61604C91.8323 3.46522 87.1991 4.77961 87.1991 3.10675Z'
        fill='#405189'
        fill-opacity='0.6'
      />
      <path
        d='M50.9033 11.3282C50.0717 12.1646 49.9529 13.5985 50.7845 14.4349C51.2597 15.0324 52.0913 15.2714 52.9229 15.0324C53.2793 15.5103 53.2793 16.1078 53.0417 16.5858L51.7349 18.4976C51.6161 18.6171 51.4973 18.8561 51.4973 18.9756L50.1905 17.5417C50.5469 16.3468 49.9529 15.1519 48.7648 14.7934C47.5768 14.4349 46.3888 15.0324 46.0324 16.2273C45.676 17.4222 46.27 18.6171 47.458 18.9756C47.8144 19.095 48.2896 19.095 48.646 18.9756L52.0913 22.6798C51.7349 23.8747 52.3289 25.0696 53.5169 25.428C54.7049 25.7865 55.8929 25.189 56.2493 23.9941C56.2493 23.8747 56.2493 23.8747 56.3681 23.7552C56.3681 23.5162 56.3681 23.2772 56.3681 23.1577C56.2493 21.9628 55.1801 21.0069 53.8733 21.2459C53.7545 21.2459 53.7545 21.2459 53.6357 21.2459C53.2793 20.7679 53.1605 20.1705 53.5169 19.6925L54.8237 17.7807C55.6553 16.4663 55.5365 14.7934 54.5861 13.5985C54.5861 13.479 54.5861 13.3595 54.7049 13.3595C54.7049 13.1205 54.7049 12.8816 54.7049 12.7621C54.5861 11.5672 53.5169 10.7307 52.3289 10.8502C51.6161 10.8502 51.2597 10.9697 50.9033 11.3282ZM47.5768 16.3468C47.9332 16.1078 48.2896 16.1078 48.646 16.3468C48.8836 16.7052 48.8836 17.0637 48.646 17.4222C48.2896 17.6612 47.9332 17.6612 47.5768 17.4222C47.2204 17.1832 47.2204 16.7052 47.5768 16.3468ZM54.7049 23.0382C54.8237 23.1577 54.8237 23.2772 54.8237 23.3967C54.8237 23.7552 54.4673 24.1136 53.9921 24.1136C53.6357 24.1136 53.2793 23.7552 53.2793 23.2772C53.2793 23.1577 53.3981 22.9187 53.3981 22.7992C53.5169 22.5603 53.7545 22.5603 53.9921 22.5603C54.3485 22.6798 54.5861 22.7992 54.7049 23.0382ZM51.7349 12.4036C51.9725 12.0451 52.4477 12.0451 52.8041 12.2841C52.9229 12.2841 52.9229 12.4036 52.9229 12.5231C52.9229 12.6426 52.9229 12.6426 53.0417 12.7621C53.0417 12.8816 53.0417 12.8816 53.0417 13.001C53.0417 13.1205 53.0417 13.1205 53.0417 13.1205C52.9229 13.24 52.8041 13.3595 52.6853 13.479C52.3289 13.718 51.8537 13.479 51.7349 13.1205C51.6161 13.001 51.6161 12.6426 51.7349 12.4036Z'
        fill='#405189'
        fill-opacity='0.3'
      />
      <path
        d='M98.6067 21.8229L96.8247 22.7788C95.0426 20.9865 92.3102 20.9865 90.5282 22.7788C89.5778 23.7348 89.1026 25.1686 89.3402 26.6025L87.4393 27.439C87.0829 27.5584 86.9641 28.0364 87.2017 28.2754L87.9146 29.7093C88.0334 30.0677 88.5086 30.1872 88.7462 29.9483L90.5282 28.9923C92.3102 30.7847 95.0426 30.7847 96.8247 28.9923C97.7751 28.0364 98.2503 26.6025 98.0127 25.1686L99.7947 24.2127C100.151 24.0932 100.27 23.6153 100.032 23.3763L99.3195 21.9424C99.3195 21.8229 98.8443 21.7034 98.6067 21.8229ZM94.6862 27.9169C93.617 28.5144 92.3102 28.0364 91.7162 26.961C91.1222 25.8856 91.5974 24.5712 92.6666 23.9737C93.7358 23.3763 95.0426 23.8542 95.6367 24.9297C96.1119 26.0051 95.7555 27.3195 94.6862 27.9169Z'
        fill='#405189'
        fill-opacity='0.3'
      />
      <path
        d='M16.0376 96.7732L14.1367 93.308C13.4239 91.9936 11.8795 91.5156 10.5727 92.2326L10.2163 92.3521L9.85988 91.7546C9.74108 91.5156 9.50347 91.5156 9.26587 91.6351C9.14707 91.6351 9.14707 91.7546 9.02827 91.8741L8.07786 94.2639C7.95905 94.5029 8.07786 94.7419 8.31546 94.8614C8.31546 94.8614 8.31546 94.8614 8.43426 94.8614L10.9291 95.3393C11.1667 95.3393 11.4043 95.2198 11.4043 94.9809C11.4043 94.8614 11.4043 94.7419 11.4043 94.7419L11.0479 94.1444L11.4043 93.9054C11.7607 93.6665 12.3547 93.786 12.4735 94.2639L14.3743 97.7291C14.0179 98.3266 14.0179 99.0435 14.3743 99.641C14.8495 100.597 15.9188 100.836 16.8692 100.358C17.2256 100.119 17.4632 99.88 17.7008 99.5215C17.8196 99.402 17.8196 99.163 17.8196 99.0435C17.9384 97.9681 17.2256 97.0122 16.1564 96.8927C16.1564 96.7732 16.0376 96.7732 16.0376 96.7732ZM15.8 99.163C15.6812 99.163 15.6812 99.163 15.8 99.163C15.4436 98.924 15.3248 98.5656 15.5624 98.2071C15.6812 97.9681 15.9188 97.8486 16.1564 97.8486C16.2752 97.8486 16.5128 97.9681 16.6316 98.0876C16.8692 98.3266 16.8692 98.6851 16.6316 98.924C16.2752 99.2825 16.0376 99.2825 15.8 99.163Z'
        fill='#405189'
        fill-opacity='0.3'
      />
      <path
        d='M5.5819 93.6672C4.75029 94.1452 4.39389 95.3401 4.8691 96.1765C5.2255 96.7739 5.81951 97.1324 6.53232 97.1324L9.85876 103.107C10.0964 103.585 10.5716 103.704 11.0468 103.465C11.522 103.226 11.6408 102.748 11.4032 102.27L8.07673 96.296C8.43314 95.6985 8.43314 94.9816 8.07673 94.3841C7.60153 93.5477 6.41351 93.1892 5.5819 93.6672ZM6.76992 95.818C6.41351 95.9375 6.05711 95.9375 5.93831 95.579C5.81951 95.2206 5.81951 94.8621 6.17591 94.7426C6.53232 94.6231 6.88873 94.6231 7.00753 94.9816C7.12633 95.3401 7.00752 95.6985 6.76992 95.818Z'
        fill='#405189'
        fill-opacity='0.3'
      />
      <path
        d='M147.249 77.6447L147.368 77.4058C147.724 76.6888 147.724 75.9719 147.368 75.2549L146.299 72.9846C146.18 72.8651 146.18 72.6261 146.299 72.5067L146.655 71.6702L147.249 72.0287C147.487 72.1482 147.724 72.0287 147.843 71.7897C147.843 71.6702 147.843 71.5507 147.843 71.5507L147.368 68.9219C147.368 68.683 147.13 68.5635 146.893 68.5635H146.774L144.398 69.6389C144.16 69.7584 144.042 69.9974 144.16 70.2363C144.16 70.3558 144.279 70.3558 144.398 70.4753L144.992 70.8338L144.636 71.6702C144.517 71.7897 144.398 71.9092 144.279 71.9092L141.784 72.2677C141.072 72.3872 140.359 72.8651 140.121 73.4626L140.002 73.7016C138.933 73.7016 138.102 74.538 138.102 75.6134C138.102 76.6888 138.933 77.5252 140.002 77.5252C141.072 77.5252 141.903 76.6888 141.903 75.6134C141.903 75.2549 141.784 74.8965 141.547 74.6575L141.666 74.4185C141.784 74.299 141.903 74.1795 142.022 74.1795L144.16 73.821C144.398 73.821 144.636 73.9405 144.754 74.1795L145.705 76.2109C145.824 76.3303 145.824 76.5693 145.705 76.6888L145.586 76.9278C144.517 76.9278 143.685 77.8837 143.804 78.8396C143.804 79.915 144.754 80.7515 145.705 80.632C146.774 80.632 147.606 79.6761 147.487 78.7201C147.606 78.3617 147.487 78.0032 147.249 77.6447ZM139.765 76.3303C139.408 76.2109 139.29 75.7329 139.527 75.4939C139.646 75.1354 140.121 75.0159 140.359 75.2549C140.715 75.3744 140.834 75.8524 140.596 76.0914C140.478 76.3303 140.002 76.4498 139.765 76.3303ZM145.348 79.3176C144.992 79.1981 144.873 78.7201 145.111 78.4812C145.23 78.1227 145.705 78.0032 145.942 78.2422C146.18 78.4812 146.418 78.8396 146.18 79.0786C146.061 79.3176 145.705 79.4371 145.348 79.3176Z'
        fill='#405189'
        fill-opacity='0.3'
      />
      <path
        d='M142.587 110.996C140.183 123.005 128.521 130.848 116.74 128.274C104.958 125.823 97.264 113.936 99.7886 101.927C102.193 89.9175 113.854 82.0751 125.636 84.6485C129.844 85.5064 133.691 87.5896 136.696 90.7758C141.865 96.0453 144.15 103.643 142.587 110.996Z'
        fill='url(#paint1_linear_12406_88881)'
      />
      <path
        d='M127.338 109.594L123.901 106.157L127.338 102.721C128.025 102.033 128.102 100.888 127.338 100.124C126.651 99.4368 125.505 99.3604 124.741 100.124L121.305 103.561L117.868 100.124C117.181 99.4368 116.035 99.3604 115.271 100.124C114.508 100.888 114.508 101.957 115.271 102.721L118.708 106.157L115.271 109.594C114.584 110.281 114.508 111.427 115.271 112.191C115.959 112.878 117.104 112.954 117.868 112.191L121.305 108.754L124.741 112.191C125.429 112.878 126.574 112.954 127.338 112.191C128.102 111.427 128.025 110.281 127.338 109.594Z'
        fill='white'
      />
      <path
        d='M69.3154 123.29C68.6026 123.409 68.0086 123.887 67.771 124.604C65.8702 124.963 64.3257 123.529 63.9693 122.812C64.6821 121.975 64.5633 120.78 63.7317 120.064C62.9001 119.347 61.7121 119.466 60.9993 120.303C60.2865 121.139 60.4053 122.334 61.2369 123.051C61.4745 123.17 61.7121 123.409 61.9497 123.409L62.7813 127.83C61.9497 128.547 61.8309 129.742 62.5437 130.579C63.2565 131.415 64.4445 131.535 65.2761 130.818C65.3949 130.818 65.3949 130.698 65.5138 130.698C65.6326 130.579 65.7514 130.459 65.8702 130.22C66.3454 129.264 65.989 128.069 64.9197 127.591C64.8009 127.591 64.8009 127.472 64.6821 127.472L64.3257 125.799C65.3949 126.516 66.7018 126.755 68.0086 126.635H68.1274C68.8402 127.472 70.0282 127.591 70.8598 126.874C70.9786 126.874 70.9786 126.755 70.9786 126.755C71.0974 126.635 71.2162 126.516 71.335 126.277C71.8102 125.321 71.4538 124.126 70.5034 123.648C70.147 123.29 69.6718 123.17 69.3154 123.29ZM62.4249 120.78C62.7813 120.661 63.1377 120.9 63.2565 121.378C63.3753 121.736 63.1377 122.095 62.6625 122.214C62.3061 122.334 61.9497 122.095 61.8309 121.617C61.8309 121.258 62.0685 120.9 62.4249 120.78ZM64.3257 129.862C64.0881 130.101 63.6129 129.981 63.3753 129.742C63.1377 129.503 63.2565 129.025 63.4941 128.786C63.4941 128.786 63.6129 128.667 63.7317 128.667C63.8505 128.547 64.0881 128.667 64.2069 128.667C64.5633 128.906 64.6821 129.264 64.4445 129.623C64.5633 129.742 64.4445 129.742 64.3257 129.862ZM69.6718 125.919C69.3154 125.919 69.0778 125.56 69.0778 125.202C69.0778 124.963 69.3154 124.724 69.553 124.604C69.6718 124.604 69.9094 124.604 70.0282 124.604C70.3846 124.724 70.5034 125.202 70.3846 125.56C70.147 125.799 69.9094 125.919 69.6718 125.919Z'
        fill='#405189'
        fill-opacity='0.3'
      />
      <defs>
        <filter
          id='filter0_d_12406_88881'
          x='0.867188'
          y='24.3457'
          width='152.227'
          height='122.625'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='11' />
          <feGaussianBlur stdDeviation='11' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0'
          />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_12406_88881' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_12406_88881'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_12406_88881'
          x1='76.946'
          y1='33.527'
          x2='76.946'
          y2='114.818'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#FDFEFF' />
          <stop offset='0.9964' stop-color='#ECF0F5' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_12406_88881'
          x1='99.2886'
          y1='106.466'
          x2='143.079'
          y2='106.466'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#B0BACC' />
          <stop offset='1' stop-color='#405189' />
        </linearGradient>
      </defs>
    </svg>
  )
}
