import React, {useEffect} from 'react'
import Dialog from '@mui/material/Dialog'

import Slide from '@mui/material/Slide'
import {TransitionProps} from '@mui/material/transitions'
import EditOptionForm from './EditFormOption'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function EditOption({
  open,
  setOpen,
  item,
  values,
  type,
  option_id,
  setEditItem,
  optionValue,
  setOptionValue,
  isSelected,
  setIsSelected,
  options,
  setIsGetOption,
  getSelectedOptions,
}: any) {
  const handleClose = () => {
    if (item.option_detail_id) {
      setIsSelected([...isSelected, item.option_detail_id])
    }

    setOpen(false)
  }
  useEffect(() => {
    console.log('open11')

    if (open && values.is_upload_image === 0) {
      console.log('open')

      getSelectedOptions()
      setIsGetOption(true)
    }
  }, [open])
  return (
    <div>
      <></>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)', // تعديلاتك الخاصة هنا
          },
          '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px', // تعديلاتك الخاصة هنا
          },
        }}
      >
        <EditOptionForm
          item={item}
          values={values}
          type={type}
          setOpen={setOpen}
          optionValue={optionValue}
          setOptionValue={setOptionValue}
          open={open}
          option_id={option_id}
          setEditItem={setEditItem}
          valuesId={values.id}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          options={options}
        />
      </Dialog>
    </div>
  )
}
