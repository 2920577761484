import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import CouponsTable from '../Coupons/list'
import {useTranslation} from 'react-i18next'

import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import Error403 from '../errors/components/Error403'
import Wallet from '.'
import ServesFees from '../servesFees'
import InvoiceForm from '../servesFees/InvoiceForm'
import PaymentHistory from './paymentHistory'

const WalletPage = () => {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const categoryBreadCrumbs: Array<PageLink> = [
    {
      title: t('home'),
      path: '/helpdesk/list',
      isSeparator: false,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route path='my-wallet' element={<Wallet />} />
        <Route
          path='servise-fees'
          element={
            store.permissions && store.permissions.includes('service_fees') ? (
              <ServesFees />
            ) : (
              <Error403 />
            )
          }
        />
        <Route
          path='servise-fees/values'
          element={
            store.permissions && store.permissions.includes('services_fees_value') ? (
              <InvoiceForm />
            ) : (
              <Error403 />
            )
          }
        />
        <Route path='Payments-history' element={<PaymentHistory />} />

        <Route index element={<Navigate to='/helpdesk/list' />} />
      </Route>
    </Routes>
  )
}

export default WalletPage
