import React from 'react'
import MainBtn from '../../customTable/MainBtn'
import PopUp from '../../customTable/PopUp'
import AcceptForm from './AcceptForm'
import RejectForm from './RejectForm'
import {Stack} from '@mui/material'
import {useSelector} from 'react-redux'

export default function Actions({order, isItem, setOrder}: any) {
  const [openAccept, setOpenAccept] = React.useState(false)
  const [openReject, setOpenReject] = React.useState(false)
  const store = useSelector((state: any) => state.vender)
  return (
    <Stack>
      {store.permissions && store.permissions.includes('refund_orders') && (
        <Stack gap='24px' direction='row'>
          {store.permissions && store.permissions.includes('refund_orders_accept') && (
            <MainBtn
              handelSubmit={() => {
                setOpenAccept(true)
              }}
              width='auto'
              text='acceptRefund'
              bgcolor='#50CD89'
            />
          )}
          {store.permissions && store.permissions.includes('refund_orders_reject') && (
            <MainBtn
              handelSubmit={() => {
                setOpenReject(true)
              }}
              width='auto'
              text='rejectRefund'
              bgcolor='#D81923'
            />
          )}
        </Stack>
      )}{' '}
      {openAccept && (
        <PopUp width='sm' setOpen={setOpenAccept} open={openAccept}>
          <AcceptForm
            setOrder={setOrder}
            setOpenAccept={setOpenAccept}
            isItem={isItem}
            order={order}
          />
        </PopUp>
      )}
      {openReject && (
        <PopUp width='sm' setOpen={setOpenReject} open={openReject}>
          <RejectForm
            setOrder={setOrder}
            setOpenReject={setOpenReject}
            order={order}
            isItem={isItem}
          />
        </PopUp>
      )}
    </Stack>
  )
}
