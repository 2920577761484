import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import CouponsTable from '../../Coupons/list'
import {useTranslation} from 'react-i18next'

import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Error403 from '../../errors/components/Error403'
import Wallet from '../../wallet'
import ServesFees from '../../servesFees'
import InvoiceForm from '../../servesFees/InvoiceForm'
import ApplicationMaker from '.'
import WarperBackages from '../../customTable/WarperBackages'

const AppMakerPage = () => {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const categoryBreadCrumbs: Array<PageLink> = [
    {
      title: t('home'),
      path: '/helpdesk/list',
      isSeparator: false,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='ios-application'
          element={
            store.permissions && store.permissions.includes('app_maker') ? (
              <>
                <WarperBackages backageKey='APP_MAKER_IOS'>
                  <ApplicationMaker />
                </WarperBackages>
              </>
            ) : (
              <Error403 />
            )
          }
        />
        <Route
          path='android-application'
          element={
            store.permissions && store.permissions.includes('app_maker') ? (
              <>
                <WarperBackages backageKey='APP_MAKER_ANDROID'>
                  {' '}
                  <ApplicationMaker android />
                </WarperBackages>
              </>
            ) : (
              <Error403 />
            )
          }
        />

        <Route index element={<Navigate to='/helpdesk/list' />} />
      </Route>
    </Routes>
  )
}

export default AppMakerPage
