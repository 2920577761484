import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../../modules/customTable/Label'
import FixedNumber from '../../../modules/customTable/FixedNumber'

export default function RangeChart({name, value, num, color}: any) {
  return (
    <Stack gap='8px'>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Label color='#8D98AF' text={name} fs='12px' fw='400' />
        <FixedNumber isNum num={value} color='#000000' text={value} fs='14px' fw='400' />

        {/* <Label color='#000000' text={value} fs='14px' fw='400' /> */}
      </Stack>
      <Stack height={'8px'} width={'100%'} borderRadius={'10px'} bgcolor={'#EFEFF4'}>
        <Stack height={'8px'} width={`${num}%`} borderRadius={'10px'} bgcolor={color}></Stack>
      </Stack>
    </Stack>
  )
}
