import {Stack} from '@mui/system'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import DateInput from './DateInput'
import HomeApi from '../../../Api/home'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, {AlertProps} from '@mui/material/Alert'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
export default function ActionBtns({setCards, setLoading}: any) {
  const [open, setOpen] = React.useState(false)
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)
  const copyLink = () => {
    navigator.clipboard
      .writeText(store.domain)
      .then(() => {
        setOpen(true)
      })
      .catch(() => {})
  }
  return (
    <Stack justifyContent={'flex-end'} flexWrap={'wrap'} gap='10px' direction={'row'}>
      {store.permissions && store.permissions.includes('dashboard_get-analysis') && (
        <DateInput setLoading={setLoading} setCards={setCards} />
      )}
      <Stack gap='2px' direction={'row'}>
        <button
          style={{
            border: 'none',
            borderRadius: store.isRtl !== 0 ? '0px 8px 8px 0px' : '8px 0 0 8px',
            background: '#FE9077',
            padding: '13px',
          }}
          onClick={copyLink}
        >
          <img src='/media/icons/shere.svg' alt='' />
        </button>

        <a
          href={store.domain}
          target='_blank'
          type='submit'
          style={{
            borderRadius: store.isRtl === 0 ? '0px 8px 8px 0px' : '8px 0 0 8px',
            background: 'var(--orange-color)',
            color: 'var(--w-color)',
            width: 'max-content',
          }}
          // disabled={formik.isSubmitting || !formik.isValid || !formik.touched}

          className='btn '
        >
          <span className='indicator-label'>{t('viewMyStore')}</span>
        </a>
      </Stack>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='success' sx={{width: '100%'}}>
          {t('msgSuccessfully')}
        </Alert>
      </Snackbar>
    </Stack>
  )
}
