import React from 'react'

export default function Woring() {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.00065 15.1038C11.6673 15.1038 14.6673 12.1038 14.6673 8.43717C14.6673 4.77051 11.6673 1.77051 8.00065 1.77051C4.33398 1.77051 1.33398 4.77051 1.33398 8.43717C1.33398 12.1038 4.33398 15.1038 8.00065 15.1038Z'
        stroke='#D22F27'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8 5.77051V9.10384'
        stroke='#D22F27'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.99609 11.1045H8.00208'
        stroke='#D22F27'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
