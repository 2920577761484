/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {useLang, setLanguage} from '../../../i18n/Metronici18n'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {actions} from '../../../../app/store/vender/Auth/auth.slice'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../app/store'
import {Avatar, Box, Stack} from '@mui/material'
import LangsMenus from './langsMenu'
// const languages = [
//   {
//     id: 1,
//     lang: 'en',
//     name: 'English',
//     flag: toAbsoluteUrl('/media/flags/united-states.svg'),
//     isRtl: 0,
//   },
//   {
//     id: 2,
//     lang: 'ar',
//     name: 'العربيه',
//     flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Flag_of_Iraq.svg/220px-Flag_of_Iraq.svg.png',
//     isRtl: 1,
//   },
//   {
//     id: 2,
//     lang: 'ku',
//     name: 'Kurdish',
//     flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Flag_of_Kurdistan.svg/280px-Flag_of_Kurdistan.svg.png',
//     isRtl: 1,
//   },
// ]

const Languages = ({languages}: any) => {
  // const [languages, setlanguages] = useState([])

  const lang = useLang()
  const currentLanguage = languages.find((x: any) => x.lang === lang)
  const {i18n} = useTranslation()
  const [islang, setIsLang] = useState<any>({
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  })
  const dispatch = useDispatch()
  const store = useSelector((state: RootState) => state.vender)
  useEffect(() => {
    const lang = localStorage.getItem('lang')
    if (lang !== null) {
      setIsLang(JSON.parse(lang))
      i18n.changeLanguage(JSON.parse(lang).lang)
      dispatch(actions.setLang(JSON.parse(lang).lang))
      dispatch(actions.setRtl(JSON.parse(lang).isRtl))

      // setlanguages(store.langs.map((e: any) => (e = {...e, name: e.lang, lang: e.code})))
    } else {
      const language = navigator.language
      i18n.changeLanguage(language.split('-')[0])
      dispatch(actions.setLang(language.split('-')[0]))
      dispatch(actions.setRtl(language.split('-')[0] === 'en' ? 0 : 1))

      // setIsLang(languages.find((e) => e.lang === language.split('-')[0]))
    }
  }, [store.lang])
  const {t} = useTranslation()

  return (
    <>
      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a className='menu-link px-5'>
          <Stack
            justifyContent={'space-between'}
            direction={'row'}
            className='menu-title position-relative'
          >
            {t('language')}
            <LangsMenus
              setIsLang={setIsLang}
              languages={languages}
              selectedLang={
                <Stack gap='10px' alignItems={'center'} direction={'row'}>
                  {t(islang.name)}
                  <Avatar
                    className='w-15px h-15px rounded-1 ms-2'
                    src={islang.flag}
                    alt={islang.name}
                  />
                </Stack>
              }
            />

            {/* <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
              <Stack gap='10px' alignItems={'center'} direction={'row'}>
                {t(islang.name)}
                <Avatar
                  className='w-15px h-15px rounded-1 ms-2'
                  src={islang.flag}
                  alt={islang.name}
                />
              </Stack>
            </span> */}
          </Stack>
        </a>
      </div>
    </>
  )
}

export {Languages}
