import {initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'

import {getStorage} from 'firebase/storage'

const app = initializeApp({
  apiKey: 'AIzaSyB9Wug6E7WPeJYG2QBjOMd7pFqXz_dYU9Y',
  authDomain: 'koin-store5t.firebaseapp.com',
  projectId: 'koin-store5t',
  storageBucket: 'koin-store5t.appspot.com',
  messagingSenderId: '343362859110',
  appId: '1:343362859110:web:573c835672c55e6f30bda7',
  measurementId: 'G-KP9539MW2C',
})
export const db = getFirestore(app)

// Initialize Firebase
export const storage = getStorage(app)

export default storage
