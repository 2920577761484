import React, {useState, useCallback, useEffect} from 'react'
// import HeadTable from '../customTable/HeadTable'
import TableHead from './TablHead'
import Row from './Row'
// import Banner from '../../Api/banner'
// import {PaginationSection} from '../customTable/pagnationSection'
// import {CustomerListLoading} from '../customer/order/custmerListLoading'
import {CircularProgress} from '@mui/material'
// import IBanner from '../../interface/IBanner'
// import JwtService from '../../utils/TokenServices'
import {useDispatch} from 'react-redux'
// import {actions} from '../../store/vender/Auth/auth.slice'
import {useTranslation} from 'react-i18next'
import {PaginationSection} from '../../customTable/pagnationSection'
import Media from '../../../Api/media'
import iMedia from '../../../interface/iMedia'
import NoDataTable from '../../customTable/NoDataTable'

export default function Table({
  rows,
  setRows,
  added,
  total,
  setTotal,
  setNum,
  setAdded,
}: {
  rows: iMedia[] | never[]
  setRows: React.Dispatch<React.SetStateAction<iMedia[] | never[]>>
  added: boolean
  total: any
  setTotal: any
  setNum: any
  setAdded: any
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [timerId, setTimerId] = useState<number | null>(null)
  const [search, setSearch] = useState('')
  const {t} = useTranslation()

  const dispatch = useDispatch()

  const getMedia = (searchText: string, rowsPerPage: number, page: number) => {
    Media.get(
      searchText,
      rowsPerPage,
      page,
      (res: any) => {
        setTotal(res.pages.total)

        setRows(res.data)
      },
      setIsLoading
    )
  }
  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setPage(1)
  }
  useEffect(() => {
    getMedia('', rowsPerPage, page)
  }, [rowsPerPage, page])
  return (
    <div style={{marginTop: '30px'}} className={`card fds sha`}>
      <div className='card-body py-3 pb-5'>
        <div className='table-responsive overflow-auto  '>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <TableHead />
            {!added ? (
              <>
                {' '}
                {!isLoading ? (
                  <tbody className='mb-4'>
                    {rows.length === 0 ? (
                      <NoDataTable />
                    ) : (
                      rows.map((e, i) => (
                        <Row
                          setRows={setRows}
                          setNum={setNum}
                          setIsLoading={setIsLoading}
                          rows={rows}
                          setAdded={setAdded}
                          item={e}
                          i={i}
                          key={i}
                        />
                      ))
                    )}
                  </tbody>
                ) : (
                  <CircularProgress />
                )}
              </>
            ) : (
              <h1>loading</h1>
            )}
          </table>
          <PaginationSection
            rowsPerPageOptions={[5, 20, 30, 40, 50, 60]}
            count={total || 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {/* pagnation  item   */}
        </div>
      </div>
    </div>
  )
}
