import {useCallback, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {ActionsCell} from '../../../componet/Menu'
import {RootState, useDispatch, withToastr} from '../../../store'
import {deleteCategoryAction} from '../../../store/vender/catogory/thunk'
import {handleClickSelected} from '../../../utils/sortTable'
import {RowItemProps} from './types'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {Avatar, Stack} from '@mui/material'
import {toast} from 'react-toastify'
import Catalog from '../../../Api/catalog'
import SimpleLoading from '../../customTable/SimpleLoading'
import PupUpImage from '../../customTable/PupUpImage'
import NameText from '../../customTable/NameText'

export const RowItem = (props: any) => {
  const dispatch = useDispatch()
  const {
    id,
    name,
    status,
    image,
    description,

    setNum,
    i,
    setRows,
  } = props

  const {lang, defLang} = useSelector((state: RootState) => state.vender)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  const handelDelete = () => {
    Catalog.delete(
      id,
      () => {
        setNum((prev: any) => prev - 1)
        setRows((prev: any) => prev.filter((e: any) => e.id !== id))
      },
      setIsLoadingDelete
    )
  }

  const navigate = useNavigate()
  const store = useSelector((state: RootState) => state.vender)

  const handleEdit = useCallback(() => {
    navigate(`/catalog/edit-category/${id}`)
  }, [id, navigate])
  const onView = useCallback(() => {
    navigate(`/catalog/sub-category/${id}`)
  }, [id, navigate])
  const {t} = useTranslation()

  return (
    <>
      <tr>
        <td>
          <a className='text-dark fw-bold text-hover-primary fs-6'>{i + 1}</a>
        </td>
        <td className=''>
          <PupUpImage image={image}>
            <Stack gap='1.25rem' direction={'row'} className='d-flex'>
              <Avatar
                alt={
                  name &&
                  (name[lang] || name[defLang]) &&
                  (name[lang] || name[defLang]).slice(0, 15)
                }
                src={image}
              />

              {/* <Link
                to={``}
                className='symbol symbol-50px'
                data-kt-ecommerce-category-filter='category_name'
                style={{
                  display: 'inline-block',
                  flexShrink: 0,
                  position: 'relative',
                  borderRadius: '0.475rem',
                }}
              >
                <span
                  className='symbol-label'
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                ></span>
              </Link> */}

              <div>
                <NameText text={name && (name[lang] || name[defLang])} />

                <div
                  className='text-muted fs-7 fw-bold'
                  dangerouslySetInnerHTML={{
                    __html:
                      typeof description === 'object'
                        ? description[lang] || description[defLang]
                        : description,
                  }}
                >
                  {/* // {typeof description === "object" ? description["en"] : description} */}
                </div>
              </div>
            </Stack>
          </PupUpImage>
        </td>
        <td>
          {+status ? (
            <div
              style={{padding: '8px 16px', color: '#3297cc'}}
              className='badge badge-light-primary'
            >
              {t('active')}
            </div>
          ) : (
            <div style={{padding: '8px 16px'}} className='badge badge-light-danger'>
              {t('inactive')}{' '}
            </div>
          )}
        </td>

        <td className='text-center'>
          {isLoadingDelete ? (
            <SimpleLoading />
          ) : (
            <ActionsCell
              onEdit={
                store.permissions && store.permissions.includes('categories_update') && handleEdit
              }
              onDelete={
                store.permissions && store.permissions.includes('categories_delete') && handelDelete
              }
              onView={onView}
            />
          )}
        </td>
      </tr>
    </>
  )
}
