import {Box, Stack, Typography} from '@mui/material'
import React, {useState, useEffect} from 'react'
import LabelInput from '../../customTable/LabelInput'
import RedStart from '../../customTable/RedStart'
import InputType from '../../customTable/InputType'
import Tabs from './TabsWithOutFlag'
import TextErea from '../../customTable/TextErea'
import InputColor from '../../customTable/InputColor'
import UpdateFile from './UpdateFile'
import ErrorMsg from '../../customTable/ErrorMsg'
import TabsLangWithFlag from '../../customTable/LangTabs'
import Langs from '../../../Api/SettingLangs'
import {AnyAction} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {useTranslation} from 'react-i18next'
import UploadImgBtn from '../../customTable/UploadImgBtn'
import Label from '../../customTable/Label'
import Switch from '../../../componet/Toastr/Switch'

export default function Contant({setting, setSeeting, msgErr, errors}: any) {
  const [base64Image, setBase64Image] = React.useState('')
  const {t} = useTranslation()

  const [data, setData] = useState<any>({})

  const [defLang, setDefLang] = useState('')
  const store = useSelector((state: RootState) => state.vender)
  console.log(store, 'setting')

  const getLangs = async () => {
    setSelectedLang(store.langs)
    setDefLang(store.defLang)
  }
  const [selectedLang, setSelectedLang] = useState<any>([])
  useEffect(() => {
    getLangs()
  }, [store.langs])
  const copyLink = () => {
    navigator.clipboard
      .writeText(setting.identity_papers)
      .then(() => {})
      .catch(() => {})
  }
  console.log(setting, 'settingsetting')

  return (
    <Stack
      p='24px'
      borderRadius={'8px'}
      bgcolor={'var(--bs-app-header-base-bg-color)'}
      width={'100%'}
      minHeight={'351px'}
      mt={'23px'}
    >
      {/* <LabelInput text={t('identityPaper')} /> */}
      <Stack gap={'12px'} borderRadius={'6px'} mt='10px' alignItems={'center'} direction={'row'}>
        <UploadImgBtn
          p='4px'
          isPdf
          height='auto'
          note={'Only pdf , docx  files are accepted'}
          errors={errors}
          gap={!setting.update_identity_papers ? '8px' : '5px'}
          base64Image={base64Image}
          setBase64Image={setBase64Image}
          name='identity_papers'
          data={setting}
          setData={setSeeting}
          text={'identityPaper'}
        />

        {/* <UpdateFile
          setting={setting}
          setSeeting={setSeeting}
          name={'update_identity_papers'}
          base64Image={base64Image}
          setBase64Image={setBase64Image}
        /> */}
      </Stack>
      <Stack mt='40px' gap={'10px'}>
        <LabelInput text={t('siteUrl')} />
        <Stack
          bgcolor={'var(--bs-app-bg-color)'}
          gap='12px'
          alignItems={'center'}
          direction={'row'}
          borderRadius={'8px'}
          p='18px'
          sx={{color: '#009AFF'}}
        >
          <Typography>{setting.domain || localStorage.getItem('domain')} </Typography>
        </Stack>
      </Stack>

      <Stack width={'100%'}>
        <Stack width={'100%'}>
          <Stack
            gap='24px'
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            direction={'row'}
            mt='48px'
          >
            <Stack width={{lg: '48%', xs: '100%'}}>
              {selectedLang.map((e: any, i: number) => (
                <>
                  {e.code === defLang && (
                    <Stack gap='10px'>
                      <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                        <LabelInput text={t('siteTitle')} />

                        <RedStart />
                      </label>
                      <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                        <InputType
                          errors={errors}
                          name={'title'}
                          change={setSeeting}
                          value={setting}
                          type='text'
                          child={e.code}
                        />
                        <TabsLangWithFlag
                          pb={1}
                          activeLang={defLang}
                          setActiveLang={setDefLang}
                          languages={selectedLang}
                        />
                        {/* <Tabs /> */}
                        <ErrorMsg msgErr={msgErr} name='title' />
                      </Box>
                    </Stack>
                  )}
                </>
              ))}
            </Stack>
            <Stack width={{lg: '48%', xs: '100%'}} gap='10px'>
              <label style={{display: 'flex', gap: '5px'}}>
                <LabelInput text={t('storeMobileNumber')} />

                <RedStart />
              </label>
              <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                <InputType
                  checked
                  errors={errors}
                  name={'mobile'}
                  change={setSeeting}
                  value={{mobile: `${setting.phone_code}${setting.mobile}`}}
                  type='text'
                />
                {/* <ErrorMsg msgErr={msgErr} name='Description' /> */}
              </Box>
            </Stack>
          </Stack>
          <Stack mt='24px' width={{sm: '100%', xs: '100%'}}>
            {selectedLang.map((e: any, i: AnyAction) => (
              <>
                {e.code === defLang && (
                  <Stack gap='10px'>
                    <label style={{display: 'flex', gap: '5px'}} htmlFor={e.code}>
                      <LabelInput text={t('description')} />

                      {/* <RedStart /> */}
                    </label>
                    <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                      <TextErea
                        errors={errors}
                        name={'description'}
                        change={setSeeting}
                        value={setting}
                        child={e.code}
                      />
                      <TabsLangWithFlag
                        pb={1}
                        activeLang={defLang}
                        setActiveLang={setDefLang}
                        languages={selectedLang}
                      />{' '}
                    </Box>
                  </Stack>
                )}
              </>
            ))}
          </Stack>
          <Stack
            gap='24px'
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            direction={'row'}
            mt='24px'
          >
            <Stack width={{lg: '48%', xs: '100%'}} gap='10px'>
              <label style={{display: 'flex', gap: '5px'}}>
                <LabelInput text={t('defaultLang')} />
              </label>
              <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                <InputType
                  errors={errors}
                  name={'en'}
                  checked={true}
                  change={setSeeting}
                  value={setting?.language}
                  type='text'
                />
                {/* <ErrorMsg msgErr={msgErr} name='Description' /> */}
              </Box>
            </Stack>
            <Stack width={{lg: '48%', xs: '100%'}} gap='10px'>
              <label style={{display: 'flex', gap: '5px'}}>
                <LabelInput text={t('currency')} />
              </label>
              <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                <InputType
                  errors={errors}
                  checked={true}
                  name={'currency'}
                  change={setData}
                  value={
                    (setting && setting.currency) || {currency: localStorage.getItem('currency')}
                  }
                  type='text'
                />
                {/* <ErrorMsg msgErr={msgErr} name='Description' /> */}
              </Box>
            </Stack>
            <Stack width={{lg: '48%', xs: '100%'}} gap='10px'>
              <label style={{display: 'flex', gap: '5px'}}>
                <LabelInput text={t('email')} />
                <RedStart />
              </label>
              <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                <InputType
                  errors={errors}
                  change={setSeeting}
                  value={setting}
                  name={'email'}
                  type='text'
                />
              </Box>
            </Stack>
            <Stack width={{lg: '48%', xs: '100%'}} gap='10px'>
              <label style={{display: 'flex', gap: '5px'}}>
                <LabelInput text={t('defaultsku')} />
              </label>
              <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                <InputType
                  errors={errors}
                  name={'default_sku'}
                  change={setSeeting}
                  value={setting}
                  type='text'
                />
                {/* <ErrorMsg msgErr={msgErr} name='Description' /> */}
              </Box>
            </Stack>
            <Stack width={{lg: '48%', xs: '100%'}} gap='10px'>
              <label style={{display: 'flex', gap: '5px'}}>
                <LabelInput text={t('templateColor')} />
              </label>
              <Box position={'relative'} sx={{width: {sm: '100%', xs: '100%'}}}>
                <InputColor name={'color'} change={setSeeting} value={setting} type='color' />
                {/* <ErrorMsg msgErr={msgErr} name='Description' /> */}
              </Box>
            </Stack>

            <Stack alignItems={'center'} gap='24px' direction={'row'}>
              <Label fw='600' text={'putYourStoreUnderRepair'} />
              <Switch
                name='status'
                value={setting.is_maintenance === 1 ? true : false}
                handleChange={() => {
                  setSeeting({...setting, is_maintenance: setting.is_maintenance === 1 ? 0 : 1})
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
