import React from 'react'
import {useTranslation} from 'react-i18next'

export default function TableHead({isActive}: any) {
  const {t} = useTranslation()

  const headTable = [
    {
      name: t('srNo'),
      class: 'min-w-50px',
      value: 'vvv',
      width: '50px',
    },
    {
      name: t('orderID'),
      // class: 'min-w-150px ',
      value: 'vvv',
      width: '120px',
    },
    {
      name: t('customer'),
      // class: 'min-w-200px ',
      width: '180px',
    },

    {
      name: t('total'),
      width: '100px',
      value: 'vvv',
    },
    {
      name: t('paymentType'),
      width: '140px',
      value: 'vvv',
    },
    {
      name: t('dateAdded'),
      width: '120px',
      value: 'vvv',
    },
    {
      name: t('dateModified'),
      width: '120px',
      value: 'vvv',
    },
    {
      name: t('view'),
      class: 'max-w-120px',
      value: 'vvv',
    },
  ]

  return (
    <thead>
      <tr
        // style={{display:'flex',justifyContent:'space-between'}}
        className='fw-bold text-muted'
      >
        {headTable.map((e, i) => (
          <>
            <th
              style={{
                textAlign: i === headTable.length - 1 ? 'start' : 'start',
                minWidth: i === headTable.length - 1 ? '120px' : e.width,
              }}
              key={i}
              className={e.class}
            >
              {e.name}
            </th>
          </>
        ))}
      </tr>
    </thead>
  )
}
