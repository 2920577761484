import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Home from '../../sittings/address/GoogleMapReact'
import GoogleMap from './Test'
import InvoEn from './InvoEn'
import Location from '../../../svg/Location'
import Label from '../../customTable/Label'
import GoogleMapBtn from '../../customTable/GoogleMapBtn'

export default function AddressDetails({order}: any) {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)
  const [isShow, setIsShow] = useState(false)
  const handelShowMap = () => {
    setIsShow(!isShow)
  }
  console.log(order, 'order')

  return (
    <Stack
      p='35px 25px'
      mt='32px'
      borderRadius={'8px'}
      position={'relative'}
      sx={{
        background: 'url(/media/avatars/van.svg),var(--bs-body-bg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: store.isRtl !== 1 ? '90% 30%' : '10% 30%',
      }}
    >
      <Typography sx={{fontSize: '19px', fontWeight: '600'}}>{t('shippingAddress')}</Typography>
      <Stack gap='8px' mt='22px'>
        <Typography sx={{fontSize: '13px', fontWeight: '400'}}>
          {order.address_full_name}
        </Typography>
        <Typography sx={{fontSize: '13px', fontWeight: '400'}}>{order.address_phone}</Typography>
        <Typography sx={{fontSize: '13px', fontWeight: '400'}}>
          {order.address_country && order.address_country[store.lang]} -{' '}
          {order.address_city && order.address_city[store.lang]}
        </Typography>
        <Typography sx={{fontSize: '13px', fontWeight: '400'}}>{order.address_details}</Typography>
      </Stack>
      <Stack marginTop='20px' gap='24px' direction={'row'}>
        <button
          style={{
            width: '100px',
            color: 'white',
            background: '#009EF7',
            borderRadius: '8px',
            border: 'none',
            padding: '5px',
          }}
          onClick={handelShowMap}
        >
          {t('map')}
        </button>
        <GoogleMapBtn latitude={+order.latitude} longitude={+order.longitude} />
      </Stack>

      {isShow && order.latitude && order.longitude && (
        <GoogleMap location={{lat: +order.latitude, lng: +order.longitude}} />
      )}
    </Stack>
  )
}
