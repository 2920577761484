import React, {useState} from 'react'
import ErrorMsg from './NewMsgErr'

function DateInput({
  date,
  setDate,
  name,
  errors,
}: {
  date: string | undefined
  setDate: React.Dispatch<React.SetStateAction<string | undefined>>
  name: string
  errors?: any
}) {
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value)
  }

  return (
    <>
      <input
        type='date'
        id='dateInput'
        name='dateInput'
        value={date}
        onChange={handleDateChange}
        style={{
          padding: '12.0625px 13px 12.5px',
          background: 'var(--bs-body-bg)',
          border: '1px solid var(--bs-gray-300)',
          borderRadius: '6.18px',
          width: '100%',
          color: 'var(--bs-text-dark)',
        }}
      />
      {errors && errors[name] && typeof errors[name] === 'string' && (
        <ErrorMsg msgErr={errors[name]} />
      )}
    </>
  )
}

export default DateInput
