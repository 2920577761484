import {Stack} from '@mui/material'
import React from 'react'

export default function FixedNumber({num, isNum, fs, fw, color}: any) {
  const currency = localStorage.getItem('currency')

  return (
    <Stack
      sx={{fontSize: fs || 'auto', fontWeight: fw || 'auto', color: color || 'auto'}}
      width={'max-content'}
    >
      {' '}
      {!isNum && currency} {num && Number(num).toFixed(2)}
    </Stack>
  )
}
