import {Stack} from '@mui/material'
import React, {useEffect} from 'react'
import HomeApi from '../../../Api/home'
import Card from './Card'
import {useTranslation} from 'react-i18next'

export default function Cards({cards}: any) {
  const {t} = useTranslation()

  return (
    <Stack
      mt='30px'
      flexWrap={'wrap'}
      gap={'10px'}
      justifyContent={'space-between'}
      direction={'row'}
    >
      <Card name={t('totalEarnings')} num={cards.total_count} per={cards.total_percentage} />
      <Card name={t('totalProducts')} num={cards.products_count} per={cards.products_percentage} />
      <Card name={t('totalOrders')} num={cards.orders_count} per={cards.orders_percentage} />
      <Card
        name={t('totalCustomers')}
        num={cards.customers_count}
        per={cards.customers_percentage}
      />
    </Stack>
  )
}
