import React from 'react'

export default function Error403() {
  return (
    <>
      <img
        style={{width: '500px', height: '600px', margin: 'auto'}}
        src={'./media/avatars/403.png'}
      />
    </>
  )
}
