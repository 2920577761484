import {Stack} from '@mui/material'
import React, {useState} from 'react'
import LabelWithText from '../../customTable/LabelWithText'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import StaticInputFile from '../../customTable/StaticInputFile'
import CheckInputWithLabel from '../../customTable/CheckInputWithLabel'
import UploadImgBtn from '../../customTable/UploadImgBtn'
import EmptyInput from '../../customTable/EmptyInput'
import RestSaveButton from '../../customTable/RestSaveBtns'
import {useTranslation} from 'react-i18next'
import Label from '../../customTable/Label'
import TaxApi from '../../../Api/SettingTax'
import ValidationFun from '../../../yup/ValidationFun'
import {EditTaxSchema, TaxSchema} from '../../../yup/SettingSchema'

export default function EmptyFormTax({isEdit, data, setData, setOpen}: any) {
  const store = useSelector((state: RootState) => state.vender)
  const [errors, setErrors] = useState({})
  const [base64Image, setBase64Image] = useState('')
  const [isLoading, setisLoading] = useState(false)
  const [isEditForm, setIsEditForm] = useState(!isEdit ? true : false)
  const {t} = useTranslation()
  return (
    <Stack flexWrap={'wrap'} gap='24px' direction={'row'} p={isEdit ? '32px 0' : '32px'}>
      <Stack
        sx={{height: 'fit-content'}}
        width={{xs: '100%', md: 'calc(50% - 12px)'}}
        position={'relative'}
      >
        <LabelWithText
          disabled={!isEditForm}
          errors={errors}
          name='tax'
          data={data}
          setData={setData}
          text='tax'
        />
        {/* <Stack
          sx={{
            left: store.isRtl !== 0 ? '0px' : 'auto',
            right: store.isRtl === 0 ? '0px' : 'auto',
            bottom: '0px',
            borderRadius: '6.18px',
            color: '#fff',
            height: 'fit-content',
          }}
          p='13px'
          bgcolor={'var(--orange-color)'}
          position={'absolute'}
        >
          %
        </Stack> */}
      </Stack>
      <Stack width={{xs: '100%', md: 'calc(50% - 12px)'}}>
        <Label text='commercialRegister' />
        <UploadImgBtn
          disabled={!isEditForm}
          p='4px'
          isPdf
          height='auto'
          note={'Only pdf , docx  files are accepted'}
          isFormData
          errors={errors}
          gap={!data.commercial_file ? '8px' : '5px'}
          base64Image={base64Image}
          setBase64Image={setBase64Image}
          name='commercial_file'
          data={data}
          setData={setData}
        />
      </Stack>{' '}
      {!isEdit && (
        <Stack width='100%' gap='24px' direction={'row'}>
          <CheckInputWithLabel
            onClick={() => setData({...data, vendor_type: 'citizen'})}
            check={data.vendor_type}
            value={'citizen'}
            text={'citizen'}
          />
          <CheckInputWithLabel
            onClick={() => setData({...data, vendor_type: 'resident'})}
            check={data.vendor_type}
            value={'resident'}
            text={'resident'}
          />
        </Stack>
      )}{' '}
      {data.vendor_type === 'citizen' ? (
        <>
          <Stack width={{xs: '100%', md: 'calc(50% - 12px)'}}>
            {/* <Label text='front side image of ID' /> */}
            <UploadImgBtn
              disabled={!isEditForm}
              text='frontSideImageOfID'
              p='4px'
              errors={errors}
              isFormData
              height='auto'
              gap={!data.id_face_one ? '8px' : '5px'}
              base64Image={base64Image}
              setBase64Image={setBase64Image}
              name='id_face_one'
              data={data}
              setData={setData}
            />
          </Stack>
          <Stack width={{xs: '100%', md: 'calc(50% - 12px)'}}>
            {/* {data.backImage ? (
              <StaticInputFile text='backImage' />
            ) : (
              <EmptyInput text='backImage' />
            )} */}
            {/* <Label text='back side of image ID' /> */}

            <UploadImgBtn
              disabled={!isEditForm}
              height='auto'
              p='4px'
              isFormData
              text='backSideOfImageID'
              errors={errors}
              gap={!data.id_face_two ? '8px' : '5px'}
              base64Image={base64Image}
              setBase64Image={setBase64Image}
              name='id_face_two'
              data={data}
              setData={setData}
            />
          </Stack>
        </>
      ) : (
        <>
          {' '}
          <Stack width={{xs: '100%', md: 'calc(50% - 12px)'}}>
            {/* {data.backImage ? (
              <StaticInputFile text='backImage' />
            ) : (
              <EmptyInput text='backImage' />
            )} */}

            <UploadImgBtn
              disabled={!isEditForm}
              height='auto'
              isPdf
              text='passport'
              errors={errors}
              p='4px'
              gap={!data.passport ? '8px' : '5px'}
              base64Image={base64Image}
              setBase64Image={setBase64Image}
              name='passport'
              data={data}
              setData={setData}
            />
          </Stack>
        </>
      )}
      {store.permissions && store.permissions.includes('vendor_tax_create') && (
        <RestSaveButton
          isCancel={!isEdit}
          isLoading={isLoading}
          SaveText={t(isEditForm ? 'save' : 'edit')}
          cancelText={t('cancel')}
          submit={() => {
            if (isEditForm) {
              if (isEdit) {
                let newData: any = {}
                for (const key in data) {
                  if (key !== 'tax' && typeof data[key] !== 'string') {
                    newData[key] = data[key]
                  } else if (key === 'tax') {
                    newData[key] = data[key]
                  }
                }
                ValidationFun(newData, EditTaxSchema, setErrors, () => {
                  let formData = new FormData()
                  for (const key in newData) {
                    if (newData[key]) {
                      formData.append(key, newData[key])
                    }
                  }
                  TaxApi.put(
                    formData,
                    (res: any) => {
                      setIsEditForm(!true)
                    },
                    setisLoading
                  )
                })
              } else {
                ValidationFun({...data, isFormData: true}, TaxSchema, setErrors, () => {
                  TaxApi.store(
                    {...data, isFormData: true},
                    (res: any) => {
                      setOpen(false)
                    },
                    setisLoading
                  )
                })
              }
            } else {
              setIsEditForm(true)
            }
          }}
          cancel={() => {
            setOpen(false)
          }}
        />
      )}
    </Stack>
  )
}
