import React, {useState, useEffect} from 'react'
import {ActionsButton} from './actionsButton'
import UploadImg from '../../../custom/UploadImg'
import {useParams} from 'react-router-dom'
import AddActionBtns from './AddActionBtn'
import {Stack, Tooltip} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import ErrorMsg from '../../customTable/NewMsgErr'

export default function OptionImage({
  index,
  option,
  formik,
  handleRemoveOption,
  handleEditOption,
  handelSaveOption,
  msgErr,
  setActiveAdd,
  Adderrors,
}: any) {
  const {id} = useParams<{
    id?: string
  }>()
  const {t} = useTranslation()

  const [isActive, setIsActive] = useState(id && option.option_id ? true : false)
  const [rowSlectedToEdit, setRowSlectedToEdit] = useState<number>(-1)
  const [errors, setErrors] = useState<any>({})

  const [isDelete, setIsDelete] = useState(false)
  const [isAllowToEdit, setIsAllowToEdit] = useState<boolean>(false)
  const handleRemove = async (index: number) => {
    handleRemoveOption(formik.values.optionDetails[index].id)
  }

  const handleEdit = async (index: number) => {
    handleEditOption(
      formik.values.optionDetails[index].id,
      formik.values.optionDetails[index]
    ).then((x: any) => {
      setRowSlectedToEdit(-1)
    })
  }
  const handelNewSaveOption = async (index: number) => {
    handelSaveOption(formik.values.optionDetails[index]).then((x: any) => {
      setRowSlectedToEdit(-1)
    })
  }
  const [err, setError] = useState('')
  const store = useSelector((state: RootState) => state.vender)

  const [langs, setLangs] = useState([])
  useEffect(() => {
    setLangs(store.langs)
  }, [store.langs])
  console.log(Adderrors[`optionDetails[${index}].option_value.ar`], 'er')

  return (
    <>
      {!isDelete && (
        <>
          {/* <ErrorMsg msgErr={msgErr} name={`image`} /> */}
          <div style={{padding: '23px'}} key={index} className='row mb-8 g-4 custom-box my-4  '>
            {/*   2 cols */}
            {err.includes('image') && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{err}</div>
              </div>
            )}
            <UploadImg
              name='option_image'
              addName='optionDetails'
              err={err}
              isActive={isActive}
              id={id}
              img={option.option_image}
              isAllowToEdit={isAllowToEdit}
              rowSlectedToEdit={rowSlectedToEdit}
              index={index}
              option={option}
              formik={formik}
              errors={errors}
              Adderrors={Adderrors}
              hight={'option_height'}
              width={'option_width'}
            />

            <div className='col-lg-10 mb-3'>
              <div className='row'>
                {langs.map((lang: any) => (
                  <div key={lang.code} className='col-lg-6 fv-row fv-plugins-icon-container'>
                    <label className='fs-6 fw-semibold form-label mt-3'>
                      <span className='required'>
                        {t('optionValueName')} {lang.code.toUpperCase()}
                      </span>
                    </label>

                    <input
                      type='text'
                      className='form-control form-control-lg'
                      disabled={isActive}
                      {...formik.getFieldProps(`optionDetails.${index}.option_value.${lang.code}`)}
                    />
                    {(errors && errors[`option_value.${lang.code}`] && (
                      <ErrorMsg child={lang.code} msgErr={errors[`option_value.${lang.code}`]} />
                    )) ||
                      (Adderrors &&
                        Adderrors[`optionDetails[${index}].option_value.${lang.code}`] && (
                          <ErrorMsg
                            child={lang.code}
                            msgErr={Adderrors[`optionDetails[${index}].option_value.${lang.code}`]}
                          />
                        ))}
                  </div>
                ))}
              </div>
              <div className='row'>
                <div className='col-lg-6 fv-row fv-plugins-icon-container'>
                  <label className='fs-6 fw-semibold form-label mt-3'>
                    <span className='required'>
                      {t('sort')}
                      {/* {!!id &&
                    isAllowToEdit &&
                    rowSlectedToEdit !== index &&
                    Boolean(option?.id !== 'created')
                      ? '1'
                      : '2'} */}
                    </span>
                  </label>
                  <input
                    min={0}
                    type='number'
                    disabled={isActive}
                    className='form-control form-control-lg'
                    {...formik.getFieldProps(`optionDetails.${index}.sort`)}
                  />
                </div>
                {id && option.option_id ? (
                  <ActionsButton
                    setErrors={setErrors}
                    isAllowToEdit={
                      isAllowToEdit &&
                      rowSlectedToEdit !== index &&
                      Boolean(option?.id !== 'created')
                    }
                    index={index}
                    setIsAllowToEdit={setIsAllowToEdit}
                    handleRemove={handleRemove}
                    handleEdit={handleEdit}
                    isCreated={Boolean(option?.id !== 'created')}
                    setRowSlectedToEdit={setRowSlectedToEdit}
                    handelSaveOption={handelNewSaveOption}
                    isActive={isActive}
                    option={option}
                    setIsActive={setIsActive}
                    setIsDelete={setIsDelete}
                    setError={setError}
                    setActiveAdd={setActiveAdd}
                  />
                ) : null}
                <Stack
                  justifyContent={'flex-end'}
                  gap='15px'
                  direction={'row'}
                  alignContent={'center'}
                >
                  {option.id === -1 && (
                    <button
                      style={{margin: '10px 0', background: 'var(--orange-color) !important'}}
                      type='button'
                      onClick={() => {
                        setActiveAdd(false)

                        formik.setFieldValue(
                          'optionDetails',
                          formik.values.optionDetails.filter(
                            (e: any) => e.deleteId !== option.deleteId
                          )
                          // formik.values.option_details.length - 2
                        )
                      }}
                      className='btn btn-icon btn-danger btn-sm'
                    >
                      <i className='bi bi-trash fs-7'></i>
                    </button>
                  )}
                  {id && !option.option_id && (
                    <AddActionBtns
                      setError={setError}
                      id={id}
                      option={option}
                      setIsDelete={setIsDelete}
                      formik={formik}
                      setActiveAdd={setActiveAdd}
                      setErrors={setErrors}
                    />
                  )}
                </Stack>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
