import React, {useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Stack, Typography} from '@mui/material'
import TitlePage from '../customTable/TitlePage'
import {KTSVG} from '../../../_metronic/helpers'
import Table from './Table'
import Create from './Create'
import {useTranslation} from 'react-i18next'
export default function Products({withOutTitle}: any) {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('products'),
    home: 'Home',
    linkHome: '/',
  }
  const [rows, setRows] = useState<any>([])

  return (
    <Stack>
      {!withOutTitle && (
        <Stack justifyContent={'space-between'} direction={'row'}>
          <TitlePage info={bannerInfo} />
        </Stack>
      )}

      <Table rows={rows} setRows={setRows} />
    </Stack>
  )
}
// var(--bs-body-bg)
