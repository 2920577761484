import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'

const Customer = {
  get: (search: string, page: number, pagerow: number, callBack: any, setIsLoading: any) => {
    let url = `/vendor/customers?page=${page}&per_page=${pagerow}`
    if (search) {
      url = url + `&search=${search}`
    }

    ApisMethods().get(url, callBack, setIsLoading)
  },
  putStatus: (id: any, status: number, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/customers/status/${id}`, {status: status}, callback, setIsLoading)
  },

  getUsersForNotifacations: async (
    search: string,
    page: number,
    pagerow: number,
    callBack: any,
    setIsLoading: any
  ) => {
    let url = `/vendor/user-notifications/get-users?page=${page}&per_page=${pagerow}`
    if (search) {
      url = url + `&search=${search}`
    }
    ApisMethods().get(url, callBack, setIsLoading)
  },
  add: (values: any, codeNum: any, callback: any, setIsLoading: any) => {
    const data = {
      gender: values.gender === 'Male' ? 'male' : 'female',
      status: values.status,
      password: values.password,
      password_confirmation: values.confirmPassword,
      mobile: values.contactPhone,
      city_id: values.city,
      country_id: values.country,
      full_name: values.fullName,
      email: values.email,
      image: values.avatar,
      phone_code: +codeNum || +966,
    }
    ApisMethods().post(`/vendor/customers`, data, callback, setIsLoading)
  },
  getUserById: async (id: string | undefined, callBack: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/customers/${id}`, callBack, setIsLoading)
  },

  editUserById: async (
    id: string | undefined,
    values: any,
    codeNum: string,
    callback: any,
    setIsLoading: any
  ) => {
    const data: any = {
      gender: values.gender === 'Male' ? 'male' : 'female',
      status: values.status,
      mobile: +values.contactPhone,
      city_id: values.city,
      country_id: values.country,
      full_name: values.fullName,
      email: values.email,
      phone_code: +codeNum || +966,
    }

    if (values.avatar.includes('data')) {
      data.image = values.avatar
    }
    ApisMethods().put(`/vendor/customers/${id}`, data, callback, setIsLoading)

    // const customer = await Apiservices.put(`/vendor/customers/${id}`, data)
    // return customer
  },
  putVerified: (id: number | undefined, status: number, callback: any, setIsLoading: any) => {
    ApisMethods().put(
      `/vendor/customers/verify/${id}`,
      {
        is_verified: status,
      },
      callback,
      setIsLoading
    )
  },

  destroy: (id: string, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(`/vendor/customers/${id}`, callback, setIsLoading)
  },
  getCountries: async (callback: any, setIsLoading: any) => {
    ApisMethods().get('/vendor/settings/get-countries', callback, setIsLoading)

    // const countries = await Apiservices('/vendor/settings/get-countries')
    // return countries
  },
  getCities: async (id: number, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/settings/country/${id}/cities`, callback, setIsLoading)
  },
  getExcel: async () => {
    const excel = await Apiservices.get(`/vendor/customers/export/excel`)
    return excel
  },
  resetCustomerPassword: async (data: any, id: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/customers/edit-password/${id}`, data, callback, setIsLoading)
  },
}

export default Customer
