import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import IBanner from '../interface/IBanner'
import ApisMethods from './apisMethods'

const AppMaker = {
  //*
  get: (android: any, callBack: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/app-maker/${android ? 'android' : 'ios'}`, callBack, setIsLoading)
  },

  add: (data: any, callback: any, setIsLoading: any, android: any) => {
    ApisMethods().post(
      `/vendor/app-maker/${android ? 'android' : 'ios'}`,
      data,
      callback,
      setIsLoading
    )
  },

  cancel: (data: any, callback: any, setIsLoading: any, android: any) => {
    ApisMethods().post(
      `/vendor/app-maker/cancel-${android ? 'android' : 'ios'}`,
      data,
      callback,
      setIsLoading
    )
  },

  delete: (android: any, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(
      `/vendor/app-maker/${android ? 'android' : 'ios'}`,
      callback,
      setIsLoading
    )
  },
}
export default AppMaker
