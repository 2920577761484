import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {ProductListFilter} from './FilterOptions'
import {useTranslation} from 'react-i18next'
import {actions} from '../../../store/vender/Auth/auth.slice'
import {useDispatch} from 'react-redux'
import {Stack} from '@mui/material'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import MainBtn from '../../customTable/MainBtn'

type Props = {
  search: string
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const TooBar = ({search, handleSearch}: Props) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack
      p='24px 29px'
      width={'100%'}
      direction={'row'}
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      gap='15px'
    >
      <div className=' mb-md-0'>
        <div className='position-relative'>
          <Stack m='0 10px'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50  translate-middle-y'
            />
          </Stack>
          <input
            style={{
              textIndent: '2rem',
            }}
            value={search}
            type='text'
            className='form-control'
            name='search'
            onChange={handleSearch}
            placeholder={t('search') || 'Search'}
          />
        </div>
      </div>
      <div className='d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-wrap align-items-center'>
          {/* <ProductListFilter
                isLoading={false}
                onReset={() => {}}
                onFilter={() => {}}
                onChangeStatus={() => {}}
                status=''
              /> */}
          {store.permissions && store.permissions.includes('products_create') && (
            <MainBtn
              handelSubmit={() => {
                dispatch(actions.setViewProduct({}))

                navigate('/catalog/add-product')
                dispatch(actions.setCashData({}))
                dispatch(actions.setCashCataData({description: {}}))
              }}
              text={'addProduct'}
            />
          )}
        </div>
      </div>
    </Stack>
  )
}
