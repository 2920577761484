import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import BasicSpeedDial from '../option/Actions'
import Actons from '../option/Actions'
import VariationsAction from './VariationsAction'
import EditPopUp from './EditPopUp'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../store'

export default function VariationsRowStatic({image, i, options}: any) {
  const [isDelete, setIsDelete] = useState(true)
  const store = useSelector((state: RootState) => state.vender)
  const {variationImg} = useSelector((state: RootState) => state.vender)

  return (
    <>
      {isDelete && (
        <Stack
          sx={{
            borderTop: '1px solid var(--bs-gray-300)',
            borderBottom: '1px solid var(--bs-gray-300)',
          }}
          direction={'row'}
          width='100%'
        >
          <Stack
            width={'76px'}
            justifyContent={'center'}
            sx={{
              borderRight: '1px solid var(--bs-gray-300)',
              fontSize: '14px',
              fontWeight: 500,
              padding: '12px',
            }}
          >
            #{i + 1}
          </Stack>
          <Stack
            height={'120px'}
            width={'250px'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <img style={{width: '80px', height: '80px'}} src={variationImg} alt='' />
          </Stack>
          <Stack
            width={'250px'}
            justifyContent={'center'}
            gap={'10px'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            {options.map((e: any, i: number) => (
              <Stack gap={'5px'} direction={'row'} key={i}>
                <Typography align='center'>
                  {e.option.name
                    ? e.option.name[store.lang] || e.option.name[store.defLang]
                    : 'any'}{' '}
                  :
                </Typography>
                <Typography align='center'>any</Typography>
              </Stack>
            ))}
          </Stack>
          <Stack
            justifyContent={'center'}
            width={'93px'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <Typography align='center'>0</Typography>
          </Stack>
          <Stack
            justifyContent={'center'}
            width={'70px'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <Typography align='center'>0 </Typography>
          </Stack>{' '}
          <Stack
            justifyContent={'center'}
            width={'70px'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <Typography align='center'>0 </Typography>
          </Stack>{' '}
          <Stack
            justifyContent={'center'}
            width={'70px'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <Typography align='center'>0 </Typography>
          </Stack>
          <Stack
            justifyContent={'center'}
            width={'70px'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <Typography align='center'>0</Typography>
          </Stack>
          <Stack
            justifyContent={'center'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
            width={'70px'}
          >
            <Typography align='center'>0</Typography>
          </Stack>
          <Stack justifyContent={'center'} position={'relative'} width={'69px'}></Stack>
        </Stack>
      )}
    </>
  )
}
