import {Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import MainBtn from '../customTable/MainBtn'

export default function Tabs({
  active,
  setActive,
}: {
  active: number
  setActive: React.Dispatch<React.SetStateAction<number>>
}) {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const tabs = [
    {name: t('general'), isRead: store.permissions && store.permissions.includes('general_read')},
    {
      name: t('socialLinks'),
      isRead: store.permissions && store.permissions.includes('social_read'),
    },
    {
      name: t('languages'),
      isRead: store.permissions && store.permissions.includes('languages_read'),
    },
    {name: t('seo'), isRead: store.permissions && store.permissions.includes('seo_read')},
    {name: t('address'), isRead: store.permissions && store.permissions.includes('address_read')},
    {name: t('tax'), isRead: store.permissions && store.permissions.includes('vendor_tax')},
    {name: t('currency'), isRead: true},
    {name: t('order'), isRead: true},
  ]
  return (
    <Stack sx={{overflowX: 'auto'}} p={'60px 0 20px 0'} gap='40px' direction={'row'}>
      {tabs.map((e, i) => (
        <Stack width={'max-content'}>
          {e.isRead && (
            <MainBtn
              without={i === active ? false : true}
              handelSubmit={() => setActive(i)}
              width={'max-content'}
              text={e.name}
            />
          )}
        </Stack>
      ))}
      <Stack width={'20px'}></Stack>
    </Stack>
  )
}
