import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {Stack} from '@mui/material'

export default function SelectGroup({
  selectedOption,
  selected,
  setSelected,
  disabled,
  setOptionValue,
  setIsAddedOption,
}: any) {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = parseInt(event.target.value)
    const selectedItem = selectedOption
      .flatMap((item: any) => item.options)
      .find((item: any) => item.id === selectedId)
    setSelected(selectedItem)
    setItem(selectedItem.name.en)
    setIsAddedOption(false)
    setOptionValue([])
  }
  useEffect(() => {
    if (!selected) {
      setItem('Select an option')
    } else if (selected.name) {
      setItem(selected.name[store.lang] || selected.name[store.defLang])
    }
  }, [selected])
  const [item, setItem] = useState('Select an option')
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack sx={{border: '1px solid var(--bs-gray-300)', borderRadius: '6.18px'}}>
      <select
        value=''
        style={{
          padding: '12.0625px 13px 12.5px',
          background: 'var(--bs-body-bg)',
          border: 'none',
          color: 'var(--bs-text-dark)',
          borderRadius: '6.18px',
          width: 'calc(100% - 10px)',
        }}
        disabled={disabled}
        onChange={handleChange}
      >
        <option value='' disabled hidden>
          {item}
        </option>
        {selectedOption.map((e: any, i: number) => (
          <optgroup
            style={{
              color: '#B5B5C3',
              fontWeight: '300',
              fontSize: '14px',
              margin: '50px 0',
            }}
            label={e.name && (e.name[store.lang] || e.name[store.defLang])}
          >
            {e.options.map((a: any, i: number) => (
              <option
                style={{
                  color: 'var(--bs-heading-color)',
                  fontWeight: '300',
                  fontSize: '14px',
                }}
                value={a.id}
              >
                <span>{a.name && (a.name[store.lang] || a.name[store.defLang])}</span>
                <span style={{color: 'red'}}> ({a.value_type})</span>
              </option>
            ))}
          </optgroup>
        ))}
      </select>
    </Stack>
  )
}
