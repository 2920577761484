import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import IBanner from '../interface/IBanner'
import ApisMethods from './apisMethods'

const ServiseFees = {
  get: async (
    searchText: string,
    rowsPerPage: number,
    page: number,
    switchValue: string,
    callBack: any,
    setIsLoading: any
  ) => {
    ApisMethods().get(
      `/vendor/services_fees/${switchValue}?page=${page}&per_page=${rowsPerPage}&search=${searchText}`,
      callBack,
      setIsLoading
    )
  },
  getValue: async (callBack: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/services_fees/value`, callBack, setIsLoading)
  },
  pay: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/services_fees/pay`, data, callback, setIsLoading)
  },
  confirm: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/services_fees/pay/confirm`, data, callback, setIsLoading)
  },
  getOrders: async (
    id: any,

    callBack: any,
    setIsLoading: any,
    rowsPerPage: number,
    page: number
  ) => {
    ApisMethods().get(`/vendor/services_fees/${id}/orders`, callBack, setIsLoading)
    // ?page=${page}&per_page=${rowsPerPage}
  },

  // getDitals: (id: number | undefined, callback: any, setIsLoading: any) => {
  //   ApisMethods().get(`/vendor/coupons/${id}`, callback, setIsLoading)
  // },
  // putDetails: (value: any, id: number | undefined) => {
  //   return Apiservices.put(`/vendor/coupons/${id}`, value)
  //     .then((response) => {
  //       return response.data
  //     })
  //     .catch((error) => {
  //       throw error
  //     })
  // },
}
export default ServiseFees
