import React from 'react'

export default function InProgress({color}: any) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.3341 11.9404C15.3474 12.4404 15.2141 12.9137 14.9741 13.3204C14.8408 13.5604 14.6608 13.7804 14.4608 13.9604C14.0008 14.387 13.3941 14.647 12.7208 14.667C11.7474 14.687 10.8874 14.187 10.4141 13.4204C10.1608 13.027 10.0074 12.5537 10.0008 12.0537C9.98077 11.2137 10.3541 10.4537 10.9541 9.9537C11.4074 9.58037 11.9808 9.34704 12.6074 9.3337C14.0808 9.30037 15.3008 10.467 15.3341 11.9404Z'
        stroke={color || '#A8A9AD'}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.6289 12.0202L12.3022 12.6602L13.6956 11.3135'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M2.11328 4.95996L7.99994 8.36662L13.8466 4.97994'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8 14.406V8.35938'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.4071 6.11301V9.88637C14.4071 9.9197 14.4071 9.94633 14.4004 9.97967C13.9337 9.573 13.3337 9.33302 12.6671 9.33302C12.0404 9.33302 11.4604 9.55302 11.0004 9.91969C10.3871 10.4064 10.0004 11.1597 10.0004 11.9997C10.0004 12.4997 10.1404 12.973 10.3871 13.373C10.4471 13.4797 10.5204 13.5797 10.6004 13.673L9.38041 14.3464C8.62041 14.773 7.3804 14.773 6.6204 14.3464L3.0604 12.373C2.25374 11.9264 1.59375 10.8064 1.59375 9.88637V6.11301C1.59375 5.19301 2.25374 4.07302 3.0604 3.62636L6.6204 1.65301C7.3804 1.22634 8.62041 1.22634 9.38041 1.65301L12.9404 3.62636C13.7471 4.07302 14.4071 5.19301 14.4071 6.11301Z'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
