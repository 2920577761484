import {Radio, Stack} from '@mui/material'
import React, {useEffect} from 'react'
import Label from '../../customTable/Label'
import Switch from '../../../componet/Toastr/Switch'
import RestSaveButton from '../../customTable/RestSaveBtns'
import InputType from '../../customTable/InputType'
import ValidationFun from '../../../yup/ValidationFun'
import General from '../../../Api/SettingGeneral'
import MainBtn from '../../customTable/MainBtn'
import {OrderSchema} from '../../../yup/SettingSchema'

export default function Order() {
  const [isLoading, setIsLoading] = React.useState(false)
  const [errors, setErrors] = React.useState<any>({})
  const [data, setData] = React.useState<any>({
    is_cancel_order: 1,
    is_refund_order: 1,
    refund_duration_type: 'day',
  })
  const submit = () => {
    ValidationFun(data, OrderSchema, setErrors, (value: any) => {
      General.EditOrder(
        {
          ...data,
        },
        (res: any) => {},
        setIsLoading
      )
    })
  }
  useEffect(() => {
    General.GetOrder((res: any) => {
      setData(res.data)
    }, setIsLoading)
  }, [])
  return (
    <Stack
      p='24px'
      borderRadius={'8px'}
      bgcolor={'var(--bs-app-header-base-bg-color)'}
      width={'100%'}
      // minHeight={'351px'}
      justifyContent={'space-between'}
    >
      <Stack>
        <Stack mt='35px' gap={{xs: '15px', sm: '90px'}} direction={'row'}>
          <Stack width={{md: '450px'}}>
            <Label fs={{xs: '14px', sm: '16px'}} fw='600' text='ActivateOrderCancellation' />
          </Stack>
          <Switch
            name='status'
            value={data.is_cancel_order === 1 ? true : false}
            handleChange={() => {
              setData({...data, is_cancel_order: data.is_cancel_order === 1 ? 0 : 1})
            }}
          />
        </Stack>
        <Stack mt='35px' gap={{xs: '15px', sm: '90px'}} direction={'row'}>
          <Stack width={{md: '450px'}}>
            <Label fs={{xs: '14px', sm: '16px'}} fw='600' text='ActivateRefundOrder' />
          </Stack>
          <Switch
            name='status'
            value={data.is_refund_order === 1 ? true : false}
            handleChange={() => {
              setData({...data, is_refund_order: data.is_refund_order === 1 ? 0 : 1})
            }}
          />
        </Stack>

        <Stack gap='14px' mt='35px'>
          <Label fw='600' text='RefundDuration' />
          <Stack gap='10px' direction={'row'}>
            <Stack alignItems={'center'} direction={'row'}>
              <Radio
                disabled={data.is_refund_order === 0 ? true : false}
                sx={{
                  color: 'var(--main-color)',
                  '&.Mui-checked': {
                    color: 'var(--main-color)',
                  },
                  mt: '4px',
                }}
                checked={data.refund_duration_type === 'houre' ? true : false}
                onClick={() => {
                  setData({...data, refund_duration_type: 'houre'})
                }}
              />
              <Label text='houre' />
            </Stack>{' '}
            <Stack alignItems={'center'} direction={'row'}>
              <Radio
                disabled={data.is_refund_order === 0 ? true : false}
                sx={{
                  color: 'var(--main-color)',
                  '&.Mui-checked': {
                    color: 'var(--main-color)',
                  },
                  mt: '4px',
                }}
                checked={data.refund_duration_type === 'day' ? true : false}
                onClick={() => {
                  setData({...data, refund_duration_type: 'day'})
                }}
              />
              <Label text='day' />
            </Stack>{' '}
          </Stack>{' '}
          <Stack maxWidth={'350px'}>
            <InputType
              checked={data.is_refund_order === 0 ? true : false}
              errors={errors}
              placeholder={'RefundDuration'}
              name={'refund_duration'}
              change={setData}
              value={data}
              type='number'
            />
          </Stack>
        </Stack>

        <Stack mt='15px' alignItems={'flex-end'}>
          <MainBtn isLoading={isLoading} width='auto' text='save' handelSubmit={submit} />
        </Stack>
      </Stack>
    </Stack>
  )
}
