import {Stack} from '@mui/material'
import React from 'react'
import Label from './Label'
import MainBtn from './MainBtn'
import {useNavigate} from 'react-router-dom'

export default function EmpityChangePackage({icon}: any) {
  const navagate = useNavigate()
  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      bgcolor={'#fff'}
      height={'62vh'}
      width={'100%'}
    >
      <Stack alignItems={'center'} gap='24px'>
        {icon}
        <Stack gap='12px'>
          <Label fw='600' fs='24px' color='#65656A' align='center' text={'upgradeYourPlanToday'} />
          <Stack width='100%' maxWidth={'542px'}>
            <Label
              fw='500'
              fs='14px'
              color='#5E6278'
              align='center'
              text={'upgradeYourPlanTodayDiscaripation'}
            />
          </Stack>
        </Stack>

        <MainBtn
          handelSubmit={() => {
            navagate('/subscriptions-change-package')
          }}
          width='fit-content'
          text='upgradeNaw'
        />
      </Stack>
    </Stack>
  )
}
