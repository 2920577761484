import React from 'react'
import {useTranslation} from 'react-i18next'

export default function TableHead() {
  const {t} = useTranslation()

  const headTable = [
    {
      name: t('orderID'),
      class: 'min-w-200px',
    },
    {
      name: t('serviseFees'),
      class: 'min-w-200px',
    },
    {
      name: t('total'),
      class: 'min-w-200px',
    },
    {
      name: t('dateAdded'),
      class: 'min-w-200px',
    },
  ]

  return (
    <thead>
      <tr
        // style={{display:'flex',justifyContent:'space-between'}}
        className='fw-bold text-muted'
      >
        {headTable.map((e, i) => (
          <th
            style={{
              textAlign: i === 0 ? 'start' : 'start',
            }}
            key={i}
            className={e.class}
          >
            {e.name}
          </th>
        ))}
      </tr>
    </thead>
  )
}
