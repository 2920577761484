import {Stack} from '@mui/material'
import React from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import ChangePackage from '../subscriptions/ChangePackage'
import All from '../../svg/packages/All'
import EmpityChangePackage from './ChangePackage'

export default function WarperBackages({children, backageKey, width}: any) {
  const {myPackage} = useSelector((state: RootState) => state.vender)
  const images: any = {
    all: <All />,
  }
  return (
    <Stack>
      {
        myPackage[backageKey] ? (
          children
        ) : (
          <EmpityChangePackage icon={images[backageKey] || images['all']} backageKey={backageKey} />
        )
        // <ChangePackage backageKey={backageKey} width={width} />
      }
    </Stack>
  )
}
