import React, {useState} from 'react'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import ErrorMsg from './NewMsgErr'
import {Stack} from '@mui/material'

export default function SelectByLang({
  name,
  values,
  option,
  value,
  setSelectData,
  errors,
  child,
}: {
  name: string
  values: any
  option: any
  value?: any
  setSelectData?: any
  errors?: any
  child?: string
}) {
  const [selectedOption, setSelectedOption] = useState<any>({id: value})

  const handleChange = (event: any) => {
    const selectedId = parseInt(event.target.value)
    const selectedItem = option.find((item: any) => item.id === selectedId)
    setSelectedOption(selectedItem)

    setSelectData(selectedItem)
    values[`${name}`] = +event.target.value
  }
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack>
      {' '}
      <select
        style={{
          padding: '12.0625px 13px 12.5px',
          background: 'var(--bs-body-bg)',
          border: '1px solid var(--bs-gray-300)',
          color: ' var(--bs-text-dark)',

          borderRadius: '6.18px',
          width: '100%',
          // backgroundColor: 'var(--bs-gray-100)',
          // borderColor: 'var(--bs-gray-100)',
        }}
        value={selectedOption.id}
        onChange={handleChange}
      >
        <option value={''} disabled hidden>
          select
        </option>
        {option.map((e: any) => (
          <option key={e.id} value={e.id}>
            {e.option_value && (e.option_value[store.lang] || e.option_value[store.defLang])}
          </option>
        ))}
      </select>
      {errors && !child && errors[name] && typeof errors[name] === 'string' ? (
        <ErrorMsg msgErr={errors[name]} />
      ) : errors &&
        child &&
        errors[`${name}.${child}`] &&
        typeof errors[`${name}.${child}`] === 'string' ? (
        <ErrorMsg child={child} msgErr={errors[`${name}.${child}`]} />
      ) : (
        ''
      )}
    </Stack>
  )
}
