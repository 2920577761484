/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {ProductSaleChartSection} from '../../../_metronic/partials/chart/productSaleChartSection'
import {SalesSection} from '../../../_metronic/partials/chart/SaleChartSection'
import {Chart2Section} from '../../../_metronic/partials/chart/section2'
import {
  CardsWidget17,
  CardsWidget20,
  CardsWidgetChart,
  ListsWidget3,
  TablesWidget9,
} from '../../../_metronic/partials/widgets'
import {TablesLastAddProduct} from '../../../_metronic/partials/widgets/tables/LastAddProduct'
import ResentOrder from './resntOrder'
import {useTranslation} from 'react-i18next'
import TitlePage from '../../modules/customTable/TitlePage'
import {Stack} from '@mui/system'
import ActionBtns from './parts/ActionBtns'
import Cards from './parts/Cards'
import {async} from 'q'
import HomeApi from '../../Api/home'
import TopSilling from './parts/TopSilling'
import DateInput from '../../modules/customTable/DateInput'
import DateInputChart from './parts/DateInputChart'
import {Typography} from '@mui/material'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import Table from './parts/Table'
import Loading from '../../componet/loading'
import Products from '../../modules/products'
import TopPayment from './parts/topPayment'
import Customers from './parts/Customers'
import Statistics from './parts/Statistics'

const DashboardPage: FC = () => {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('dashboard'),
    home: t('home'),
    linkHome: '/',
  }
  const [cards, setCards] = useState<any>({})
  const [charts, setCharts] = useState<any>({})
  const store = useSelector((state: RootState) => state.vender)
  const [loading, setLoading] = useState(true)
  console.log(cards, 'cards')

  return (
    <Stack position={'relative'}>
      <Stack justifyContent={'space-between'} gap='10px' direction={'row'}>
        <TitlePage info={bannerInfo} />

        <ActionBtns setLoading={setLoading} setCards={setCards} />
      </Stack>
      {loading ? (
        <Stack
          width={'100%'}
          zIndex={'200'}
          minHeight={'80vh'}
          height={'100%'}
          top='0'
          position={'absolute'}
        >
          <Stack width={'100%'} bgcolor={'#f5f8fa'} height={'100%'}>
            <Loading />
          </Stack>
        </Stack>
      ) : (
        <>
          {store.permissions && store.permissions.includes('dashboard_get-analysis') && (
            <>
              <Cards cards={cards} />
              <Stack gap='24px' direction={{lg: 'row'}} mt='24px'>
                <Stack
                  direction={{lg: 'column', sm: 'row'}}
                  width={{lg: 'calc(33% - 12px)', sm: '100%'}}
                  gap='24px'
                >
                  <TopPayment data={cards.top_payment} />
                  <Customers data={cards.customers} />
                </Stack>
                <Stack width={{lg: 'calc(77% - 12px)', xs: '100%'}}>
                  <Statistics data={cards.statistics} />
                </Stack>
              </Stack>
            </>
          )}

          <Stack
            mt='20px'
            gap='20px'
            width={'100%'}
            direction={'row'}
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            className='col-md-12 col-lg-6 mb-md-5  p-0  '
            style={{
              padding: '0px',
            }}
          >
            {store.permissions && store.permissions.includes('dashboard_top-selling') && (
              <TopSilling />
            )}

            {store.permissions && store.permissions.includes('dashboard_get-chart') && (
              <Stack
                sx={{
                  width: 'calc(65% - 10px)',
                  borderRadius: '8px',
                  bgcolor: 'var(--bs-app-footer-bg-color)',
                  justifyContent: 'space-between',
                  '@media (max-width: 1200px)': {
                    width: '100%',
                  },
                }}
              >
                <Stack
                  alignItems={'center'}
                  p='28px 28px 0 28px'
                  justifyContent={'space-between'}
                  direction={'row'}
                >
                  <Typography sx={{fontSize: '18px', fontWeight: '600'}}>{t('sales')}</Typography>
                  <DateInputChart setCharts={setCharts} />
                </Stack>
                <Stack sx={{overflow: 'auto'}} width={'100%'}>
                  <Stack sx={{overflow: 'auto'}} minWidth={{xs: '750px', md: '0'}}>
                    {' '}
                    <CardsWidgetChart
                      charts={charts}
                      className=''
                      description='Active Projects'
                      color='#F1416C'
                      img={toAbsoluteUrl('/media/patterns/vector-1.png')}
                    />
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>

          {store.permissions && store.permissions.includes('dashboard_last-orders') && <Table />}

          {store.permissions && store.permissions.includes('products_read') && (
            <Products withOutTitle />
          )}
        </>
      )}
    </Stack>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
