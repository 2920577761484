import React from 'react'
import MainBtn from '../../customTable/MainBtn'
import {Stack} from '@mui/material'
import PopUp from '../../customTable/PopUp'
import RowBtn from './RowBtn'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function RowBtns({
  colors,
  prices,
  duration,
  type,
  name,
  paidId,
  freeId,
  paidDurationId,
}: any) {
  const {sizeImages} = useSelector((state: RootState) => state.vender)
  const [isChoose, setIsChoose] = React.useState(-1)
  let num = -1
  return (
    <tr className='fw-bold text-muted '>
      <td></td>
      {Object.entries(colors).map(([key, value]: any, i) => {
        if (
          sizeImages &&
          sizeImages.subscription &&
          sizeImages.subscription.package_type === 'free'
        ) {
          if (sizeImages.subscription.package_free_id == freeId[key]) {
            num = i
          }
        } else if (
          sizeImages &&
          sizeImages.subscription &&
          sizeImages.subscription.package_type === 'paid'
        ) {
          if (sizeImages.subscription.package_paid_id == paidId[key]) {
            num = i
          }
        } else if (
          sizeImages &&
          sizeImages.subscription &&
          sizeImages.subscription.package_type === 'paid_duration'
        ) {
          if (sizeImages.subscription.package_paid_id == paidDurationId[key]) {
            num = i
          }
        }

        return (
          <td>
            <Stack alignItems={'center'}>
              <RowBtn
                name={name}
                type={type}
                duration={duration}
                prices={prices}
                value={value}
                index={key}
                i={num}
                text={num > i ? 'upgrade' : num < i ? 'downgrade' : 'currentPlan'}
              />
            </Stack>
          </td>
        )
      })}
    </tr>
  )
}
