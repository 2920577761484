import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {useTranslation} from 'react-i18next'
import {Stack} from '@mui/material'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import MainBtn from '../../customTable/MainBtn'

type Props = {
  search: string
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
  num: any
}

export const TooBar = ({search, handleSearch, num}: Props) => {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const navigate = useNavigate()
  return (
    <Stack
      gap='15px'
      flexWrap={'wrap'}
      p='29.5px 29.5px 0 29.5px'
      justifyContent={'space-between'}
      direction={'row'}
    >
      <div className=' mb-md-0'>
        <div className='position-relative'>
          <Stack m='0 10px'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50  translate-middle-y'
            />
          </Stack>

          <input
            style={{
              textIndent: '2rem',
            }}
            value={search}
            type='text'
            className='form-control'
            name='search'
            onChange={handleSearch}
            placeholder={t('search') || 'search'}
          />
        </div>
      </div>{' '}
      {store.permissions && store.permissions.includes('options_create') && (
        <MainBtn
          num={num}
          backageKey='OPTION_NUM'
          width='auto'
          handelSubmit={() => {
            navigate('/catalog/add-option')
          }}
          text='create'
        />
      )}
    </Stack>
    // <div className='card-header border-0 pt-5'>
    //   <div className='card-toolbar w-100'>
    //     <Stack
    //       gap='15px'
    //       className='d-flex flex-wrap justify-content-between align-items-center w-100'
    //     >
    //       <div className=' mb-md-0'>
    //         <div className='position-relative'>
    //           <Stack m='0 10px'>
    //             <KTSVG
    //               path='/media/icons/duotune/general/gen021.svg'
    //               className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50  translate-middle-y'
    //             />
    //           </Stack>

    //           <input
    //             style={{
    //               textIndent: '2rem',
    //             }}
    //             value={search}
    //             type='text'
    //             className='form-control'
    //             name='search'
    //             onChange={handleSearch}
    //             placeholder={t('search') || 'search'}
    //           />
    //         </div>
    //       </div>
    //       <div className='d-flex flex-wrap align-items-center'>
    //         <div className='d-flex flex-wrap align-items-center'>
    //           {store.permissions && store.permissions.includes('options_create') && (
    //             <MainBtn
    //               num={num}
    //               backageKey='OPTION_NUM'
    //               without
    //               width='auto'
    //               handelSubmit={() => {
    //                 navigate('/catalog/add-option')
    //               }}
    //               text='create'
    //             />
    //           )}
    //         </div>
    //       </div>
    //     </Stack>
    //   </div>
    // </div>
  )
}
