import {Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

export default function TitlePage({info}: any) {
  const {t} = useTranslation()
  return (
    <Stack gap='5px'>
      <Typography sx={{fontSize: '1.23rem', fontWeight: '600', color: 'var(--bs-body-color)'}}>
        {info.title}
      </Typography>
      <Stack alignItems={'center'} gap='7px' direction={'row'}>
        <Link style={{textDecoration: 'none'}} to={'/'}>
          <Typography
            sx={{
              color: '#009EF7  !important',
              fontSize: '13px',
              fontWeight: '500',
              width: 'max-content',
            }}
          >
            {t('home')}
          </Typography>
        </Link>
        »
        <Typography
          sx={{
            color: 'var(--bs-text-muted) !important',
            fontSize: '13px',
            fontWeight: '500',
            width: 'max-content',
          }}
        >
          {info.title}
        </Typography>
      </Stack>
    </Stack>
  )
}
