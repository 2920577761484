import {Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import Label from '../customTable/Label'

export default function Tabs({value, setValue}: any) {
  const {t} = useTranslation()

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      // gap='10px'
      sx={{
        border: '1px solid var(--bs-gray-300)',
        borderRadius: '8px',
        height: '52px',
        // width: '210px',
        background: 'var(--bs-body-bg)',
      }}
    >
      {' '}
      <button
        style={{
          background: value === 'cash' ? 'var(--main-color)' : '#ff000000',
          color: value !== 'online' ? '#fff' : 'var(--bs-text-dark)',
          border: 'none',
          borderRadius: '8px',
          padding: '16px 22px',
        }}
        onClick={() => {
          setValue('cash')
        }}
      >
        <Label
          color={value !== 'online' ? '#fff' : 'var(--bs-text-dark)'}
          fs={{xs: '10px', sm: '13px'}}
          text={'orderWithCash'}
        />
      </button>
      <button
        style={{
          background: value === 'online' ? 'var(--main-color)' : '#ff000000',
          color: value === 'online' ? '#fff' : 'var(--bs-text-dark)',
          border: 'none',
          borderRadius: '8px',
          padding: '16px 22px',
        }}
        onClick={() => {
          setValue('online')
        }}
      >
        <Label
          color={value === 'online' ? '#fff' : 'var(--bs-text-dark)'}
          fs={{xs: '10px', sm: '13px'}}
          text={'orderWithKoninPay'}
        />
      </button>
    </Stack>
  )
}
