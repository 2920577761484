import React, {useState, useEffect} from 'react'
import {ActionsButton} from './actionsButton'
import UploadImg from '../../../custom/UploadImg'
import {useParams} from 'react-router-dom'
import AddActionBtns from './AddActionBtn'
import {Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import ErrorMsg from '../../customTable/NewMsgErr'

export default function TextOption({
  index,
  option,
  formik,
  disabled,
  handleRemoveOption,
  handleEditOption,
  msgErr,
  setActiveAdd,
  Adderrors,
}: any) {
  const {id} = useParams<{
    id?: string
  }>()
  const {t} = useTranslation()

  const [isActive, setIsActive] = useState(id && option.option_id ? true : false)
  const [rowSlectedToEdit, setRowSlectedToEdit] = useState<number>(-1)

  const [isDelete, setIsDelete] = useState(false)
  const [isAllowToEdit, setIsAllowToEdit] = useState<boolean>(false)
  const handleRemove = async (index: number) => {
    handleRemoveOption(formik.values.optionDetails[index].id)
  }

  const handleEdit = async (index: number) => {
    handleEditOption(
      formik.values.optionDetails[index].id,
      formik.values.optionDetails[index]
    ).then((x: any) => {
      setRowSlectedToEdit(-1)
    })
  }

  const [err, setError] = useState('')
  const store = useSelector((state: RootState) => state.vender)
  const [langs, setLangs] = useState([])
  useEffect(() => {
    setLangs(store.langs)
  }, [store.langs])
  const [errors, setErrors] = useState<any>({})

  return (
    <>
      {!isDelete && (
        <div
          style={{padding: '20px ', width: '100%'}}
          key={index}
          className='custom-box bg-whit my-4'
        >
          <div className='row pt-5 '>
            {langs.map((lang: any) => (
              <div key={lang.code} className='col-lg-6 fv-row fv-plugins-icon-container'>
                <label className='fs-6 fw-semibold form-label mt-3'>
                  <span className='required'>
                    {t('optionValueName')} {lang.code.toUpperCase()}
                  </span>
                  <span style={{color: 'var(--orange-color)', fontSize: '10px', margin: '0 5px'}}>
                    ( {t('noteMaxCaracter')})
                  </span>
                </label>

                <input
                  maxLength={7}
                  type='text'
                  className='form-control form-control-lg'
                  disabled={isActive}
                  {...formik.getFieldProps(`optionDetails.${index}.option_value.${lang.code}`)}
                />
                {(errors && errors[`option_value.${lang.code}`] && (
                  <ErrorMsg child={lang.code} msgErr={errors[`option_value.${lang.code}`]} />
                )) ||
                  (Adderrors && Adderrors[`optionDetails[${index}].option_value.${lang.code}`] && (
                    <ErrorMsg
                      child={lang.code}
                      msgErr={Adderrors[`optionDetails[${index}].option_value.${lang.code}`]}
                    />
                  ))}
              </div>
            ))}
          </div>
          <div className='row'>
            <div className='col-lg-6 fv-row fv-plugins-icon-container'>
              <label className='fs-6 fw-semibold form-label mt-3'>
                <span className='required'>{t('sort')}</span>
              </label>
              <input
                min={0}
                disabled={isActive}
                type='number'
                className='form-control form-control-lg'
                {...formik.getFieldProps(`optionDetails.${index}.sort`)}
              />
              {err.includes('sort') && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{err}</div>
                </div>
              )}
              {formik.errors[`sort.${index}`] && formik.touched[`sort.${index}`] ? (
                <div className='fv-plugins-message-container'>
                  {formik.errors[`sort.${index}`] as React.ReactNode}
                </div>
              ) : null}
            </div>
            {id && option.option_id ? (
              <ActionsButton
                isAllowToEdit={isAllowToEdit && rowSlectedToEdit !== index}
                index={index}
                setIsAllowToEdit={setIsAllowToEdit}
                handleRemove={handleRemove}
                handleEdit={handleEdit}
                isActive={isActive}
                option={option}
                setIsActive={setIsActive}
                setIsDelete={setIsDelete}
                setError={setError}
                setErrors={setErrors}
              />
            ) : null}
            <Stack justifyContent={'flex-end'} gap='15px' direction={'row'} alignContent={'center'}>
              {option.id === -2 && (
                <button
                  style={{margin: '10px 0', background: 'var(--orange-color) !important'}}
                  type='button'
                  onClick={() => {
                    setActiveAdd(false)

                    formik.setFieldValue(
                      'optionDetails',
                      formik.values.optionDetails.filter((e: any) => e.deleteId !== option.deleteId)
                      // formik.values.option_details.length - 2
                    )
                  }}
                  className='btn btn-icon btn-danger btn-sm'
                >
                  <i className='bi bi-trash fs-7'></i>
                </button>
              )}
              {id && !option.option_id && (
                <AddActionBtns
                  id={id}
                  formik={formik}
                  setError={setError}
                  option={option}
                  setIsDelete={setIsDelete}
                  setActiveAdd={setActiveAdd}
                  setErrors={setErrors}
                />
              )}
            </Stack>
          </div>
        </div>
      )}
    </>
  )
}
