import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../customTable/Label'
import {useTranslation} from 'react-i18next'
import FixedNumber from '../../customTable/FixedNumber'

export default function LabelWithValue({label, value, isPrice}: any) {
  const {t} = useTranslation()
  return (
    <Stack justifyContent={'space-between'} direction={'row'}>
      <Label fw='400' fs='16px' text={t(label)} />
      {isPrice ? (
        <FixedNumber fw='400' fs='16px' num={value} />
      ) : (
        <Label fw='400' fs='16px' text={t(value)} />
      )}
    </Stack>
  )
}
