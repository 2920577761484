import {Box, Stack} from '@mui/material'
import React, {useState, useEffect} from 'react'
import LabelInput from '../../customTable/LabelInput'
import RedStart from '../../customTable/RedStart'
import InputType from '../../customTable/InputType'
import ErrorMsg from '../../customTable/ErrorMsg'
import SaveButton from '../../customTable/SaveButton'
import RestSaveButton from '../../customTable/RestSaveBtns'
import SocialApi from '../../../Api/SettingSocial'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Loading from '../../customTable/LoadingForm'
import Label from '../../customTable/Label'

export default function Social() {
  const [isLoadingSocial, setIsLoadingSocial] = useState(false)
  const getSocial = () => {
    SocialApi.get((res: any) => {
      setSocial(res.data)
      setOld(res.data)
    }, setIsLoadingSocial)
  }
  useEffect(() => {
    getSocial()
  }, [])
  const {t} = useTranslation()

  const inputs = [
    {
      label: t('twitter'),
      name: 'twitter',
    },
    {
      label: t('facebook'),
      name: 'facebook',
    },
    {
      label: t('linkedin'),
      name: 'linkedin',
    },
    {
      label: t('instagram'),
      name: 'instagram',
    },
    {
      label: t('whatsapp'),
      name: 'whatsapp',
    },
  ]
  const appLink = [
    {
      label: t('android'),
      name: 'android',
    },
    {
      label: t('IOS'),
      name: 'ios',
    },
  ]
  const [social, setSocial] = useState({})
  const [old, setOld] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const handelSubmit = () => {
    SocialApi.put(social, () => {}, setIsLoading)
  }
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack minHeight={'60vh'} position={'relative'}>
      {isLoadingSocial ? (
        <Loading />
      ) : (
        <Stack>
          <Stack
            p='24px'
            borderRadius={'8px'}
            bgcolor={'var(--bs-app-header-base-bg-color)'}
            width={'100%'}
          >
            <Stack gap='24px'>
              {/* <p style={{width: '600px'}}></p> */}
              {inputs.map((e, i) => (
                <Stack maxWidth={'600px'} key={i} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={'CODE'}>
                    <LabelInput text={e.label} />
                  </label>
                  <Box sx={{width: '100%'}}>
                    <InputType change={setSocial} name={e.name} value={social} type='text' />
                  </Box>
                </Stack>
              ))}
            </Stack>
            <Stack borderTop='1px dashed #B5B5C3' pt='24px' mt='24px' gap='24px'>
              {/* <p style={{width: '600px'}}></p> */}
              <Label text={'applicationLinks'} />
              {appLink.map((e, i) => (
                <Stack maxWidth={'600px'} key={i} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={'CODE'}>
                    <LabelInput text={e.label} />
                  </label>
                  <Box sx={{width: '100%'}}>
                    <InputType change={setSocial} name={e.name} value={social} type='text' />
                  </Box>
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Stack>
            {store.permissions && store.permissions.includes('social_update') && (
              <RestSaveButton
                isLoading={isLoading}
                SaveText='Save'
                cancelText='Rest'
                submit={handelSubmit}
                cancel={() => setSocial(old)}
              />
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
