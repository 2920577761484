import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const Error500: FC = () => {
  return (
    <>
      <img
        style={{width: '500px', height: '600px', margin: 'auto'}}
        src={'./media/avatars/500.svg'}
      />
    </>
  )
}

export {Error500}
