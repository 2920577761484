import * as yup from 'yup'

export const VarationShcema = yup.object().shape({
  height: yup.number().when('is_shipping_pickup', {
    is: 1,
    then: yup.number().required('heightErr'),
    otherwise: yup.number(),
  }),
  image: yup.string().required('ImageErr'),
  is_shipping_pickup: yup.number().oneOf([0, 1]).required('shippingPickupErr'),
  is_unlimited: yup.number().oneOf([0, 1]).required('unlimitedErr'),
  length: yup.number().when('is_shipping_pickup', {
    is: 1,
    then: yup.number().required('lengthErr'),
    otherwise: yup.number(),
  }),

  price: yup.number().required('priceErr'),
  product_id: yup.string().required('productErr'),
  // quantity: yup.number().required('Quantity is required'),
  weight: yup.number().when('is_shipping_pickup', {
    is: 1,
    then: yup.number().required('weightErr'),
    otherwise: yup.number(),
  }),
  quantity: yup.number().when('is_unlimited', {
    is: 0,
    then: yup
      .number()
      .required('quantityErr')
      .test(
        'is-greater-than-zero',
        'Quantity must be greater than zero',
        (value: any) => value > 0
      ),
    otherwise: yup.number(),
  }),
  width: yup.number().when('is_shipping_pickup', {
    is: 1,
    then: yup.number().required('widthErr'),
    otherwise: yup.number(),
  }),
})
