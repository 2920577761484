import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../../customTable/Label'
import BackagesTabs from './BackagesTabs'
import Package from './Packege'

export default function Backages({subTitle, title, width, setPackageById, setIsLoadingTable}: any) {
  const [plan, setPlan] = useState('monthly')
  const [packages, setPackages] = useState([
    {
      type: 'free',
      amount: '0',
      advantages: [
        'personal Access',
        'Self-learning System',
        'Unlimited Overview',
        'Up to 10 areas of interest',
      ],
      color: '#0BB783',
    },
    {
      type: 'Standard',
      amount: '50',
      advantages: [
        'personal Access',
        'Self-learning System',
        'Unlimited Overview',
        'Up to 10 areas of interest',
      ],
      color: '#009EF7',
      comingSoon: true,
    },
    ,
    {
      type: 'Premium',
      amount: '150',
      advantages: [
        'personal Access',
        'Self-learning System',
        'Unlimited Overview',
        'Up to 10 areas of interest',
      ],
      color: '#FFA800',
      comingSoon: true,
    },
    ,
    {
      type: 'Ultimate',
      amount: '250',
      advantages: [
        'personal Access',
        'Self-learning System',
        'Unlimited Overview',
        'Up to 10 areas of interest',
      ],
      color: '#D71B22',
      comingSoon: true,
    },
  ])
  return (
    <Stack alignItems={'center'} bgcolor={'#fff'} p='48px 16px' borderRadius={'8px'}>
      <Stack gap='10px'>
        <Label
          align='center'
          color='#65656A'
          fs={{xs: '20px', md: '32px'}}
          fw='600'
          text={title || 'titleBackages'}
        />
        <Label
          align='center'
          color='#5E6278'
          fs='14px'
          fw='600'
          text={subTitle || 'subscriptionPlan'}
        />
      </Stack>
      <BackagesTabs
        setIsLoadingTable={setIsLoadingTable}
        setPackageById={setPackageById}
        plan={plan}
        setPlan={setPlan}
      />
      {/* <Stack justifyContent={'center'} flexWrap={'wrap'} mt='41px' width='100%' direction={'row'}>
        {packages.map((e: any) => (
          <Package width={width} item={e} />
        ))}
      </Stack> */}
    </Stack>
  )
}
