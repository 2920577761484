import ApisMethods from './apisMethods'

const TaxApi = {
  get: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/tax`, callback, setIsLoading)
  },

  put: async (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/tax/update`, data, callback, setIsLoading)
  },
  store: async (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/tax`, data, callback, setIsLoading)
  },
}
export default TaxApi
