import {Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import TitlePage from '../../customTable/TitlePage'
import DomainVsg from '../../../svg/Domain'
import Label from '../../customTable/Label'
import MainBtn from '../../customTable/MainBtn'
import PopUp from '../../customTable/PopUp'
import Form from './Form'
import DomainApi from '../../../Api/domin'
import Table from './Table'
import Loading from '../../customTable/LoadingForm'

export default function Domain() {
  const {t} = useTranslation()
  const [dns, setDns] = useState(null)
  const [data, setData] = useState<any>({})
  const [open, setOpen] = useState(false)
  const [isDns, setIsDns] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const bannerInfo = {
    title: t('customDomain'),
    home: t('home'),
    linkHome: '/',
  }
  const getDomain = () => {
    DomainApi.get(
      (res: any) => {
        setIsDns(true)
        setData(res.data)
      },
      setIsLoading,
      true
    )
  }
  useEffect(() => {
    getDomain()
  }, [isDns])
  return (
    <Stack width={'100%'} height={'70vh'} position={'relative'}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack height={'100%'}>
          <TitlePage info={bannerInfo} />

          <Stack
            mt='24px'
            p='24px'
            justifyContent={'center'}
            minHeight={'60vh'}
            height={'100%'}
            className='card'
          >
            {!isDns && (
              <Stack gap='32px' justifyContent={'center'} alignItems={'center'} mt='54px'>
                <DomainVsg />
                <Label text='connectDomain' />
                <MainBtn handelSubmit={() => setOpen(true)} width='auto' text='connect' />
                <PopUp open={open} setOpen={setOpen} width={'sm'}>
                  <Form setIsDns={setIsDns} setDns={setDns} dns={dns} setOpen={setOpen} />
                </PopUp>
              </Stack>
            )}
            {isDns && (
              <Stack gap='24px'>
                <Label color='#787887' fs='14px' text='subDomainExample' />
                <Label color='#009EF7' fs='20px' fw='600' text={data.custom_domain} />
                <Stack gap='5px' alignItems={'center'} direction={'row'}>
                  <Label fs='16px' color='#181C32' text='verification' />:
                  <Stack
                    borderRadius={'8px'}
                    p='4px 8px'
                    bgcolor={data.is_verification === 1 ? '#0bff0b38' : '#ff000026'}
                  >
                    <Label
                      fs='16px'
                      color={data.is_verification === 1 ? 'green' : 'red'}
                      text={data.is_verification === 1 ? 'success' : 'failed'}
                    />
                  </Stack>
                </Stack>
                <Label color='#787887' fs='14px' text='waitAftterTime' />
                <MainBtn
                  isLoading={isLoadingDelete}
                  bgcolor='#40518914'
                  color='#405189'
                  br='8px'
                  handelSubmit={() => {
                    DomainApi.delete((res: any) => {
                      setIsDns(false)
                    }, setIsLoadingDelete)
                  }}
                  width='max-content'
                  text='disconnectDomain'
                />
                <Stack height={'1px'} width={'100%'} bgcolor={'#000'}></Stack>{' '}
                <Table data={data.dns_record} />
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
