import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../../modules/customTable/Label'
import RangeChart from './RangeChart'
import CustomerStatus from './CustomerStatus'

export default function Customers({data}: any) {
  return (
    <Stack
      height={'203px'}
      minWidth={{xlg: '350px', lg: 'auto'}}
      width={'100%'}
      gap='16px'
      borderRadius={'18px'}
      bgcolor={'#fff'}
      p='24px'
    >
      <Label color='#3F4254' text='customers' fs='18px' fw='500' />
      <CustomerStatus color={'#50CD89'} name='activeUsers' value={data.active} />
      <CustomerStatus color={'#FFAD0F'} name='bannedUsers' value={data.banned} />
      <CustomerStatus color={'#EF305E'} name='inactiveUsers' value={data.in_active} />
    </Stack>
  )
}
