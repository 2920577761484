import {Stack} from '@mui/material'
import React from 'react'
import Label from './Label'
import Location from '../../svg/Location'

export default function GoogleMapBtn({latitude, longitude}: any) {
  return (
    <a
      href={`https://www.google.com/maps/place/${latitude},${longitude}`}
      target='_blank'
      rel='noopener noreferrer'
    >
      <Stack p='8px 12px' gap='6px' alignItems={'center'} direction={'row'}>
        <Label color='#009EF7' fs='14px' fw='500' text='Get directions' />
        <Location />{' '}
      </Stack>
    </a>
  )
}
