import {Button, Stack, Tooltip} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import Label from './Label'
import StaticInputFile from './StaticInputFile'
import ErrorMsg from './NewMsgErr'
import PopUp from './PopUp'
import {useNavigate} from 'react-router-dom'

export default function UploadImgBtn({
  base64Image,
  setBase64Image,
  name,
  data,
  setData,
  width,
  height,
  br,
  disabled,
  border,
  isPdf,
  convertToBusiness,
  note,
  text,
  p,
  gap,
  errors,
  FormData,
}: any) {
  const [image, setImage] = useState(base64Image || '')
  const {t} = useTranslation()
  const navigate = useNavigate()
  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    if ((isPdf && file) || (file && file.type.includes('image/'))) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        setBase64Image(file)
        if (name && data && setData) {
          setData({...data, [name]: FormData ? file : reader.result})
        }
        setImage(reader.result as string)
      }
      reader.onerror = (error) => {}
    } else {
      setBase64Image(null)
      alert(note || t('Only jpeg , jpg , png and svg image files are accepted'))
    }
  }

  useEffect(() => {
    if (data && data[name] && data[name].type && data[name].type.includes('image/')) {
      const reader = new FileReader()
      reader.readAsDataURL(data[name])
      reader.onload = () => {
        setBase64Image(data[name])

        setImage(reader.result as string)
      }
      reader.onerror = (error) => {}
    }
  }, [])
  const [open, setOpen] = useState(false)
  return (
    <>
      <Tooltip title={note || 'Only jpeg , jpg , png and svg image files are accepted'}>
        <Stack width={'100%'} gap={gap || '8px'}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Label text={text} />
          </Stack>
          <Stack
            sx={{
              width: '100%',
              height: !data[name] && convertToBusiness ? '48px' : height || '85px',
              borderRadius: br || '100px',
              '& .css-1g5ew2a-MuiButtonBase-root-MuiButton-root': {
                p: '0',
              },
            }}
          >
            {' '}
            <Stack
              alignItems={'flex-end'}
              p={p || '8px'}
              borderRadius={'8px'}
              border={!image && !data[name] ? '1px solid #dfdfdf' : 'none'}
            >
              <Button
                component={'label'}
                sx={{
                  bgcolor: !image && !data[name] ? '#bdfaff' : '',
                  width: !image && !data[name] ? 'auto' : '100%',
                }}
              >
                {!image && !data[name] ? (
                  <>
                    <Label color='var(--main-color)' text='upload' />
                  </>
                ) : (
                  <Stack
                    width='100%'
                    onClick={() => {
                      if (disabled) {
                        if (data[name].includes('pdf')) {
                          window.open(data[name], '_blank')
                        } else {
                          setOpen(true)
                        }
                        console.log(11, data[name], image, 'clicked')
                      }
                    }}
                  >
                    <StaticInputFile text={text} width='100%' />
                  </Stack>
                )}
                <input
                  hidden
                  // accept='.jpg, .jpeg, .png, .svg'
                  onChange={handleImageChange}
                  multiple
                  type='file'
                  disabled={disabled}
                />
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Tooltip>
      <PopUp setOpen={setOpen} open={open}>
        <Stack bgcolor={'#fff'}>
          <img
            src={data[name]}
            alt='uploaded'
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              borderRadius: '8px',
            }}
          />
        </Stack>
      </PopUp>
      {errors && errors[name] && errors && typeof errors[name] === 'string' && (
        <ErrorMsg msgErr={errors[name]} />
      )}
    </>
  )
}
