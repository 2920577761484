import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../../modules/customTable/Label'
import RangeChart from './RangeChart'

export default function TopPayment({data}: any) {
  return (
    <Stack
      height={'203px'}
      minWidth={{xlg: '350px', lg: 'auto'}}
      width={'100%'}
      gap='16px'
      borderRadius={'18px'}
      bgcolor={'#fff'}
      p='24px'
    >
      <Label color='#3F4254' text='topPayment' fs='18px' fw='500' />
      <RangeChart
        color={'#0688E7'}
        name='koninPay'
        num={+data.cash.percentage}
        value={data.cash.count}
      />
      <RangeChart
        color={'#01C0F6'}
        name='cashOnDelivery'
        num={+data.online.percentage}
        value={data.online.count}
      />
    </Stack>
  )
}
