import {Stack} from '@mui/material'
import React from 'react'
import Label from '../customTable/Label'

export default function LabelWithValue({label, value, gap}: any) {
  return (
    <Stack gap={gap || '30px'} direction={'row'}>
      <Stack minWidth={'44.7px'}>
        <Label fs='14px' fw='600' color='#858585' text={label} />
      </Stack>
      <Label fs='14px' fw='600' color='#858585' text={value} />
    </Stack>
  )
}
