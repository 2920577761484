import React, {useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Stack, Typography} from '@mui/material'
import TitlePage from '../../customTable/TitlePage'
import {KTSVG} from '../../../../_metronic/helpers'
import Table from './Table'
import {useTranslation} from 'react-i18next'
import Label from '../../customTable/Label'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
export default function BackageTable({packageById}: any) {
  const {t} = useTranslation()

  const [rows, setRows] = useState<any>([])

  return (
    <Stack>
      {/* <Stack direction={'row'} p='0 27px'>
        <Stack>
          <Stack  borderBottom='1px dashed #c7c7c7' p='12px'>
            <Label color='var(--bs-text-dark)' fs='12px' fw='600' text='price' />
          </Stack>
          <Stack maxWidth={'200px'}>
            <Stack borderBottom='1px dashed #c7c7c7' p='12px'>
              <Label color='var(--bs-text-dark)' fs='12px' fw='600' text='price' />
            </Stack>
            <Stack borderBottom='1px dashed #c7c7c7' p='12px'>
              <Label color='var(--bs-text-dark)' fs='12px' fw='600' text='productsNum' />
            </Stack>
            <Stack borderBottom='1px dashed #c7c7c7' p='12px'>
              <Label color='var(--bs-text-dark)' fs='12px' fw='600' text='numberOfCategories' />
            </Stack>
          </Stack>

          {packageById &&
            packageById[0] &&
            packageById[0].features.map((e: any) => (
              <Stack maxWidth={'200px'} borderBottom='1px dashed #c7c7c7' p='12px'>
                {' '}
                <Label
                  color='var(--bs-text-dark)'
                  fs='12px'
                  fw='600'
                  text={e.feature_name && (e.feature_name[lang] || e.feature_name[defLang])}
                />
              </Stack>
            ))}
        </Stack>
        {packageById.map((e: any) => (
          <Stack>
            {e.features.map((item: any) => (
              <Stack maxWidth={'250px'} borderBottom='1px dashed #c7c7c7' p='12px'>
                {' '}
                {item.hasFeature ? 'true' : 'f'}
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack> */}
      <Table packageById={packageById} rows={rows} setRows={setRows} />
    </Stack>
  )
}
// var(--bs-body-bg)
