import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Listroles} from '../roules/components/listRoules'
import {Projects} from '../roules/components/Projects'
import {useTranslation} from 'react-i18next'
import RolesDetails from '../roules/details'
import SubVendorList from '../roules/subVendorList'
import {SubVendorProfile} from '../roules/subVendorProfile/SubVendorProfile'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import Error403 from '../errors/components/Error403'
import WarperBackages from '../customTable/WarperBackages'
import RefundRequests from '../refundRequests'
import RefundListing from '../refundListing'
import ViewRefundRequests from '../refundRequests/View/View'
import Orders from '.'
import ViewRefundListing from '../refundListing/View/View'
import View from './View/View'

const OrdersPage = () => {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const rolesBreadCrumbs: Array<PageLink> = [
    {
      title: t('rolesList'),
      path: '/role/roles-list',
      isSeparator: false,
      isActive: true,
    },
    {
      title: t('viewRole'),
      path: '/role/details/:id',
      isSeparator: false,
      isActive: false,
    },
  ]
  return (
    <Routes>
      <Route
        element={
          <>
            {/* <ProfileHeader /> */}
            <Outlet />
          </>
        }
      >
        <Route
          path='orders-list'
          element={
            store.permissions && store.permissions.includes('orders_read') ? (
              <>
                <Orders />{' '}
              </>
            ) : (
              <Error403 />
            )
          }
        />
        <Route
          path='refund-requests'
          element={
            store.permissions && store.permissions.includes('orders_read') ? (
              <>
                <RefundRequests />{' '}
              </>
            ) : (
              <Error403 />
            )
          }
        />
        <Route
          path='refund-listing'
          element={
            store.permissions && store.permissions.includes('refund_orders') ? (
              <>
                <RefundListing />{' '}
              </>
            ) : (
              <Error403 />
            )
          }
        />
        {/* <Route
          path='refund-listing/:id'
          element={
            store.permissions && store.permissions.includes('orders_read') ? (
              <>
                <ViewRefundListing />{' '}
              </>
            ) : (
              <Error403 />
            )
          }
        /> */}
        <Route
          path='orders/:id'
          element={
            store.permissions && store.permissions.includes('orders_read') ? (
              <>
                <ViewRefundListing />{' '}
              </>
            ) : (
              <Error403 />
            )
          }
        />
        <Route
          path='refund-requests/:id'
          element={
            store.permissions && store.permissions.includes('orders_read') ? (
              <>
                <ViewRefundRequests />{' '}
              </>
            ) : (
              <Error403 />
            )
          }
        />
        <Route index element={<Navigate to='/crafted/pages/profile/overview' />} />
      </Route>
    </Routes>
  )
}

export default OrdersPage
