/* eslint-disable @typescript-eslint/no-unused-expressions */

import {useFormik} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import 'react-quill/dist/quill.snow.css'
import {useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import {Boundary} from '../../../componet/Bondary'
import {RootState, useDispatch, withToastr} from '../../../store'
import {FetchStatus} from '../../../store/types'
import {
  createSubCategoryAction,
  getCategoryName,
  updateSubCategoryAction,
} from '../../../store/vender/catogory/thunk'
import {
  createProductAction,
  getProductById,
  getProductName,
  updateProductAction,
} from '../../../store/vender/products/thunk'
import {Language, languages} from '../../../types'
import {IamgeSection} from '../../options/addCtogory/iamgeSection'
import {ProductDetails} from './ProductDetails'
import TabsComponent from './tabs'
import {InitialValues, validationSchema} from './validationSchema'
import ErrorMsg from '../../customTable/ErrorMsg'
import {Stack} from '@mui/material'
import {actions} from '../../../store/vender/Auth/auth.slice'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import ViewBtn from './ViewBtn'
import MainBtn from '../../customTable/MainBtn'
import OldInput from '../../customTable/OldInput'
import ValidationFun from '../../../yup/ValidationFun'
import {productSchema} from '../../../yup/ProductsShcema'
import Product from '../../../Api/product'

export const AddProduct = () => {
  const dispatch = useDispatch()
  const {id} = useParams<{
    id?: string
  }>()
  const {nameProduct, product} = useSelector((state: RootState) => state.product)
  const {nameCategory, status, error} = useSelector((state: RootState) => state.category)
  const location = useLocation()
  const navigate = useNavigate()
  const lang = useLang()
  const isEditProduct = location.pathname.includes('edit-product')
  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab')
  const [activeLang, setActiveLang] = useState<Language>('en')
  const {t} = useTranslation()
  console.log(tab)

  const fetchCategoryNames = useCallback(async () => {
    dispatch(getCategoryName({}))
  }, [dispatch])
  const productListFormat = useMemo(() => {
    const list = nameProduct?.map((item) => {
      return {
        value: item.id,
        label: item.name[activeLang],
      }
    })
    return list
  }, [nameProduct, activeLang])

  const reformated = useMemo(() => {
    const result = nameCategory?.map((item) => ({
      value: item?.id?.toString(),
      label: item?.name[activeLang],
    })) as Array<{value: string; label: string}>
    return result || []
  }, [nameCategory, activeLang])
  const store = useSelector((state: RootState) => state.vender)
  const initialValues: InitialValues = useMemo(() => {
    let obj = {} as InitialValues
    if (!id && store.cashData.dataCashType === 'product') {
      obj = store.cashData
    } else if (isEditProduct && product && id) {
      ;(obj.status = product.status || 0), (obj.sku = product.sku || '')
      obj.unlimited_quantity = product.unlimited_quantity || 0
      obj.quantity = product.quantity || 0
      obj.is_shipping_pickup = product.is_shipping_pickup || 0
      obj.weight = product.weight || ''
      obj.height = product.height || ''
      obj.length = product.length || ''
      obj.width = product.width || ''
      obj.cost = product.cost || ''
      obj.price = product.price || null
      obj.discount_amount = product.discount_amount || ''
      obj.in_stock = product.in_stock || true
      obj.manufacturer_id = product.manufacturer_id || null
      obj.value = product.value || 0
      obj.model = product.model || ''
      obj.image = product.image || ''
      obj.parent_id = product.parent_id || ''
      obj.sort = product.sort || ''
      obj.name = product.name || ({} as {[key in Language]: string})
      obj.description = product.description || ({} as {[key in Language]: string})
      obj.permalink = product.permalink || ({} as {[key in Language]: string})
      obj.meta_title = product.meta_title || ({} as {[key in Language]: string})
      obj.meta_description = product.meta_description || ({} as {[key in Language]: string})
      obj.meta_keywords = product.meta_keywords || ({} as {[key in Language]: any})

      obj.product_tags = product.product_tags || ({} as {[key in Language]: any})
      ;(obj.is_featured = product.is_featured || 0),
        (obj.related_products =
          product.related_products?.map((item: any) => ({
            value: item.related_product_id,
            label:
              productListFormat.find((x) => parseInt(x.value) === item.related_product_id)?.label ||
              '',
          })) || [])
      obj.categories =
        product.categories?.map((item: any) => ({
          value: item.id,
          label: reformated.find((x) => parseInt(x.value) === item.id)?.label || '',
        })) || []

      obj.is_new_arrival = product.is_new_arrival || 0
    } else {
      ;(obj.status = 1), (obj.sku = '')
      obj.unlimited_quantity = 0
      // obj.quantity = 0
      obj.is_shipping_pickup = 0
      // obj.weight = 0
      // obj.height = 0
      // obj.length = 0
      // obj.width = 0
      // obj.cost = 0
      // obj.price = 0
      // obj.discount_amount = 0
      obj.in_stock = 0
      obj.manufacturer_id = ''
      obj.value = ''
      obj.model = ''
      obj.image = ''
      obj.is_featured = 0
      obj.related_products = []
      obj.categories = []
      obj.name = {} as {[key in Language]: string}
      obj.description = {} as {[key in Language]: string}
      obj.permalink = {} as {[key in Language]: string}
      obj.meta_title = {} as {[key in Language]: string}
      obj.meta_description = {} as {[key in Language]: string}
      obj.meta_keywords = {} as {[key in Language]: any}
      obj.product_tags = {} as {[key in Language]: any}

      obj.is_new_arrival = 0
      obj.parent_id = {
        value: '',
        label: '',
      }
      obj.sort = 0

      for (const lang of store.storeLangs) {
        obj.name[lang] = ''
        obj.description[lang] = ''
        obj.permalink[lang] = ''
        obj.meta_title[lang] = ''
        obj.meta_description[lang] = ''
        obj.meta_keywords[lang] = []
        obj.product_tags[lang] = []
      }
    }

    return obj
  }, [isEditProduct, product, id, productListFormat, reformated])
  const [activeKey, setActiveKey] = useState('General')
  useEffect(() => {
    console.log(tab, 'tab')

    if (tab) {
      setActiveKey(tab)
    }
  }, [])
  const [languages, setlanguages] = useState(['en'])
  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  const formik = useFormik<InitialValues>({
    initialValues: initialValues as InitialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: (values, {setSubmitting, resetForm}) => {
      //   we  need  to  preventDefault behaviral

      if (id && product.id && isEditProduct) {
        setSubmitting(true)
        dispatch(
          withToastr(
            updateSubCategoryAction({
              id,
              ...values,
              parent_id: values.parent_id.value ? parseInt(values.parent_id.value) : null,
            }),
            {
              success: {
                title: `success to update data to  updated `,
              },
              error: {
                title: `error to update data to  updated`,
              },
            }
          )
        ).then((res) => {
          resetForm()
          setSubmitting(false)
          //  redirect  to  list  page
          navigate('/catalog/sub-category')
          return
        })
      } else {
        setSubmitting(true)
        if (
          values.in_stock === 1 &&
          values.unlimited_quantity === 0 &&
          (!values.quantity || values.quantity < 1)
        ) {
          alert('error')
        } else {
          const data = {
            ...values,
            quantity: values.quantity ? values.quantity : 0,
          }
          dispatch(
            withToastr(
              createProductAction({
                ...values,
                quantity: values.quantity ? values.quantity : 0,
              }),
              {
                success: {
                  title: `success to update data to  updated `,
                },
                error: {
                  title: `error to update data to  updated`,
                },
              }
            )
          )
            .then((res) => {
              dispatch(actions.setCashData({}))
              resetForm()
              setSubmitting(false)
              //  redirect  to  list  page

              return
            })
            .catch((err) => {
              setSubmitting(false)
            })
        }
      }
    },
  })

  const fetchListProduct = useCallback(() => {
    if (id && isEditProduct) {
      //   we  need  to  featch  catofory details
      dispatch(
        withToastr(getProductById(id), {
          success: {
            title: 'Success to get  Catogry details ',
          },
        })
      )
    }
    // dispatch(
    //   withToastr(getProductName({}), {
    //     success: {
    //       title: `success to update data to  updated `,
    //     },
    //     error: {
    //       title: `error to update data to  updated`,
    //     },
    //   })
    // ) XxX
  }, [dispatch, id, isEditProduct])

  //   useEffect
  useEffect(() => {
    fetchListProduct()
    fetchCategoryNames()
  }, [fetchListProduct, id, fetchCategoryNames])

  const nameCategoryListFormat = useMemo(() => {
    const list = nameCategory?.map((item) => {
      return {
        value: item.id,
        label: item.name && (item.name[store.lang] || item.name[store.defLang]),
      }
    })
    return list
  }, [nameCategory, activeLang])
  // handleSave
  const [loading, setLoading] = useState(false)
  const handleSave = useCallback(() => {
    if (!id) return

    const inputData = {
      ...formik.values,
      cost: formik.values.cost ? formik.values.cost : 0,
      weight: formik.values.weight ? formik.values.weight : 0,
      height: formik.values.height ? formik.values.height : 0,
      width: formik.values.width ? formik.values.width : 0,
      length: formik.values.length ? formik.values.length : 0,
    } as any
    ValidationFun(inputData, productSchema(languages), setErrors, () => {
      const isImageBase64 = inputData.image?.includes('data:image')
      if (!isImageBase64 && inputData && inputData.image) {
        delete inputData.image
      }
      inputData.related_products = inputData?.related_products?.map((x: any) => x.value)
      inputData.categories = inputData?.categories?.map((x: any) => x.value)
      Product.put(parseInt(id), inputData, () => {}, setLoading)
      // setLoading(true)
      // dispatch(
      //   withToastr(
      //     updateProductAction({
      //       id: parseInt(id),
      //       inputData,
      //     }),
      //     {
      //       success: {
      //         title: `success to update data to  updated `,
      //       },
      //       error: {
      //         title: `error to update data to  updated`,
      //       },
      //     }
      //   )
      // )
      //   .then((res) => {
      //     setLoading(false)
      //     toast.success('Modified successfully')

      //     return
      //   })
      //   .catch((err) => {
      //     // setMsgErr(error.response.data.message)

      //     setLoading(false)
      //   })
    })
  }, [dispatch, formik.values, id])

  const [errorMsg, setErrorMsg] = useState('')
  const [errors, setErrors] = useState({})
  return (
    <div
      style={{marginTop: '31px', position: 'relative'}}
      className='content d-flex flex-column flex-column-fluid'
    >
      {/* //  need  to  ad  button to  end  of  the   screen */}

      <Stack
        direction={'row'}
        gap='15px'
        style={{
          position: 'absolute',
          top: '-71px',
          right: store.isRtl === 0 ? 0 : 'auto',
          left: store.isRtl === 1 ? 0 : 'auto',
        }}
        className='d-flex flex-column-fluid d-flex justify-content-end '
      >
        {id && <MainBtn isLoading={loading} handelSubmit={handleSave} text='save' />}
        {/* {<ViewBtn value={formik.values} />} */}
      </Stack>
      <Boundary
        isLoading={(id && isEditProduct && !(product as any).id) || status === FetchStatus.pending}
        error={error}
      >
        <div className='post d-flex flex-column-fluid'>
          <div
            style={{
              paddingRight: '0',
              paddingLeft: '0',
              margin: ' 0',
              maxWidth: '10000000000000px',
            }}
            className='container-xxl'
          >
            <div
              //  need  to  prevent default
              style={{gap: '31px'}}
              // onSubmit={(e) => {
              //   e.preventDefault()
              //   formik.handleSubmit()
              // }}
              className='form d-flex flex-column flex-lg-row'
              // noValidate
            >
              <Stack>
                <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7  pt-1 '>
                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>{t('thumbnail')}</h2>
                      </div>
                    </div>
                    <div className='card-body text-center pb-0 pt-0'>
                      <IamgeSection<InitialValues>
                        formik={formik}
                        errors={errors}
                        className='text-center'
                        image={formik.values.image}
                        error={formik.errors.image}
                        touched={formik.touched.image}
                        name='image'
                        hight='product_height'
                        width='product_width'
                      />
                      <ErrorMsg msgErr={errorMsg} name='Image' />
                    </div>
                  </div>

                  <ProductDetails
                    formik={formik}
                    lable={t('productDetails') || 'product Details'}
                    subLable={t('categories') || 'Categories'}
                    options={nameCategoryListFormat}
                  />

                  <div className='card card-flush py-4'>
                    <div className='card-header'>
                      <div className='card-title'>
                        <h2>{t('productDetails')}</h2>
                      </div>
                    </div>
                    <div style={{padding: '0 2.25rem'}} className='card-body  pt-0'>
                      <div className='mb-10'>
                        {/* //<!--begin::Label--> */}
                        <label className='required form-label'>{t('model')}</label>
                        <OldInput errors={errors} name={'model'} formik={formik} />
                        {/* <input
                          type='text'
                          {...formik.getFieldProps(`model`)}
                          className='form-control mb-2'
                          // placeholder={t('model')}
                        />
                        <ErrorMsg msgErr={errorMsg} name='Model' /> */}

                        {/* {formik.touched.model && formik.errors.model && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.model}</div>
                          </div>
                        )} */}
                      </div>
                      {/*  is_new_arrival is  toogle */}
                      <div className='mb-10 my-2'>
                        {/* //<!--begin::Label--> */}
                        {/*  need  them  in same  line */}
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                          <label className=' form-label'>{t('isNewArrival')}</label>
                          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              checked={!!formik.values.is_new_arrival}
                              type='checkbox'
                              {...formik.getFieldProps(`is_new_arrival`)}
                              onChange={(e) => {
                                formik.setFieldValue(`is_new_arrival`, e.target.checked ? 1 : 0)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* //<!--end::Thumbnail settings--> */}
                  {/* //<!--begin::Status--> */}
                  <div className='card card-flush py-4'>
                    {/* //<!--begin::Card header--> */}
                    <div className='card-header'>
                      {/* //<!--begin::Card title--> */}
                      <div className='card-title'>
                        <h2>{t('status')}</h2>
                      </div>
                      {/* //<!--end::Card title--> */}
                      {/* //<!--begin::Card toolbar--> */}
                      <div className='card-toolbar'>
                        <Stack
                          bgcolor={
                            !formik.values.status || formik.values.status == 0 ? '#a2aca2' : 'green'
                          }
                          className='rounded-circle  w-15px h-15px'
                        ></Stack>
                      </div>
                      {/* //<!--begin::Card toolbar--> */}
                    </div>
                    {/* //<!--end::Card header--> */}
                    {/* //<!--begin::Card body--> */}
                    <div className='card-body pt-0'>
                      {/* //<!--begin::Select2--> */}
                      <select
                        {...formik.getFieldProps('status')}
                        className='form-select mb-2'
                        data-control='select2'
                        data-hide-search='true'
                        data-placeholder='Select an option'
                      >
                        <option></option>
                        <option value='1'>{t('active')}</option>
                        <option value='0'>{t('inactive')}</option>
                      </select>
                      <div className='text-muted fs-7'>{t('setCategory')}</div>
                      {formik.touched.status && formik.errors.status && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.status}</div>
                        </div>
                      )}
                    </div>
                    {/* //<!--end::Card body--> */}
                  </div>
                </div>
              </Stack>

              <div
                style={{paddingLeft: '0', paddingRight: '0'}}
                className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'
              >
                <TabsComponent
                  errors={errors}
                  setErrors={setErrors}
                  activeKey={activeKey}
                  setActiveKey={setActiveKey}
                  setErrorMsg={setErrorMsg}
                  formik={formik}
                />
              </div>
            </div>
          </div>
        </div>
      </Boundary>
    </div>
  )
}
