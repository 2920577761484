import React, {useState, useCallback, useEffect} from 'react'
import TableHead from './TablHead'
import {Typography} from '@mui/material'
import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import Row from './Row'
import OrderInfo from './OrderInfo'
import {Stack} from '@mui/system'

export default function Table({order}: any) {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)
  return (
    <div style={{marginTop: '30px'}} className={`card fds sha`}>
      <div className='card-body py-3 pb-5'>
        <Typography sx={{fontSize: '19px', fontWeight: '600', marginTop: '10px'}}>
          {t('order')} {order.order_number}
        </Typography>

        <div className='table-responsive overflow-auto  '>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <TableHead />
            {order.order_items &&
              order.order_items.map((e: any, i: any) => <Row order={order} key={i} item={e} />)}
          </table>
          <Stack alignItems={'flex-end'} width='100%'>
            <OrderInfo order={order} />
          </Stack>
        </div>
      </div>
    </div>
  )
}
