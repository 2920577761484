import React from 'react'

export default function ArrowSelect() {
  return (
    <svg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.14043 3.68649L1.74069 0.286751C1.40414 -0.0497866 0.858486 -0.0497866 0.52194 0.286751C0.185395 0.623288 0.185395 1.16896 0.52194 1.5055L5.02555 6.00909C5.34283 6.3264 5.8573 6.3264 6.17458 6.00909L10.6782 1.5055C11.0147 1.16896 11.0147 0.623288 10.6782 0.28675C10.3417 -0.049787 9.79598 -0.049787 9.45944 0.28675L6.0597 3.68649C5.80587 3.94032 5.39426 3.94032 5.14043 3.68649Z'
        fill='white'
      />
    </svg>
  )
}
