import React from 'react'

export default function HasNotFeature() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_8342_105432)'>
        <path
          d='M16.9688 0H7.03125C3.148 0 0 3.148 0 7.03125V16.9688C0 20.852 3.148 24 7.03125 24H16.9688C20.852 24 24 20.852 24 16.9688V7.03125C24 3.148 20.852 0 16.9688 0Z'
          fill='#F44336'
        />
      </g>
      <path
        d='M16.22 14.8697C16.5933 15.2432 16.5933 15.8477 16.22 16.2202C16.1314 16.309 16.0262 16.3794 15.9103 16.4274C15.7944 16.4755 15.6701 16.5001 15.5447 16.5C15.3003 16.5 15.0558 16.4064 14.8696 16.2202L12 13.3504L9.13046 16.2202C9.04189 16.309 8.93665 16.3795 8.82079 16.4275C8.70492 16.4755 8.58071 16.5001 8.4553 16.5C8.32986 16.5001 8.20564 16.4755 8.08976 16.4275C7.97388 16.3795 7.86862 16.309 7.78004 16.2202C7.40671 15.8478 7.40671 15.2432 7.78004 14.8697L10.6498 12.0001L7.78 9.1305C7.40667 8.75698 7.40667 8.15249 7.78 7.78005C8.1535 7.40671 8.75696 7.40671 9.13046 7.78005L12 10.6498L14.8696 7.78C15.2431 7.40667 15.8465 7.40667 16.22 7.78C16.5933 8.15244 16.5933 8.75698 16.22 9.1305L13.3503 12.0001L16.22 14.8697Z'
        fill='#FAFAFA'
      />
      <defs>
        <clipPath id='clip0_8342_105432'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
