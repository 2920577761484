import {Checkbox, Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../../customTable/Label'
import InputWithLabel from '../../customTable/LabelWithText'
import UploadImg from '../../UploadImg'

import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import MainBtn from '../../customTable/MainBtn'
import ValidationFun from '../../../yup/ValidationFun'
import {AppMakerSchema} from '../../../yup/AppMakerShcema'
import {log} from 'console'
import AppMaker from '../../../Api/AppMaker'

export default function IHaveAccountsInStoresForm({
  active,
  android,
  data,
  setData,
  setIsSubmit,
}: any) {
  const [base64Image, setBase64Image] = useState('')
  const store = useSelector((state: RootState) => state.vender)
  const [isLoading, setUsLoading] = React.useState(false)
  const [errors, setErrors] = useState<any>({})

  return (
    <Stack gap='24px' p='25px' borderRadius={'8px'} bgcolor={'#fff'} mt='24px'>
      <Label text='haveAccountTitle' />
      <Label fs='12px' fw='400' text='haveAccountSubTitle' />
      <Stack direction={'row'} gap='5px' flexWrap={'wrap'}>
        <Label fs='12px' fw='400' text='checkLink' />
        <Label fs='12px' fw='400' color='#009EF7' text='followingLink' />
      </Stack>
      <Stack gap='40px'>
        <img src='./media/1.svg' alt='' />
        <Label fs='14px' text='itIsNotAcceptableIfTheAccountIsIndividual' />

        <img src='./media/2.svg' alt='' />
        <InputWithLabel
          width={{md: '521px', xs: '100%'}}
          name={'duns_num'}
          data={data}
          errors={errors}
          setData={setData}
          text='DUNumber'
        />
        <Stack bgcolor={'#C5C5D8'} height={'1px'} width={'100%'}></Stack>
        <Stack gap='32px'>
          <Label text={android ? 'androidApp' : 'isoApp'} />
          <Stack gap='15px'>
            <Label text='applicationIcon' />
            <UploadImg
              name='app_icon'
              data={data}
              setData={setData}
              errors={errors}
              base64Image={base64Image}
              setBase64Image={setBase64Image}
            />
          </Stack>
          <Stack flexWrap={'wrap'} gap='32px' direction={'row'}>
            <InputWithLabel
              width={{md: 'calc(50% - 16px)', xs: '100%'}}
              name={'account_mail'}
              data={data}
              setData={setData}
              errors={errors}
              text='accountMail'
            />{' '}
            <InputWithLabel
              width={{md: 'calc(50% - 16px)', xs: '100%'}}
              name={'account_password'}
              data={data}
              errors={errors}
              setData={setData}
              text='password'
            />
          </Stack>
          <Stack bgcolor={'#C5C5D8'} height={'1px'} width={'100%'}></Stack>
        </Stack>
      </Stack>
      <Stack>
        <Stack alignContent={'center'} gap='3px' direction={'row'}>
          <Checkbox
            checked={data.is_agree}
            sx={{
              color: 'var(--main-color)',
              '&.Mui-checked': {
                color: 'var(--main-color)',
              },
            }}
          />
          <Stack mt='8px' alignContent={'center'} gap='5px' direction={'row'}>
            <Label fs={{xs: '12px', sm: '14px'}} text='iAgreeTo' />
            <Label
              fs={{xs: '12px', sm: '14px'}}
              color='#009EF7'
              text='theAppStorePublishingPolicy'
            />
          </Stack>
        </Stack>
        {store.permissions && store.permissions.includes('app_maker_create') && (
          <Stack mt='41px' alignItems={'flex-end'}>
            <MainBtn
              width='auto'
              text='submit'
              handelSubmit={() => {
                ValidationFun(
                  {...data, check: data.check, active: active},
                  AppMakerSchema,
                  setErrors,
                  (value: any) => {
                    AppMaker.add(
                      {
                        ...data,
                        is_have_account: 1,
                        is_agree: data.is_agree ? 1 : 0,
                      },
                      () => {
                        setIsSubmit(true)
                      },
                      setUsLoading,
                      android
                    )
                  }
                )
              }}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
