import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../customTable/Label'
import RestSaveButton from '../../customTable/RestSaveBtns'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function Table({data}: any) {
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack sx={{overflow: 'auto'}} width={'100%'}>
      <Stack
        position={'relative'}
        minWidth={'500px'}
        border='1px solid #181C32'
        borderRadius={'8px'}
      >
        <Stack direction={'row'} borderBottom={'1px solid #181C32'}>
          <Stack
            width={'50px'}
            borderRight={store.isRtl !== 1 ? '1px solid #181C32' : ''}
            borderLeft={store.isRtl === 1 ? '1px solid #181C32' : ''}
          >
            <Stack p='16px'>1</Stack>
          </Stack>
          <Stack
            minWidth={'20%'}
            borderRight={store.isRtl !== 1 ? '1px solid #181C32' : ''}
            borderLeft={store.isRtl === 1 ? '1px solid #181C32' : ''}
          >
            {' '}
            <Stack p='16px'>{data.point_one} </Stack>
          </Stack>
          <Stack
            minWidth={'50%'}
            borderRight={store.isRtl !== 1 ? '1px solid #181C32' : ''}
            borderLeft={store.isRtl === 1 ? '1px solid #181C32' : ''}
          >
            {' '}
            <Stack p='16px'>{data.target_one} </Stack>
          </Stack>{' '}
          <Stack>
            {' '}
            <Stack p='16px'>CNAME </Stack>
          </Stack>
        </Stack>{' '}
        <Stack direction={'row'}>
          <Stack
            width={'50px'}
            borderRight={store.isRtl !== 1 ? '1px solid #181C32' : ''}
            borderLeft={store.isRtl === 1 ? '1px solid #181C32' : ''}
          >
            <Stack p='16px'>2</Stack>
          </Stack>
          <Stack
            minWidth={'20%'}
            borderRight={store.isRtl !== 1 ? '1px solid #181C32' : ''}
            borderLeft={store.isRtl === 1 ? '1px solid #181C32' : ''}
          >
            {' '}
            <Stack p='16px'>{data.point_two} </Stack>
          </Stack>
          <Stack
            minWidth={'50%'}
            borderRight={store.isRtl !== 1 ? '1px solid #181C32' : ''}
            borderLeft={store.isRtl === 1 ? '1px solid #181C32' : ''}
          >
            {' '}
            <Stack p='16px'>{data.target_two} </Stack>
          </Stack>{' '}
          <Stack>
            {' '}
            <Stack p='16px'>CNAME </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
