import {Stack} from '@mui/material'
import React, {useState, useEffect} from 'react'
import Cards from './Cards'
import TitlePage from '../../customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import ButtonPrient from './ActionBtn'
import {useParams} from 'react-router'
import OrderApi from '../../../Api/order'
import AddressDetails from './AddressDetails'
import OrderDetails from './OrderDetails'
import Table from './Table'
import Address from '../../../Api/SettingAddress'
import axios from 'axios'
import JwtService from '../../../utils/TokenServices'
import Loading from '../../customTable/LoadingForm'
import SimpleLoading from '../../customTable/SimpleLoading'
import MainBtn from '../../customTable/MainBtn'
import Label from '../../customTable/Label'
import Actions from './Actions'

export default function ViewRefundRequests() {
  const {t} = useTranslation()
  const {id} = useParams()

  const bannerInfo = {
    title: t('requestDetails'),
    home: t('home'),
    linkHome: '/',
  }
  const [order, setOrder] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)

  const getOrder = async () => {
    const data = await OrderApi.getSingleOrder(
      id,
      (res: any) => {
        setOrder(res.data)
      },
      setIsLoading
    )
  }
  const [dataStore, setDataStore] = useState({})
  const [isLoadingAddress, setIsLoadingAddress] = useState(false)
  const getAddress = async () => {
    Address.get((res: any) => {
      setDataStore(res.data)
    }, setIsLoadingAddress)
  }
  useEffect(() => {
    getOrder()
    getAddress()
  }, [])
  const [isLoadingPdf, setIsLoadingPdf] = useState(false)

  const getPdfInvo = async () => {
    OrderApi.getOrderPdf(id, () => {}, setIsLoadingPdf)
  }

  return (
    <Stack position={'relative'} minHeight={'80vh'} width={'100%'}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack>
          <Stack gap='15px' flexWrap={'wrap'} direction={'row'}>
            <Stack minWidth={'300px'}>
              <TitlePage info={bannerInfo} />
            </Stack>
            <Stack
              gap='24px'
              mb='34px'
              justifyContent={'flex-end'}
              direction={'row'}
              width={'100%'}
            >
              {order.refund_data &&
                order.refund_data.status &&
                order.refund_data.status === 'pending' && (
                  <Actions setOrder={setOrder} order={order} />
                )}
              {/* <ButtonPrient dataStore={dataStore} invoiceData={order} /> */}
              {isLoadingPdf ? (
                <SimpleLoading />
              ) : (
                <button
                  style={{
                    border: 'none',
                    background: '#009EF7',
                    color: 'white',
                    borderRadius: '5.5px',
                    padding: '7px 17px',
                  }}
                  onClick={getPdfInvo}
                >
                  {t('downLoad')}
                </button>
              )}
            </Stack>
          </Stack>
          <Stack gap='16px' mb='32px' borderRadius={'8px'} bgcolor={'#fff'} p='32px'>
            <Stack alignItems={'center'} gap={'5px'} direction={'row'}>
              <Label fw='600' text='refundType' />:{' '}
              <Label text={order.has_request_refund ? 'fullRefund' : 'partialRefund'} />
            </Stack>
            {order.refund_data && order.refund_data.reason && (
              <>
                {' '}
                <Label fw='600' text='reasonForRefund' />{' '}
                <Label
                  color='#7E8299'
                  fs='13px'
                  text={order.refund_data && order.refund_data.reason}
                />
              </>
            )}
            {order.refund_data && order.refund_data.reject_reason && (
              <>
                {' '}
                <Label fw='600' text='rejectReason' />{' '}
                <Label
                  color='#7E8299'
                  fs='13px'
                  text={order.refund_data && order.refund_data.reject_reason}
                />
              </>
            )}
            {order.refund_data && order.refund_data.refund_amount && (
              <Stack alignItems={'center'} gap={'5px'} direction={'row'}>
                <Label fw='600' text='RefundAmount' />:{' '}
                <Label text={order.refund_data && order.refund_data.refund_amount} />
              </Stack>
            )}
          </Stack>
          <Cards order={order} />
          <AddressDetails order={order} />
          <Table order={order} />
          <OrderDetails order={order} />
        </Stack>
      )}
    </Stack>
  )
}
