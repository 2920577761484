import {FormikProps} from 'formik'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {fileListToBase64} from '../../../utils/image'
import {InitialValues} from './validationSchema'
import ImgMsg from '../../customTable/ImgMsg'
import {Avatar, Stack} from '@mui/material'
import SizeImage from '../../customTable/SizeImage'

type Props = {
  formik: FormikProps<InitialValues>
  hight?: string
  width?: string
}

export const IamgeSection = ({formik, width, hight}: Props) => {
  return (
    <div className='card-body text-center pt-0'>
      <div
        className=' image-input-empty image-input-outline image-input-placeholder mb-3'
        data-kt-image-input='true'
      >
        {' '}
        <label
          htmlFor='image'
          className=' btn-active-color-primary  bg-body shadow'
          data-kt-image-input-action='change'
          data-bs-toggle='tooltip'
          title='Change image'
        >
          <div
            style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            className='image-input-wrapper w-150px h-150px'
          >
            {' '}
            {formik.values.image ? (
              <Avatar
                src={
                  typeof formik.values.image === 'object'
                    ? URL.createObjectURL((formik.values.image as any)[0])
                    : formik.values.image
                }
                alt='cser Avatar'
                className='w-150px h-150px'
                sx={{maxWidth: '100%', maxHeight: '100%', borderRadius: '8px'}}
              />
            ) : (
              <Avatar
                src={toAbsoluteUrl('/media/avatars/img.svg')}
                alt='user-info'
                className='w-100px h-100px'
                sx={{borderRadius: '8px'}}
              />
            )}
          </div>

          {/* <i className='bi bi-pencil-fill fs-7'></i> */}

          <input
            id='image'
            type='file'
            multiple={false}
            style={{display: 'none'}}
            accept='.jpg, .jpeg, .png, .svg'
            onChange={(e) => {
              const fileList = e.target.files as FileList

              if (e.target.files && e.target.files.length > 1) {
                formik.setFieldError('image', 'Please do not take more than 4 items')
                formik.setFieldTouched('image', true)
                e.target.value = '' // Clear the value of the input element
                return
              }
              fileListToBase64(fileList).then((base64String) => {
                formik.setFieldValue('image', base64String)
              })
            }}
            onFocus={(e) => {
              if (!formik.touched.image) {
                formik.setFieldTouched('image', true)
              }
            }}
            onBlur={(e) => {
              if (!formik.touched.image) {
                formik.setFieldTouched('image', true)
              }
            }}
          />
        </label>
        {/*  check  if  the  formik  image  is  found  then  show  x  icon  */}
      </div>
      <SizeImage width={width} hight={hight} />

      {/* <ImgMsg /> */}
    </div>
  )
}
