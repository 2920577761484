import React, {useEffect, useState} from 'react'
import {Stack, Typography} from '@mui/material'
import Table from './Table'
import {useTranslation} from 'react-i18next'
import TitlePage from '../customTable/TitlePage'
import iMedia from '../../interface/iMedia'
import {Link, useNavigate} from 'react-router-dom'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import MainBtn from '../customTable/MainBtn'
import Tabs from './Tabs'
import InvoiceForm from './InvoiceForm'
export default function ServesFees() {
  const {t} = useTranslation()
  const navagite = useNavigate()
  const bannerInfo = {
    title: t('servesFees'),
    home: t('home'),
    linkHome: '/',
  }
  const [rows, setRows] = useState<iMedia[]>([])
  const [added, setAdded] = useState(false)
  const [switchValue, setSwitchValue] = useState('cash')
  const store = useSelector((state: RootState) => state.vender)
  useEffect(() => {
    if (added) {
      setAdded(false)
    }
  }, [rows])

  return (
    <Stack>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <TitlePage info={bannerInfo} />
        {store.permissions && store.permissions.includes('services_fees_value') && (
          <MainBtn
            handelSubmit={() => {
              navagite('values')
            }}
            bgcolor='#f16645'
            width='auto'
            text='serviceFeeValue'
          />
        )}
      </Stack>
      <div style={{marginTop: '30px'}} className={`card fds sha`}>
        <Stack p='0 30px' mt='25px' direction={'row'}>
          <Tabs value={switchValue} setValue={setSwitchValue} />
        </Stack>

        <Table switchValue={switchValue} added={added} rows={rows} setRows={setRows} />
      </div>
    </Stack>
  )
}
