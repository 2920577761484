import {Container, Stack} from '@mui/material'
import React, {useState} from 'react'
import Header from './Header'
import LoginForm from './LoginForm'
import ForgetPassword from './ForgetPassword'
import RestPassword from './RestPassword'

export default function Login() {
  const [isActive, setIsActive] = useState(0)
  const [code, setCode] = useState('')
  const [phone, setPhone] = useState('')
  const [value, setValue] = useState('+964')

  return (
    <Stack minHeight={'100vh'} bgcolor={'#fff'}>
      <Header />
      <Stack justifyContent={'center'} height={'75vh'}>
        <Container maxWidth={'xs'}>
          <Stack width={'100%'}>
            {isActive === 0 && <LoginForm setIsActive={setIsActive} />}
            {isActive === 1 && (
              <ForgetPassword
                value={value}
                setValue={setValue}
                setPhone={setPhone}
                setCode={setCode}
                setIsActive={setIsActive}
              />
            )}
            {isActive === 2 && (
              <RestPassword
                setIsActive={setIsActive}
                code_phone={value}
                phone={phone}
                code={code}
              />
            )}
          </Stack>
        </Container>
      </Stack>
    </Stack>
  )
}
