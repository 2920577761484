import {Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

export default function Links() {
  const {t} = useTranslation()

  const links = [
    {
      name: t('pricing'),
      link: 'https://vendor.api.koninstore.com/en/pricing',
      active: true,
    },
    {
      name: t('templates'),
      link: 'https://vendor.api.koninstore.com/en/templates',
      active: false,
    },
    {
      name: t('help'),
      link: 'https://vendor.api.koninstore.com/en/help',
      active: false,
    },
  ]
  return (
    <Stack gap={'46px'} direction='row'>
      {links.map((e, i) => (
        <a style={{display: 'block', color: ' var(--bs-text-dark)'}} href={e.link}>
          {e.name}
        </a>
      ))}
    </Stack>
  )
}
