import React, {useState} from 'react'
import {ActionsCell} from '../../componet/Menu'
import {Box, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import Switch from '../../componet/Toastr/Switch'
import Media from '../../Api/media'
import PageApi from '../../Api/Page'
import {useNavigate} from 'react-router-dom'
import MenuApi from '../../Api/menu'
import MainBtn from '../customTable/MainBtn'
import PopUp from '../customTable/PopUp'
import Form from './Form'
import Invoice from './invoce/View'
import ServiseFees from '../../Api/serviseFees'
import SimpleLoading from '../customTable/SimpleLoading'
import FixedNumber from '../customTable/FixedNumber'

export default function Row({item, i, switchValue}: {item: any; i: number; switchValue: string}) {
  const [state, setState] = useState(item.status)
  const [open, setOpen] = React.useState(false)
  const [openInvice, setOpenInvice] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const [isLoadingPay, setLoadingPay] = useState(false)
  const pay = () => {
    setOpenInvice(true)
  }

  const [isDeleted, setDeleted] = useState(true)
  const [signature, setSignature] = useState(false)
  const store = useSelector((state: RootState) => state.vender)
  return (
    <>
      {isDeleted && (
        <>
          {' '}
          <tr className='fw-bold text-muted '>
            <td style={{cursor: 'pointer'}}>
              <a onClick={pay} style={{color: '#009EF7'}}>
                #{editItem.code}
              </a>
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted fs-7 fw-semibold'>{editItem.start_date}</span>{' '}
            </td>
            <td style={{textAlign: 'start'}}>
              <span>
                <Stack alignItems={'center'} gap='5px' direction={'row'}>
                  <FixedNumber isNum num={editItem.paid_amount} />

                  {editItem.paid_currency_symbol}
                </Stack>
              </span>{' '}
            </td>
            <td style={{textAlign: 'start'}}>
              <span>
                <Stack alignItems={'center'} gap='5px' direction={'row'}>
                  {' '}
                  <FixedNumber isNum num={editItem.total} />
                  {'  '}
                  {editItem.paid_currency_symbol}
                </Stack>
              </span>{' '}
            </td>{' '}
            <td style={{textAlign: 'start'}}>{editItem.due_date}</td>
            {store.permissions &&
              store.permissions.includes('services_fees_pay') &&
              switchValue === 'cash' && (
                <td style={{textAlign: 'start'}} className='text-start'>
                  {isLoadingPay ? (
                    <SimpleLoading />
                  ) : (
                    <MainBtn
                      disabled={editItem.paid_date}
                      text='pay'
                      width='auto'
                      handelSubmit={() => {
                        ServiseFees.pay(
                          {service_fee_id: editItem.id},
                          (res: any) => {
                            setSignature(res.setSignature)
                            setOpen(true)
                          },
                          setLoadingPay
                        )
                      }}
                    />
                  )}
                </td>
              )}
          </tr>
        </>
      )}
      <PopUp width={'sm'} open={open} setOpen={setOpen}>
        {open && (
          <Form setOpen={setOpen} res={{signature: signature, service_fee_id: editItem.id}} />
        )}
      </PopUp>
      <PopUp width={'md'} open={openInvice} setOpen={setOpenInvice}>
        {openInvice && <Invoice editItem={editItem} id={editItem.id} />}
      </PopUp>
    </>
  )
}
