import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog, {DialogProps} from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {Avatar, Stack, Typography} from '@mui/material'
import Img from '../../../componet/imag'
import {useTranslation} from 'react-i18next'
import Product from '../../../Api/product'
import Loading from '../../customTable/LoadingForm'
import FixedNumber from '../../customTable/FixedNumber'

export default function ViewBtn({open, setOpen, id}: any) {
  const [fullWidth, setFullWidth] = React.useState(true)
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('lg')
  const {product} = useSelector((state: RootState) => state.product)
  const [selectedFilesFound, setSelectedFilesFound] = React.useState<any>([])

  React.useEffect(() => {
    if (product?.product_pictures?.length) {
      const files = product?.product_pictures?.map((file: any) => {
        const imageNameWithExtension = file?.picture.substring(file?.picture.lastIndexOf('/') + 1)
        const imageName = imageNameWithExtension.substring(
          0,
          imageNameWithExtension.lastIndexOf('.')
        )

        return {
          id: file.id,
          preview: file.picture,
          name: imageName,
        }
      })

      setSelectedFilesFound(files)
    }
  }, [product?.product_pictures])

  const handleClose = () => {
    setOpen(false)
  }
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()

  const currency = localStorage.getItem('currency')
  const [isLoading, setIsLoading] = React.useState(false)
  const getData = () => {
    Product.getViewProduct(
      id,
      (res: any) => {
        setViewData(res.data)
      },
      setIsLoading
    )
  }
  const [viewData, setViewData] = React.useState<any>({})
  React.useEffect(() => {
    if (open) {
      getData()
    }
  }, [open])

  return (
    <React.Fragment>
      <></>
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)', // تعديلاتك الخاصة هنا
          },
          '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px', // تعديلاتك الخاصة هنا
          },
        }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        {isLoading ? (
          <Stack position={'relative'} width={'100%'} height={'250px'}>
            <Loading />
          </Stack>
        ) : (
          <Stack
            dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
            gap='18px'
            direction={'row'}
            p={{md: '60px', xs: '20px'}}
            width={'100%'}
            sx={{color: 'var(--bs-text-dark)'}}
            flexWrap={{xs: 'wrap', lg: 'nowrap'}}
          >
            <Box width={{lg: '550px', xs: '100%'}}>
              <Stack direction={'row'} gap='10px' flexWrap={'wrap'}>
                <Stack width={'100%'}>
                  <Avatar
                    sx={{width: '100%', height: {xs: '40vw', md: '446px'}, borderRadius: '8px'}}
                    src={viewData.image}
                    alt='image'
                  />
                </Stack>

                <Stack p={'10px 0'} gap='15px' width={'100%'} overflow={'auto'} direction={'row'}>
                  {viewData.product_pictures &&
                    viewData.product_pictures.map((file: any, i: number) => (
                      <>
                        <img
                          style={{width: i === 0 ? '173.2px' : '173.2px', borderRadius: '12px'}}
                          src={file.picture}
                          alt=''
                        />
                      </>
                    ))}
                </Stack>
              </Stack>
            </Box>

            <Stack width={{lg: 'calc(100% - 550px)', md: '100%'}}>
              <Typography sx={{fontSize: '24px', fontWeight: '800'}}>
                {viewData.name && (viewData.name[store.lang] || viewData.name[store.defLang])}
              </Typography>
              <Stack mt='16px' height={'44px'} direction={'row'}>
                <div className='rating '>
                  {Array.from({length: viewData.rate}, (_, i) => (
                    <div className='rating-label checked'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </div>
                  ))}
                  {Array.from({length: 5 - viewData.rate}, (_, i) => (
                    <div className='rating-label'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                    </div>
                  ))}
                  ({viewData.rate_counts} {t('customerReview')})
                </div>
              </Stack>
              <Typography
                dangerouslySetInnerHTML={{
                  __html:
                    viewData.description &&
                    (viewData.description[store.lang] || viewData.description[store.defLang]),
                }}
                sx={{fontSize: '24px', fontWeight: '800', minHeight: '80px'}}
              ></Typography>
              <Typography sx={{fontSize: '30px', fontWeight: '800', color: '#D81923'}}>
                <FixedNumber num={viewData.price} />
              </Typography>
              <Stack flexWrap={'wrap'} gap='44px' direction={'row'} mt='8px'>
                <Stack
                  sx={{
                    boxShadow: '0px 0px 15px rgba(37, 143, 103, 0.1)',
                    borderRadius: '24px',
                    padding: '10px 3px',
                    width: '109px',
                    fontSize: '17px',
                    fontWeight: '700',
                  }}
                  direction={'row'}
                  justifyContent={'center'}
                  gap='22px'
                >
                  <span style={{color: '#C8C8C8'}}>-</span> <span>1</span>
                  <span>+</span>
                </Stack>
                <Stack
                  sx={{
                    boxShadow: '0px 0px 15px rgba(37, 143, 103, 0.1)',
                    borderRadius: '24px',
                    padding: '10px 15px',
                    // width: '109px',
                    fontSize: '17px',
                    fontWeight: '700',
                    background: '#D81923',
                    color: 'white',
                  }}
                  direction={'row'}
                  justifyContent={'center'}
                  gap='22px'
                >
                  {t('AddtoCart')}
                </Stack>
                <Stack
                  sx={{
                    boxShadow: '0px 0px 15px rgba(37, 143, 103, 0.1)',
                    borderRadius: '24px',
                    padding: '10px 15px',
                    // width: '109px',
                    fontSize: '17px',
                    fontWeight: '700',
                    background: 'white',
                    border: '1px solid #D81923',
                    color: '#D81923',
                  }}
                  direction={'row'}
                  justifyContent={'center'}
                  gap='22px'
                >
                  {t('buyNow')}
                </Stack>
              </Stack>
              <Stack>
                <Typography m='32px 0' sx={{fontSize: '16px', fontWeight: '500'}}>
                  {t('buyMore')}
                </Typography>
                <Stack>
                  <Stack
                    sx={{borderTop: '1px  solid var(--bs-gray-300)'}}
                    width={'100%'}
                    direction={'row'}
                  >
                    <Typography
                      sx={{fontWeight: '500', fontSize: '16px', padding: '20px 0', width: '43%'}}
                    >
                      {t('quantity')}
                    </Typography>
                    <Typography
                      sx={{fontWeight: '500', fontSize: '16px', padding: '20px 0', width: '43%'}}
                    >
                      {t('pricePerItem')}
                    </Typography>
                    <Typography
                      sx={{fontWeight: '500', fontSize: '16px', padding: '20px 0', width: '14%'}}
                    >
                      {t('discount')}
                    </Typography>
                  </Stack>
                  {viewData.bulk_discount &&
                    viewData.bulk_discount.map((e: any, i: any) => (
                      <Stack
                        sx={{borderTop: '1px  solid var(--bs-gray-300)'}}
                        width={'100%'}
                        direction={'row'}
                      >
                        <Typography
                          sx={{
                            fontWeight: '500',
                            fontSize: '16px',
                            padding: '20px 0',
                            width: '43%',
                          }}
                        >
                          {e.min + '-' + e.max}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: '500',
                            fontSize: '16px',
                            padding: '20px 0',
                            width: '43%',
                          }}
                        >
                          {currency}
                          {viewData.price}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: '500',
                            fontSize: '16px',
                            padding: '20px 0',
                            color: '#707B86',
                            width: '14%',
                          }}
                        >
                          {e.is_fixed === 1 ? e.value : 3 / 100}
                        </Typography>
                      </Stack>
                    ))}
                </Stack>
                <Stack gap='32px' mt='48px'>
                  {viewData.options &&
                    viewData.options.map((e: any, i: any) => (
                      <>
                        {e && e.input_type === 'single_select' && e.value_type === 'color' ? (
                          <Stack gap='32px'>
                            <Typography sx={{fontSize: '22px', fontWeight: '500'}}>
                              {e && e.name && (e.name[store.lang] || e.option.name[store.defLang])}
                            </Typography>
                            <Stack gap='40px' flexWrap={'wrap'} direction={'row'}>
                              {e.option_details.map((a: any, i: any) => (
                                <Stack
                                  sx={{
                                    // border: '1px solid var(--bs-text-dark)',
                                    borderRadius: '8px',
                                    // minWidth: '60px',
                                    minHeight: '60px',
                                  }}
                                  bgcolor={a.option_code}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                ></Stack>
                              ))}
                            </Stack>
                          </Stack>
                        ) : e && e.input_type === 'single_select' && e.value_type === 'image' ? (
                          <Stack gap='32px'>
                            <Typography sx={{fontSize: '22px', fontWeight: '500'}}>
                              {e && e.name && (e.name[store.lang] || e.option.name[store.defLang])}
                            </Typography>
                            <Stack flexWrap={'wrap'} gap='40px' direction={'row'}>
                              {e.option_details.map((e: any, i: any) => (
                                <Stack>
                                  <img
                                    style={{width: '60px', height: '60px', borderRadius: '16px'}}
                                    src={e.option_image}
                                    alt=''
                                  />
                                </Stack>
                              ))}
                            </Stack>
                          </Stack>
                        ) : e && e.input_type === 'single_select' && e.value_type === 'text' ? (
                          <Stack gap='32px'>
                            {' '}
                            <Typography sx={{fontSize: '22px', fontWeight: '500'}}>
                              {e && e.name && (e.name[store.lang] || e.name[store.defLang])}
                            </Typography>{' '}
                            <Stack flexWrap={'wrap'} gap='40px' direction={'row'}>
                              {e.option_details.map((a: any, i: any) => (
                                <Stack
                                  p='10px'
                                  sx={{
                                    border: '1px solid var(--bs-text-dark)',
                                    borderRadius: '8px',
                                    // minWidth: '60px',
                                    minHeight: '60px',
                                  }}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                >
                                  <Typography sx={{fontSize: '24px', fontWeight: '500'}}>
                                    {a.option_value &&
                                      (a.option_value[store.lang] || a.option_value[store.defLang])}
                                  </Typography>
                                </Stack>
                              ))}
                            </Stack>
                          </Stack>
                        ) : e && e.input_type === 'date' ? (
                          <>
                            {e && e.name && (
                              <Stack mt='24px'>
                                <Typography sx={{fontSize: '22px', fontWeight: '500'}}>
                                  {e.name[store.lang] || e.name[store.defLang]}
                                </Typography>
                                <Stack
                                  direction={'row'}
                                  justifyContent={'space-between'}
                                  p='10px'
                                  mt='15px'
                                  mb='15px'
                                  border={'1px solid #A8A9A9'}
                                  // minHeight={'56px'}
                                  borderRadius={'7px'}
                                >
                                  <Typography sx={{color: '#A9A9A9'}}>{t('datePicker')}</Typography>
                                  <img src='./media/avatars/icons/calendar.svg' alt='' />
                                </Stack>
                              </Stack>
                            )}
                          </>
                        ) : e && e.input_type === 'text' ? (
                          <>
                            {e && e.name && (
                              <Stack mt='24px'>
                                <Typography sx={{fontSize: '22px', fontWeight: '500'}}>
                                  {e.name[store.lang] || e.name[store.defLang]}
                                </Typography>
                                <Box
                                  mt='15px'
                                  mb='15px'
                                  border={'1px solid #A8A9A9'}
                                  minHeight={'80px'}
                                  borderRadius={'7px'}
                                ></Box>
                              </Stack>
                            )}
                          </>
                        ) : e && e.input_type === 'file' ? (
                          <>
                            {e && e.name && (
                              <Stack mt='24px'>
                                <Typography sx={{fontSize: '22px', fontWeight: '500'}}>
                                  {e.name[store.lang] || e.name[store.defLang]}
                                </Typography>
                                <Stack
                                  direction={'row'}
                                  justifyContent={'center'}
                                  p='15px 10px'
                                  mt='15px'
                                  mb='5px'
                                  border='1px dashed #009AFF'
                                  // minHeight={'56px'}
                                  bgcolor={'#F0F9FF'}
                                  borderRadius={'7px'}
                                  maxWidth={'445px'}
                                >
                                  <img
                                    style={{width: '20px'}}
                                    src='./media/avatars/icons/Upload.svg'
                                    alt=''
                                  />
                                </Stack>
                                {t('additionally')}
                              </Stack>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    ))}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Dialog>
    </React.Fragment>
  )
}
