import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import {Stack} from '@mui/material'
import ErrorMsg from './NewMsgErr'

export default function MySelect({
  name,
  values,
  option,
  disabled,
  value,
  setValues,
  withoutBorder,
  errors,
  stringValue,
}: {
  name: string
  values: any
  option: any
  disabled?: boolean | undefined
  value?: any
  setValues: any
  withoutBorder?: any
  errors?: any
  stringValue?: any
}) {
  const [selectedOption, setSelectedOption] = useState<any>(value)
  const store = useSelector((state: RootState) => state.vender)
  console.log(value)

  const handleChange = (event: any) => {
    const selectedId = parseInt(event.target.value)
    const selectedItem = option.find(
      (item: any) => item.id === selectedId || item.value === selectedId
    )
    setSelectedOption(selectedItem)
    if (stringValue) {
      setValues({...values, [`${name}`]: event.target.value})
    } else {
      setValues({...values, [`${name}`]: +event.target.value ? +event.target.value : 0})
    }
    // values[`${name}`] = +event.target.value
  }

  return (
    <Stack
      sx={{border: withoutBorder ? 'none' : '1px solid var(--bs-gray-300)', borderRadius: '6.18px'}}
    >
      <select
        disabled={disabled}
        style={{
          padding: '12.0625px 13px 12.5px',
          background: 'var(--bs-body-bg)',
          color: ' var(--bs-text-dark)',
          border: 'none',
          borderRadius: '6.18px',
          width: 'calc(100% - 10px)',
          // backgroundColor: 'var(--bs-gray-100)',
          // borderColor: 'var(--bs-gray-100)',
        }}
        value={selectedOption && (selectedOption.id || selectedOption.value)}
        onChange={handleChange}
      >
        <option value={0} disabled hidden>
          select
        </option>
        {option.map((e: any) => (
          <option key={e.id || e.value} value={e.id || e.value}>
            {(e.name && e.name[`${store.lang}`]) || e.name || e.label}
          </option>
        ))}
      </select>
      {errors && errors[name] && errors && typeof errors[name] === 'string' && (
        <ErrorMsg msgErr={errors[name]} />
      )}
    </Stack>
  )
}
