import {useFormik} from 'formik'
import * as React from 'react'

import {useCallback, useEffect, useMemo, useState} from 'react'
import 'react-quill/dist/quill.snow.css'
import {useSelector} from 'react-redux'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, {AlertProps} from '@mui/material/Alert'

import {RootState, useDispatch, withToastr} from '../../../store'
import {
  createCategoryAction,
  getCategoryById,
  updateCategoryAction,
} from '../../../store/vender/catogory/thunk'
import CloseIcon from '@mui/icons-material/Close'
import {getProductName} from '../../../store/vender/products/thunk'
import {Language} from '../../../types'
import {IamgeSection} from './iamgeSection'
import {InitialValues} from './validationSchema'
import {CategorySchema} from '../../../yup/categorySchema'
import * as Yup from 'yup'
import {useTranslation} from 'react-i18next'
import Editor from '../../customTable/Editor'
import {IconButton, Stack, Typography} from '@mui/material'
import TabsWithFlag from '../../customTable/LangTabsWithFlag'
import {toast} from 'react-toastify'
import MainBtn from '../../customTable/MainBtn'
import ValidationFun from '../../../yup/ValidationFun'
import Catalog from '../../../Api/catalog'
import ErrorMsg from '../../customTable/NewMsgErr'

const animatedComponents = makeAnimated()
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
export const AddCategory = () => {
  const [open, setOpen] = React.useState(false)
  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const dispatch = useDispatch()
  const {id} = useParams<{
    id?: string
  }>()
  const {nameProduct} = useSelector((state: RootState) => state.product)
  const {category} = useSelector((state: RootState) => state.category)
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const {t} = useTranslation()

  const lang = useLang()

  const fetchListProduct = useCallback(() => {
    if (id) {
      //   we  need  to  featch  catofory details
      dispatch(
        withToastr(getCategoryById(id), {
          success: {
            title: 'Success to get  Catogry details ',
          },
        })
      )
    }
    dispatch(
      withToastr(getProductName({}), {
        success: {
          title: `success to update data to  updated `,
        },
        error: {
          title: `error to update data to  updated`,
        },
      })
    )
  }, [dispatch, id])

  //   useEffect
  useEffect(() => {
    fetchListProduct()
  }, [fetchListProduct, id])

  const store = useSelector((state: RootState) => state.vender)

  const productListFormat = useMemo(() => {
    const list = nameProduct?.map((item) => {
      return {
        value: item.id,
        label: item.name && (item.name[store.lang] || item.name[store.defLang]),
      }
    })
    return list
  }, [lang, nameProduct])

  const [activeLang, setActiveLang] = useState<Language>('en')
  const [languages, setlanguages] = useState(['en'])

  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  useEffect(() => {
    if (store.defLang) {
      setActiveLang(store.defLang)
    }
    getLangs()
  }, [store.langs])
  const [langs, setLangs] = useState([])
  const initialValues: InitialValues = useMemo(() => {
    let obj = {} as InitialValues
    obj.image = ''
    obj.sort = ''
    obj.status = true
    obj.name = {} as {[key in Language]: string}
    obj.permalink = {} as {[key in Language]: string}
    obj.description = {} as {[key in Language]: string}
    obj.meta_title = {} as {[key in Language]: string}
    obj.meta_description = {} as {[key in Language]: string}
    // obj.meta_keywords = {} as {[key in Language]: string}
    obj.meta_keywords = {} as {[key in Language]: any}

    obj.products = []
    if (!id && store.cashCataData.dataCashType === 'cata') {
      // obj = store.cashData
      for (const lang of store.storeLangs) {
        obj.name[lang] = ''
        obj.description[lang] = ''
        obj.permalink[lang] = ''
        obj.meta_title[lang] = ''
        obj.meta_description[lang] = ''
        // obj.meta_keywords[lang] = ''
        obj.meta_keywords[lang] = []

        obj.products = []
      }
    } else if (id && category) {
      const productsData = (category as any).products

      const {
        image,
        sort,
        status,
        name,
        description,
        permalink,
        meta_title,
        meta_description,
        meta_keywords,
        products,
      } = category as InitialValues

      obj.image = image || ''
      obj.sort = sort || 0
      obj.status = status || false
      obj.name = name || ({} as {[key in Language]: string})
      obj.permalink = permalink || ({} as {[key in Language]: string})
      obj.description = description || ({} as {[key in Language]: string})
      obj.meta_title = meta_title || ({} as {[key in Language]: string})
      obj.meta_description = meta_description || ({} as {[key in Language]: string})
      // obj.meta_keywords = meta_keywords || ({} as {[key in Language]: string})
      // obj.meta_keywords = meta_keywords || ({} as {[key in Language]: any})
      obj.products = Array.isArray(products) ? products.map((x: any) => x.id) : []
      for (const lang of store.storeLangs) {
        obj.meta_keywords[lang] =
          meta_keywords && meta_keywords[lang] && typeof meta_keywords[lang] === 'string'
            ? meta_keywords[lang].split(',')
            : []
      }
    } else {
      for (const lang of store.storeLangs) {
        obj.name[lang] = ''
        obj.description[lang] = ''
        obj.permalink[lang] = ''
        obj.meta_title[lang] = ''
        obj.meta_description[lang] = ''
        // obj.meta_keywords[lang] = ''
        obj.meta_keywords[lang] = []

        obj.products = []
      }
    }

    return obj
  }, [category, id])
  const [msgErr, setMsgErr] = useState<string>('')
  const [errors, setErrors] = useState<any>({})
  const formik = useFormik<InitialValues>({
    initialValues: initialValues as InitialValues,
    // validationSchema: ValuesSchema,
    enableReinitialize: true,

    onSubmit: (values, {setSubmitting, resetForm}) => {
      if (id && category) {
        ValidationFun(values, CategorySchema(languages), setErrors, async () => {
          Catalog.put(
            id,
            {
              id,
              ...values,
              image: /^data:image\/[a-zA-Z]+;base64,/.test(values.image) ? values.image : null,
            },
            () => {
              navigate('/catalog/list')
            },
            setLoading
          )
        })
      } else {
        ValidationFun(
          {...values, sort: values.sort ? values.sort : 0},
          CategorySchema(languages),
          setErrors,
          () => {
            Catalog.add(
              {
                ...values,
                sort: values.sort ? values.sort : 0,
                products: values.products ? values.products : [],
              },
              () => {
                if (store.cashData.dataCashType === 'product') {
                  navigate(
                    store.cashData.dataCashType === 'product' && store.cashData.id
                      ? `/catalog/edit-product/${store.cashData.id}`
                      : store.cashData.dataCashType === 'product'
                      ? '/catalog/add-product '
                      : '/catalog/list'
                  )
                  resetForm()
                  setLoading(false)
                } else if (store.cashCataData.dataCashType === 'cata') {
                  navigate(
                    store.cashCataData.dataCashType === 'product' && store.cashCataData.id
                      ? `/catalog/add-sub-category/${store.cashCataData.id}`
                      : store.cashCataData.dataCashType === 'cata'
                      ? '/catalog/add-sub-category'
                      : '/catalog/list'
                  )
                } else {
                  resetForm()
                  setSubmitting(false)

                  navigate('/catalog/list')
                  setLoading(false)
                }
              },
              setLoading
            )
          }
        )
      }
    },
  })
  console.log(formik.values.meta_keywords)

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
    }),
    option: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
      color: 'var(--bs-heading-color, inherit)',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-app-bg-color)',
      color: 'var(--bs-heading-color, inherit)',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: 'var(--bs-heading-color, inherit)',
    }),
  }
  const getLangs = async () => {
    setLangs(store.langs)
  }
  const [tag, setTag] = useState('')

  const addTag = () => {
    if (!tag) {
      return
    }
    if (!formik.values.meta_keywords) {
      formik.setFieldValue(`meta_keywords[${activeLang}]`, [tag])
      setTag('')
    } else if (!formik.values.meta_keywords[`${activeLang}`]) {
      formik.setFieldValue(`meta_keywords[${activeLang}]`, [tag])

      setTag('')
    } else if (
      formik.values.meta_keywords[`${activeLang}`] &&
      !formik.values.meta_keywords[`${activeLang}`].includes(tag)
    ) {
      formik.setFieldValue(`meta_keywords[${activeLang}]`, [
        ...formik.values.meta_keywords[`${activeLang}`],
        tag,
      ])
      setTag('')
    } else {
      handleClick()
    }
  }
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      addTag()
    }
  }
  return (
    <div style={{marginTop: '28px'}} className='content d-flex flex-column flex-column-fluid'>
      <div className='post d-flex flex-column-fluid'>
        {/* //<!--begin::Container--> */}
        <div
          style={{paddingRight: 0, paddingLeft: 0, margin: 'auto 0', maxWidth: '20000px'}}
          className='container-xxl'
        >
          <div
            style={{gap: '31px'}}
            // onSubmit={formik.handleSubmit}
            className='form d-flex flex-column flex-lg-row'
            // noValidate
          >
            {/* //<!--begin::Aside column--> */}
            <Stack>
              <Stack position={'sticky'} top={90}>
                <div
                  style={{marginTop: '0'}}
                  className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7  pt-1 '
                >
                  {/* //<!--begin::Thumbnail settings--> */}
                  <div className='card card-flush py-4'>
                    {/* //<!--begin::Card header--> */}
                    <div className='card-header'>
                      {/* //<!--begin::Card title--> */}
                      <div className='card-title'>
                        <h2>{t('thumbnail')}</h2>
                      </div>
                      {/* //<!--end::Card title--> */}
                    </div>
                    <IamgeSection width='category_width' hight='category_height' formik={formik} />
                    <ErrorMsg msgErr={errors[`image`]} />

                    {/* <ErrorMsg msgErr={msgErr} name='Image' /> */}
                  </div>
                  {/* //<!--end::Thumbnail settings--> */}
                  {/* //<!--begin::Status--> */}
                  <div className='card card-flush py-4'>
                    {/* //<!--begin::Card header--> */}
                    <div className='card-header'>
                      {/* //<!--begin::Card title--> */}
                      <div className='card-title'>
                        <h2>{t('status')}</h2>
                      </div>
                      {/* //<!--end::Card title--> */}
                      {/* //<!--begin::Card toolbar--> */}
                      <div className='card-toolbar'>
                        <div
                          style={{
                            background:
                              !formik.values.status || formik.values.status == 0
                                ? '#a2aca2'
                                : 'green',
                          }}
                          className='rounded-circle  w-15px h-15px'
                        ></div>
                      </div>
                      {/* //<!--begin::Card toolbar--> */}
                    </div>
                    {/* //<!--end::Card header--> */}
                    {/* //<!--begin::Card body--> */}
                    <div className='card-body pt-0'>
                      {/* //<!--begin::Select2--> */}
                      <select
                        {...formik.getFieldProps('status')}
                        className='form-select mb-2'
                        data-control='select2'
                        data-hide-search='true'
                        data-placeholder='Select an option'
                        value={!formik.values.status || formik.values.status == 0 ? 0 : 1}
                        // onChange={(e:any)=>}
                      >
                        {/* <option value='0'></option> */}
                        <option value={1}>{t('active')}</option>
                        <option value={0}>{t('inactive')}</option>
                      </select>
                      <div className='text-muted fs-7'> {t('setCategoryStatus')}</div>
                      {formik.touched.status && formik.errors.status && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.status}</div>
                        </div>
                      )}
                    </div>
                    {/* //<!--end::Card body--> */}
                  </div>
                </div>
              </Stack>
            </Stack>

            <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              {/* //<!--begin::General options--> */}
              <Stack position={'relative'}>
                <TabsWithFlag
                  languages={langs}
                  activeLang={activeLang}
                  setActiveLang={setActiveLang}
                  msgErr={errors && Object.keys(errors)[0]}
                />
              </Stack>
              {langs.map((e: any, i) => (
                <>
                  {e.code === activeLang && (
                    <>
                      {' '}
                      <div style={{background: 'var(--bs-app-bg-color)'}}>
                        <div className='card  card-flush py-4'>
                          {/* //<!--begin::Card header--> */}
                          <div className='card-header'>
                            <div className='card-title'>
                              <h2>{t('general')}</h2>
                            </div>
                          </div>
                          {/* //<!--end::Card header--> */}
                          {/* //<!--begin::Card body--> */}
                          <div className='card-body pt-0'>
                            {/* //<!--begin::Input group--> */}
                            <div className='mb-10 fv-row'>
                              {/* //<!--begin::Label--> */}
                              <label className='required form-label'>{t('categoryName')}</label>

                              <input
                                type='text'
                                className='form-control form-control-lg '
                                {...formik.getFieldProps(`name[${activeLang}]`)}
                                onBlur={() => {
                                  if (id) {
                                    if (!formik.values.permalink[activeLang]) {
                                      formik.setFieldValue(
                                        `permalink[${activeLang}]`,
                                        formik.values.name[activeLang].trim().replace(/\s/g, '-')
                                      )
                                    }
                                  } else {
                                    formik.setFieldValue(
                                      `permalink[${activeLang}]`,
                                      formik.values.name[activeLang].trim().replace(/\s/g, '-')
                                    )
                                  }
                                  formik.setFieldTouched(`name[${activeLang}]`, true)
                                }}
                              />
                              <>
                                <ErrorMsg
                                  child={activeLang}
                                  msgErr={errors[`name.${activeLang}`]}
                                />
                              </>
                            </div>

                            <div className='mb-10 fv-row'>
                              {/* //<!--begin::Label--> */}
                              <label className='form-label required'>{t('description')}</label>
                              <Editor child={'description'} formik={formik} name={activeLang} />

                              <ErrorMsg
                                child={activeLang}
                                msgErr={errors[`description.${activeLang}`]}
                              />
                              {/* //<!--end::Editor--> */}
                              {/* //<!--begin::Description--> */}
                              <div style={{marginTop: '40px'}} className='mb-2 fv-row'>
                                {/* //<!--begin::Label--> */}
                                <label className='required form-label'>{t('permalink')} </label>
                                <input
                                  type='text'
                                  className='form-control form-control-lg'
                                  {...formik.getFieldProps(`permalink[${activeLang}]`)}
                                  onBlur={() => {
                                    formik.setFieldValue(
                                      `permalink[${activeLang}]`,
                                      formik.values.permalink[activeLang].trim().replace(/\s/g, '-')
                                    )
                                  }}
                                />
                                <ErrorMsg
                                  child={activeLang}
                                  msgErr={errors[`permalink.${activeLang}`]}
                                />
                              </div>
                            </div>
                            {/* //<!--end::Input group--> */}
                          </div>
                          {/* //<!--end::Card header--> */}
                        </div>
                        {/* //<!--end::General options--> */}
                        {/* //<!--begin::Meta options--> */}
                        <div style={{marginTop: '30px'}} className='card card-flush py-4'>
                          {/* //<!--begin::Card header--> */}
                          <div className='card-header'>
                            <div className='card-title'>
                              <h2>{t('metaOptions')}</h2>
                            </div>
                          </div>
                          {/* //<!--end::Card header--> */}
                          {/* //<!--begin::Card body--> */}
                          <div className='card-body pt-0'>
                            {/* //<!--begin::Input group--> */}
                            <div className='mb-10'>
                              {/* //<!--begin::Label--> */}
                              <label className='form-label'>
                                {t('metaTagTitle')} {activeLang}
                              </label>
                              {/* //<!--end::Label--> */}
                              {/* //<!--begin::Input--> */}
                              <input
                                type='text'
                                {...formik.getFieldProps(`meta_title[${activeLang}]`)}
                                className='form-control mb-2 form-control-lg'
                              />

                              {/* //<!--end::Input--> */}
                              {/* //<!--begin::Description--> */}
                              <div className='text-muted fs-7'>{t('setMetaTagTitle')}</div>
                              {formik.touched.meta_title &&
                                formik.touched.meta_title[activeLang] &&
                                formik.errors.meta_title &&
                                formik.errors.meta_title[activeLang] && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {formik.errors.meta_title &&
                                        formik.errors.meta_title[activeLang]}
                                    </div>
                                  </div>
                                )}
                            </div>
                            {/* //<!--end::Input group--> */}
                            {/* //<!--begin::Input group--> */}
                            <div className='mb-10'>
                              {/* //<!--begin::Label--> */}
                              <label className='form-label'>
                                {t('metaTagDescription') + ' '}
                                {activeLang}
                              </label>
                              <textarea
                                style={{
                                  padding: '12.0625px 13px 12.5px',
                                  background: 'var(--bs-body-bg)',
                                  border: '1px solid var(--bs-gray-300)',
                                  borderRadius: '6.18px',
                                  width: '100%',
                                  color: 'var(--bs-text-dark)',
                                }}
                                rows={6}
                                {...formik.getFieldProps(`meta_description[${activeLang}]`)}
                              />

                              {formik.touched.meta_description &&
                                formik.touched.meta_description[activeLang] &&
                                formik.errors.meta_description &&
                                formik.errors.meta_description[activeLang] && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {formik.errors.meta_description &&
                                        formik.errors.meta_description[activeLang]}
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div>
                              {/* //<!--begin::Label--> */}
                              <label className='form-label'>
                                {t('metaTagKeywords')} {activeLang}{' '}
                              </label>
                              {/* //<!--end::Label--> */}
                              {/* //<!--begin::Editor--> */}
                              <>
                                <Stack position={'relative'}>
                                  <input
                                    type='text'
                                    value={tag}
                                    onChange={(e) => setTag(e.target.value)}
                                    className='form-control form-control-lg '
                                    onKeyDown={handleKeyDown}
                                  />
                                  <Stack
                                    position={'absolute'}
                                    height={'100%'}
                                    width={'43px'}
                                    right={store.isRtl === 0 ? 0 : 'auto'}
                                    left={store.isRtl !== 0 ? 0 : 'auto'}
                                    borderRadius={store.isRtl === 0 ? '0 8px 8px 0' : '8px 0 0 8px'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    sx={{
                                      background: !tag ? '#afb2c1' : 'var(--main-color)',
                                      color: 'white',
                                      cursor: 'pointer',
                                    }}
                                    onClick={addTag}
                                  >
                                    <Typography sx={{fontSize: '20px'}}>+</Typography>
                                  </Stack>
                                </Stack>
                                <Stack m='20px 0' flexWrap={'wrap'} gap='15px' direction={'row'}>
                                  {formik.values.meta_keywords &&
                                    formik.values.meta_keywords[`${activeLang}`] &&
                                    typeof formik.values.meta_keywords[`${activeLang}`] !==
                                      'string' &&
                                    formik.values.meta_keywords[`${activeLang}`].map(
                                      (e: any, i: any) => (
                                        <Stack
                                          gap='15px'
                                          minWidth={'60px'}
                                          p='3px 8px'
                                          borderRadius={'6px'}
                                          sx={{background: '#f5f8fa'}}
                                          direction={'row'}
                                          justifyContent={'space-between'}
                                          alignItems={'center'}
                                        >
                                          <Typography sx={{color: '#181c32'}}>{e}</Typography>
                                          <Typography
                                            onClick={() => {
                                              formik.setFieldValue(
                                                `meta_keywords[${activeLang}]`,
                                                formik.values.meta_keywords[`${activeLang}`].filter(
                                                  (a: any) => a !== e
                                                )
                                              )
                                            }}
                                            sx={{color: '#181c32', cursor: 'pointer'}}
                                          >
                                            {' '}
                                            <svg
                                              height='14'
                                              width='14'
                                              viewBox='0 0 20 20'
                                              aria-hidden='true'
                                              focusable='false'
                                              className='css-tj5bde-Svg'
                                            >
                                              <path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
                                            </svg>
                                          </Typography>
                                        </Stack>
                                      )
                                    )}
                                  <Snackbar
                                    open={open}
                                    autoHideDuration={6000}
                                    onClose={handleClose}
                                  >
                                    <Alert
                                      onClose={handleClose}
                                      severity='warning'
                                      sx={{width: '100%'}}
                                    >
                                      {t('msgtag')}{' '}
                                    </Alert>
                                  </Snackbar>
                                </Stack>
                              </>
                              {/* //<!--end::Editor--> */}
                              {/* //<!--begin::Description--> */}
                              <div className='text-muted fs-7'>
                                {t('setMetaTagKeywords')}

                                <code>,</code>
                                {t('betweenEachKeyword')}
                              </div>
                            </div>
                            {/* //<!--end::Input group--> */}
                          </div>
                          {/* //<!--end::Card header--> */}
                        </div>
                      </div>
                      <div className='card card-flush py-4'>
                        {/* //<!--begin::Card header--> */}
                        <div className='card-body pt-0'>
                          <div
                            style={{marginTop: '15px'}}
                            className='d-flex flex-wrap gap-2 text-black'
                          >
                            {/* //<!--begin::Label--> */}
                            <label className='form-label fs-2'>{t('sort')}</label>

                            <input
                              type='number'
                              min={0}
                              {...formik.getFieldProps(`sort`)}
                              className='form-control mb-2 form-control-lg'
                            />
                            <ErrorMsg msgErr={errors[`sort`]} />
                          </div>
                        </div>
                        {/* //<!--end::Card header--> */}
                        {/* //<!--begin::Card body--> */}
                      </div>
                    </>
                  )}
                </>
              ))}

              <div className='card card-flush py-4'>
                <div className='card-body pt-0'>
                  {/* //<!--begin::Input group--> */}
                  <div>
                    <div className='d-flex flex-wrap gap-5'>
                      {/* begin::Input group */}
                      <div style={{marginTop: '15px'}} className='fv-row w-100 flex-md-root'>
                        {/* begin::Label */}
                        <label className='form-label'>{t('assignProducts')}</label>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          styles={customStyles}
                          options={productListFormat}
                          value={formik.values.products?.map((product: any) => {
                            return {
                              value: product,
                              label: productListFormat.find((item: any) => item.value === product)
                                ?.label,
                            }
                          })}
                          onChange={(options) => {
                            let products: Array<string> = []
                            options?.map((option: any) => {
                              products.push(option?.value)
                            })
                            formik.setFieldValue('products', products)
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.products && formik.errors.products && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.products}</div>
                          </div>
                        )}
                      </div>
                      {/* end::Input group */}
                      {/* begin::Input group */}
                    </div>
                  </div>
                </div>
                {/* //<!--end::Card header--> */}
              </div>

              <div>
                <div style={{gap: '20px'}} className='d-flex justify-content-end'>
                  <Link
                    onClick={() => {}}
                    to={
                      store.cashData.dataCashType === 'product' && store.cashData.id
                        ? `/catalog/edit-product/${store.cashData.id}`
                        : store.cashData.dataCashType === 'product'
                        ? '/catalog/add-product '
                        : store.cashCataData.dataCashType === 'cata' && store.cashCataData.id
                        ? `/catalog/edit-sub-category/${store.cashCataData.id}`
                        : store.cashCataData.dataCashType === 'cata'
                        ? 'add-sub-category'
                        : '/catalog/list'
                    }
                    className='btn btn-light me-5'
                  >
                    {t('cancel')}
                  </Link>
                  <MainBtn
                    width='auto'
                    type='submit'
                    isLoading={loading}
                    handelSubmit={() => formik.handleSubmit()}
                    text='saveChanges'
                  />
                  {/* <button
                    onClick={() => formik.handleSubmit()}
                    type='submit'
                    className='btn btn-danger'
                    disabled={formik.isSubmitting}
                  >
                    <span className='indicator-label'>
                      {formik.isSubmitting ? t('wait') : t('saveChanges')}{' '}
                    </span>
                    {formik.isSubmitting && (
                      <span className='indicator-progress'>
                        {' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button> */}
                </div>
                <div className='d-flex justify-content-end'></div>
                {/* //<!--begin::Button--> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //<!--end::Post--> */}
    </div>
  )
}
