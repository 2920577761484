import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../../customTable/Label'
import OrderInfo from './OrderInfo'
import InputWithLabel from '../../customTable/LabelWithText'
import RestSaveButton from '../../customTable/RestSaveBtns'
import {useTranslation} from 'react-i18next'
import TextErea from '../../customTable/TextErea'
import OrderApi from '../../../Api/order'
import {stat} from 'fs'

export default function RejectForm({setOpenReject, order, setOrder}: any) {
  const {t} = useTranslation()
  const [data, setData] = useState<any>({})
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const handelSubmit = () => {
    console.log({
      ...order,
      status: 'canceled',
      cancel_data: {
        ...order.cancel_data,
        status: 'canceled',
        reason: data.reason,
        has_request_cancel: true,
      },
    })

    setOrder({
      ...order,
      status: 'canceled',
      cancel_data: {
        ...order.cancel_data,
        status: 'canceled',
        reason: data.reason,
      },
      has_request_cancel: true,
    })
    setOpenReject(false)

    OrderApi.canceledOrder(
      order.id,
      data,
      () => {
        setOrder({
          ...order,
          status: 'canceled',
          cancel_data: {
            ...order.cancel_data,
            status: 'canceled',
            reason: data.reason,
          },
          has_request_cancel: true,
        })
        setOpenReject(false)
      },
      setIsLoadingBtn
    )
  }
  return (
    <Stack gap='24px' p='32px 24px'>
      <Label fw='600' text='cancelOrder' />
      <Stack gap='8px'>
        <Label text='ReasonForCancellation' />
        <TextErea change={setData} value={data} name='reason' />
      </Stack>
      <RestSaveButton
        isLoading={isLoadingBtn}
        SaveText={t('accept')}
        isCancel
        cancelText={t('cancel')}
        submit={handelSubmit}
        cancel={() => {
          setOpenReject(!true)
        }}
      />
    </Stack>
  )
}
