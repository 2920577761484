import React, {useState, useEffect} from 'react'

interface Props {
  type: string
  value: any
  name: string
  change: any
  child?: string
  checked?: boolean
}

const InputColor: React.FC<Props> = ({type, value, name, change, child, checked}) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (child && (!value || !value[name])) {
      change({...value, [name]: {}})
    }
  }, [child, name, change, value])

  useEffect(() => {
    if (child) {
      setInputValue(value && value[name] && value[name][child] ? value[name][child] : '')
    } else {
      setInputValue(value && value[name] ? value[name] : '')
    }
  }, [child, name, value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setInputValue(newValue)

    if (child) {
      change({...value, [name]: {...value[name], [child]: newValue}})
    } else {
      change({...value, [name]: type === 'number' ? +newValue : newValue})
    }
  }

  return (
    <>
      <input
        style={{
          padding: '12.0625px 13px 12.5px',
          background: 'var(--bs-body-bg)',
          border: '1px solid var(--bs-gray-300)',
          borderRadius: '6.18px',
          width: '100%',
          color: 'var(--bs-text-dark)',
          height: '45px',
        }}
        value={inputValue}
        onChange={handleChange}
        type={type}
        disabled={checked || false}
      />
    </>
  )
}

export default InputColor
