import React from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {useTranslation} from 'react-i18next'
import Address from '../../../Api/SettingAddress'
import {format, parseISO} from 'date-fns'
import {Container, Stack} from '@mui/material'
import Logo from '../../../svg/Logo'

export default function InvoEn({invoiceData}: any) {
  const store = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()

  return (
    <Container maxWidth='lg'>
      <Stack width='100%' dir={store.isRtl === 0 ? 'ltr' : 'rtl'} className={`container invo `}>
        <div className='head'>
          <div className='flex-c d-block d-sm-none'>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <Logo />

              <p className='bold text-center fs-18-med'>
                {invoiceData.store_name && invoiceData.store_name[store.lang]}
              </p>
            </div>
          </div>
          <div className='flex flex-column flex-sm-row'>
            <div className='flex-c'>
              <h2 className='invoice text-center text-sm-start'>{t('invoice')}</h2>
              <p className='bold text-center text-sm-start fs-18-med'>
                {invoiceData.created_at}
                {/* {invoiceData.created_at && format(parseISO(invoiceData.created_at), 'yyyy-MM-dd')} */}
              </p>
              <p className='bold text-center text-sm-start fs-18-med'>
                {t('invoiceID ')}: {invoiceData && invoiceData.order_number}
              </p>
            </div>
            <div className='flex-c d-none d-sm-block'>
              <img className='invoice-logo' src={store.StoreImage} alt='invoice-logo' />
              <p className='bold text-center fs-18-med'>
                {' '}
                {invoiceData.name && invoiceData.name[store.lang]}
              </p>
            </div>
            <div className='flex-c'>
              <h2 className='text-center text-sm-start totalPricing'>${invoiceData.total}</h2>
              <p className='bold text-center text-sm-start fs-18-med'>{t('chargedToKonin2Pay')}</p>
            </div>
          </div>
        </div>
        <div className='sec'>
          <div className='flex align-items-flex-start'>
            <div className='flex-c'>
              <span className='ft-poppins-med text-2d'>{t('from')} :</span>
              <p className='ft-poppins-med text-2d'>Mostore</p>
              <p className='ft-poppins-med text-2d'>mo@gmail.com</p>
              <p className='ft-poppins-med text-2d'>+970592585555</p>
              <p className='ft-poppins-med text-2d max-w-420px'>
                Address : 2972 Westheimer Rd. Santa Ana
              </p>
            </div>
            <div className='flex-c'>
              <span className='ft-poppins-med text-2d'>{t('to')} :</span>
              <p className='ft-poppins-med text-2d'>{invoiceData.address_full_name}</p>
              <p className='ft-poppins-med text-2d'>{invoiceData.address_email}</p>
              <p className='ft-poppins-med text-2d'>{invoiceData.address_phone}</p>
              <p className='ft-poppins-med text-2d max-w-420px'>
                {t('shippingAddress')} :{' '}
                {invoiceData.store_address &&
                  invoiceData.store_address.address &&
                  invoiceData.store_address.address[store.lang]}
              </p>
            </div>
          </div>
        </div>
        <div className='table'>
          <table>
            <thead>
              <tr>
                <th className='text-web fs-18-med max-w-600px web-auto'>{t('yourOrder')}</th>
                <th className='text-web fs-18-med max-w-262px'>{t('quantity')}</th>
                <th className='text-web pl-20 fs-18-med'>{t('unitPrice')}</th>
                <th className='text-web pl-20 fs-18-med'>{t('subTotal')}</th>
                <th className='text-web text-right fs-18-med'>{t('total')}</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData.order_items &&
                invoiceData.order_items.map((e: any, i: any) => (
                  <tr key={i}>
                    <td className='text-left'>
                      <div className='flex justify-content-start align-items-start'>
                        <div className='flex-c'>
                          <span className='product-name ft-poppins-reg'>
                            {e.name && (e.name[store.lang] || e.name[store.defLang])}
                          </span>
                          <span className='product-name ft-poppins-reg'>
                            {t('sku')}: {e.product && e.product.sku}
                          </span>
                          <div className='pl-fr flex-wrap'>
                            {e.order_options &&
                              e.order_options.map((a: any, i: any) => (
                                <p className='ft-poppins-reg'>
                                  {a.option_name && a.option_name[store.lang]}:{' '}
                                  {a.option_value && a.option_value[store.lang]}
                                </p>
                              ))}{' '}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{e.bulk_discount_amount} (Bulk Discount)</td>
                    <td className='pl-20'>
                      $<span className='bold fs-18px'>{e.unit_price}</span>
                    </td>
                    <td className='pl-20'>
                      $<span className='bold fs-18px'>{e.sub_total}</span>
                    </td>
                    <td className='text-right'>
                      $<span className='bold fs-18px'>{e.total}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className='billing'>
          <div className='flex-c billingDetails'>
            <h3 className='bold ft-poppins-med'>{t('billingSummary')}</h3>
            <table className='summaryBorder'>
              <tr>
                <td className='text-gray fs-14px'>{t('subTotal')}</td>
                <td className='fs-15-med padding-left-60px'>${invoiceData.sub_total}</td>
              </tr>
              <tr>
                <td className='text-gray fs-14px'>{t('discount')}</td>
                <td className='fs-15-med padding-left-60px'>
                  -${invoiceData.total - invoiceData.sub_total}
                </td>
              </tr>
              <tr>
                <td className='text-gray fs-14px'>{t('coupon')}</td>
                <td className='fs-15-med padding-left-60px'>
                  ${invoiceData.coupon_discount_amount}
                </td>
              </tr>
              <tr>
                <td className='text-gray fs-14px'>{t('shipping')}</td>
                <td className='fs-15-med padding-left-60px'>${invoiceData.shipping_cost}</td>
              </tr>
              <tr>
                <td className='text-gray fs-14px'>{t('tax')}</td>
                <td className='fs-15-med padding-left-60px'>${invoiceData.tax}</td>
              </tr>
            </table>
            <div className='flex'>
              <p className='bold'>{t('grandTotal')}</p>
              <p className='bold padding-left-60px'>${invoiceData.total}</p>
            </div>
          </div>
        </div>
        <div className='footer'>
          <div className='flex-c'>
            <img
              className='rog-logo'
              style={{width: ' 150px'}}
              src='./media/avatars/invoice.png'
              alt='rog-logo'
            />
          </div>
        </div>
      </Stack>
    </Container>
  )
}
