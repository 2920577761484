import {Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import TitlePage from '../customTable/TitlePage'
import InputWithLabel from '../customTable/LabelWithText'
import Label from '../customTable/Label'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import ServiseFees from '../../Api/serviseFees'
import Loading from '../customTable/LoadingForm'

export default function InvoiceForm() {
  const {t} = useTranslation()
  const [data, setData] = useState<any>({})
  const bannerInfo = {
    title: t('servesFeesValue'),
    home: t('home'),
    linkHome: '/',
  }
  const store = useSelector((state: RootState) => state.vender)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    ServiseFees.getValue((data: any) => {
      console.log(
        {
          ...data.data,
          serviceFees: +data.data.service_fees.split(' ')[1],
          percentageOfTheBillValue: +data.data.percentage_of_bill_value.split('')[0],
          payableTaxPaid: +data.data.payable_tax_paid.split('')[0],
        },
        'data'
      )

      setData({
        ...data.data,
        serviceFees: +data.data.service_fees.split(' ')[1],
        percentageOfTheBillValue: +data.data.percentage_of_bill_value.split('')[0],
        payableTaxPaid: +data.data.payable_tax_paid.split('')[0],
      })
    }, setIsLoading)
  }, [])
  return (
    <Stack>
      {' '}
      <TitlePage info={bannerInfo} />
      <Stack height={'70vh'} width={'100%'} position={'relative'}>
        {isLoading ? (
          <Loading />
        ) : (
          <Stack mt='21px' bgcolor={'#fff'} p='32px' borderRadius={'8px'}>
            <Stack gap='32px'>
              <InputWithLabel
                width={{md: '400px'}}
                name='service_fees'
                data={data}
                setData={setData}
                disabled
                text='servesFees'
              />{' '}
              <InputWithLabel
                width={{md: '400px'}}
                name='percentage_of_bill_value'
                data={data}
                setData={setData}
                disabled
                text='percentageOfTheBillValue'
              />{' '}
              <InputWithLabel
                width={{md: '400px'}}
                name='payable_tax_paid'
                data={data}
                setData={setData}
                disabled
                text='payableTaxPaid'
              />
            </Stack>
            <Stack mt='54px' gap='32px'>
              <Label text='ServiceFeeCalculation' />
              <InputWithLabel
                width={{md: '400px'}}
                name='invoiceValue'
                type='number'
                data={data}
                setData={setData}
                text='invoiceValue'
              />{' '}
              <Stack width={{md: '400px'}} position={'relative'}>
                <InputWithLabel
                  width={{md: '400px'}}
                  name='servesFeesFixed'
                  type='number'
                  disabled
                  data={{...data, servesFeesFixed: (data.payableTaxPaid * data.invoiceValue) / 100}}
                  setData={setData}
                  text='servesFees'
                />{' '}
                <Stack
                  sx={{
                    left: store.isRtl !== 0 ? '0px' : 'auto',
                    right: store.isRtl === 0 ? '0px' : 'auto',
                    bottom: '0',
                    bgcolor: 'var(--orange-color)',
                    p: '13px',
                    color: '#fff',
                    borderRadius: store.isRtl === 0 ? '0 6.18px 6.18px 0' : '6.18px 0 0 6.18px',
                  }}
                  position={'absolute'}
                >
                  {' '}
                  ({data.payable_tax_paid}){' '}
                </Stack>
              </Stack>
              <Stack width={{md: '400px'}} position={'relative'}>
                <InputWithLabel
                  width={{md: '400px'}}
                  name='percentageOfTheBillValueFixed'
                  type='number'
                  disabled
                  data={{
                    ...data,
                    percentageOfTheBillValueFixed:
                      (data.percentageOfTheBillValue * data.invoiceValue) / 100,
                  }}
                  setData={setData}
                  text='percentageOfTheBillValue'
                />
                <Stack
                  sx={{
                    left: store.isRtl !== 0 ? '0px' : 'auto',
                    right: store.isRtl === 0 ? '0px' : 'auto',
                    bottom: '0',
                    bgcolor: 'var(--orange-color)',
                    p: '13px',
                    color: '#fff',
                    borderRadius: store.isRtl === 0 ? '0 6.18px 6.18px 0' : '6.18px 0 0 6.18px',
                  }}
                  position={'absolute'}
                >
                  {' '}
                  ({data.percentage_of_bill_value}){' '}
                </Stack>
              </Stack>
              <InputWithLabel
                width={{md: '400px'}}
                name='totalInvoiceValue'
                type='number'
                disabled
                data={{
                  ...data,
                  totalInvoiceValue:
                    data.invoiceValue -
                    (data.serviceFees +
                      (data.payableTaxPaid * data.invoiceValue) / 100 +
                      (data.percentageOfTheBillValue * data.invoiceValue) / 100),
                }}
                setData={setData}
                text='totalInvoiceValue'
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
