import {Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import Label from '../../customTable/Label'
import LabelWithValue from './LabelWithValue'
import LabelWithInput from './LabelWithInput'
import PackagesApi from '../../../Api/Packages'

export default function Invoice({id}: any) {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<any>({
    test: 'text',
    Features: [],
  })
  useEffect(() => {
    PackagesApi.getHistoryById(
      id,
      (res: any) => {
        setData(res.data)
      },
      setIsLoading
    )
  }, [])
  return (
    <Stack>
      <Stack minWidth={''} overflow={'auto'} gap='24px' p='32px'>
        <Stack gap='24px' p='32px' borderRadius={'8px'} border='1px solid #EFF0F6'>
          <Label fw='600' fs='18px' text='subscribedThrough' />
          <LabelWithValue label='name' value={data.vendor_name} />
          <LabelWithValue label='type' value='test' />
          <LabelWithValue label='email' value={data.vendor_email} />
          <LabelWithValue label='country' value={data.country_name} />
          <LabelWithValue label='city' value={data.city_name} />
        </Stack>
        <Stack
          gap='24px'
          flexWrap={'wrap'}
          direction={'row'}
          p='32px'
          borderRadius={'8px'}
          border='1px solid #EFF0F6'
        >
          <Stack width={{md: '50%', xs: '100%'}} gap='24px'>
            <Label fw='600' fs='18px' text='paymentDetails' />
            <LabelWithValue
              label='invoiceID'
              value={data.payment_details && data.payment_details.id}
            />
            <LabelWithValue
              label='subTotalAmount'
              value={data.payment_details && data.payment_details.subtotal}
            />
            <LabelWithValue
              label='totalAmount'
              value={data.payment_details && data.payment_details.total}
            />
            <LabelWithValue
              label='discountAmount'
              value={data.payment_details && data.payment_details.discount}
            />
            <LabelWithValue label='tax' value={data.payment_details && data.payment_details.fees} />
          </Stack>
          <Stack gap='24px'>
            <Label fw='600' fs='18px' text='subscriptionsDate' />
            <LabelWithValue label={'startDate'} value={data.start_at} />
            <LabelWithValue label='endDate' value={data.expire_at} />
          </Stack>
        </Stack>
        <Stack flexWrap={'wrap'} gap='32px' direction={'row'}>
          <LabelWithInput
            name={'status_text'}
            text={'subscriptionStatus'}
            data={data}
            setData={setData}
          />
          <LabelWithInput name={'plan_name'} text={'packageName'} data={data} setData={setData} />
        </Stack>
        <Stack gap='24px'>
          <Label fw='600' text='packageFeatures' />
          {data.features &&
            data.features.map((e: any, i: any) => (
              <Label text={`${i + 1}- ${e && e.feature_name}`} />
            ))}
        </Stack>
      </Stack>
    </Stack>
  )
}
