import * as React from 'react'
import Dialog from '@mui/material/Dialog'

import AddForm from './AddForm'
import ICuopon from '../../../interface/ICuopon'
import {useTranslation} from 'react-i18next'
import MainBtn from '../../customTable/MainBtn'

export default function AddCoupon({total, rows, setRows, setIsLoading, num, setNum}: any) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const {t} = useTranslation()

  return (
    <div>
      <MainBtn
        backageKey='COUPON_NUM'
        num={num}
        handelSubmit={handleClickOpen}
        text='createCoupon'
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={true}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)', // تعديلاتك الخاصة هنا
          },
          '& .MuiDialog-paperWidthSm': {
            maxWidth: '800px', // تعديلاتك الخاصة هنا
          },
        }}
      >
        <AddForm
          setLoading={setIsLoading}
          setRows={setRows}
          total={total}
          rows={rows}
          setOpen={setOpen}
          setNum={setNum}
        />
      </Dialog>
    </div>
  )
}
