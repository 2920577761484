import React from 'react'

export default function Location() {
  return (
    <svg width='12' height='17' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.07031 0.742188C2.84288 0.742188 0.226562 3.36012 0.226562 6.58909C0.226562 10.1439 2.66225 10.9815 3.94016 13.161C5.12984 15.1892 5.13081 16.7422 6.07031 16.7422C7.04459 16.7422 6.98309 15.2954 8.20047 13.161C9.4175 11.0263 11.9141 10.1439 11.9141 6.58909C11.9141 3.36012 9.29775 0.742188 6.07031 0.742188ZM6.07031 8.82975C4.86294 8.82975 3.88413 7.85044 3.88413 6.64256C3.88413 6.03828 4.12881 5.49166 4.52453 5.09569C4.92022 4.70003 5.46663 4.45503 6.07031 4.45503C7.27769 4.45503 8.25616 5.43434 8.25616 6.64256C8.25616 7.85044 7.27769 8.82975 6.07031 8.82975Z'
        fill='#4CAF50'
      />
      <path
        d='M6.06891 0.742188C5.90625 0.742188 5.74525 0.749156 5.58594 0.762187C8.58737 1.00794 10.9467 3.52284 10.9467 6.58909C10.9467 10.1439 8.45016 11.0263 7.23313 13.161C6.26894 14.8515 6.10691 16.1105 5.59716 16.5611C5.72784 16.6788 5.87925 16.7422 6.06891 16.7422C7.04319 16.7422 6.98169 15.2954 8.19906 13.161C9.41609 11.0263 11.9127 10.1439 11.9127 6.58909C11.9127 3.36012 9.29634 0.742188 6.06891 0.742188Z'
        fill='#43A047'
      />
      <path
        d='M4.52444 5.09566L0.682375 8.93982C0.401344 8.29941 0.226562 7.54185 0.226562 6.58904C0.226562 4.98788 0.869875 3.53729 1.91169 2.48145L4.52444 5.09566Z'
        fill='#F44336'
      />
      <path
        d='M7.63031 8.17361C8.01688 7.77908 8.25525 7.23867 8.25525 6.64258C8.25525 5.43436 7.2765 4.45508 6.06931 4.45508C5.46553 4.45508 4.91906 4.69998 4.52344 5.09583L8.39375 1.22339C9.77719 1.82386 10.8825 2.9443 11.4641 4.33826L7.63044 8.17405L7.63031 8.17361Z'
        fill='#42A5F5'
      />
      <path
        d='M8.39656 1.22339L8.07031 1.54983C9.36828 2.3157 10.3446 3.56898 10.7474 5.0582L11.467 4.33826C10.8853 2.94426 9.78 1.82386 8.39656 1.22339Z'
        fill='#2196F3'
      />
      <path
        d='M7.63266 8.17389L3.41309 12.3958C2.49566 11.2395 1.31856 10.3894 0.683594 8.93986L4.52566 5.0957C4.13003 5.49155 3.88525 6.03833 3.88525 6.64245C3.88525 7.8503 4.864 8.82958 6.07153 8.82958C6.68297 8.82958 7.23578 8.57836 7.63253 8.17345L7.63266 8.17389Z'
        fill='#FFC107'
      />
      <path
        d='M8.39325 1.22331L4.52291 5.09578L1.91016 2.48159C2.96978 1.40762 4.44169 0.742188 6.06881 0.742188C6.89488 0.742188 7.68106 0.913812 8.39325 1.22331Z'
        fill='#1E88E5'
      />
      <path
        d='M6.06894 0.742188C5.90631 0.742188 5.74531 0.749125 5.58603 0.762187L5.58594 0.762219C6.46196 0.833537 7.31035 1.10282 8.06716 1.54975L8.39341 1.22331C7.68119 0.913812 6.895 0.742188 6.06894 0.742188Z'
        fill='#1976D2'
      />
    </svg>
  )
}
