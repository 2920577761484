import {MenuItem, Select, Stack} from '@mui/material'
import React from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowSelect from '../../svg/ArrowSelect'

export default function NewSelectState({
  option,
  onchange,
  changeState,
  setChangeState,
  state,
  check,
}: any) {
  const handleChange = (event: any) => {
    setChangeState({value: event.target.value})
    onchange(event.target.value)
    // values[`${name}`] = +event.target.value
  }

  return (
    <Stack height={'100%'} dir='ltr'>
      <Select
        inputProps={{
          sx: {
            paddingRight: '0px !important',
            padding: '0px !important',
            border: 'none !important',
          },
        }}
        disabled={check}
        sx={{
          textAlign: 'center',
          padding: state ? '5px 6px' : '12.0625px 13px 12.5px',
          background:
            state && changeState.value === 'pending'
              ? '#FFC700'
              : state && changeState.value === 'preparing'
              ? '#FFAB10'
              : state && changeState.value === 'in_progress'
              ? '#009EF7'
              : state && changeState.value === 'refunded'
              ? '#f8f5ff7a'
              : state && changeState.value === 'not_active'
              ? '#5959597a'
              : state && (changeState.value === 'completed' || changeState.value === 'active')
              ? '#50CD89'
              : state && (changeState.value === 'canceled' || changeState.value === 'blocked')
              ? '#EF305E'
              : '#D81923',
          border: state ? 'none' : 'auto',
          color: '#fff',
          borderRadius: '8.18px',
          height: '100%',
          width: '100%',
          '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
            {
              paddingRight: '0px !important',
            },
          '& .css-1k0sxlf': {
            padding: state ? '5px 6px !important' : '12.0625px 13px 12.5px !important',
          },
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
          },
          '& .MuiSelect-icon': {
            right: '10px', // لإزالة المسافة الافتراضية من اليمين
            color: '#0000',
          },
        }}
        value={changeState.value}
        onChange={handleChange}
      >
        {option.map((e: any) => (
          <MenuItem
            style={{
              // padding: '5px',
              background: 'var(--bs-app-footer-bg-color)',
              color: 'var(--bs-body-color)',
            }}
            key={e}
            value={e.value}
          >
            <Stack alignItems={'center'} justifyContent={'center'} gap='14px' direction={'row'}>
              {e.label}
              <ArrowSelect />
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  )
}
