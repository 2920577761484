import {Stack} from '@mui/material'
import React, {useState} from 'react'
import MainBtn from '../../customTable/MainBtn'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import InputWithLabel from '../../customTable/LabelWithText'
import StaticInputFile from '../../customTable/StaticInputFile'
import Label from '../../customTable/Label'
import Status from '../../customTable/Status'
import UploadImg from '../../UploadImg'
import DeleteIcon from '../../../svg/DeleteIcon'
import DeletedMsg from '../../customTable/DeleteMsg'
import Delete from '../../../svg/Delete'
import {useNavigate} from 'react-router-dom'
import PupUpImage from '../../customTable/PupUpImage'
import AppMaker from '../../../Api/AppMaker'
import SimpleLoading from '../../customTable/SimpleLoading'

export default function IHaveAftterSubmit({IDontHave, android, data, setData}: any) {
  const store = useSelector((state: RootState) => state.vender)
  const [base64Image, setBase64Image] = useState('')
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [status, setStatus] = useState('accept')
  const [open, setOpen] = useState(false)
  const naviagete = useNavigate()
  const [isCancel, setIsCancel] = useState(false)
  const deleteApp = () => {
    if (isCancel) {
      AppMaker.cancel({}, () => {}, setDeleteLoading, android)
      return
    } else {
      AppMaker.delete(
        android,
        () => {
          setData({...data, is_request_remove: true})
        },
        setDeleteLoading
      )
    }
  }
  return (
    <Stack position={'relative'}>
      {data.is_request_remove && (
        <>
          <Stack p='8px' border='1px dashed #ff8d25' bgcolor={'#f9d6c2'}>
            <Label fs='12px' color={'#ff0000'} text={'pendingDeleteMsg'} />
          </Stack>
        </>
      )}
      {data.reject_reason && (
        <>
          <Stack p='8px' border='1px dashed red' bgcolor={'#fbe8e9'}>
            <Label fs='12px' color={'#ff0e00'} text={data?.reject_reason} />
          </Stack>
        </>
      )}
      <Stack
        sx={{
          left: store.isRtl !== 0 ? '0px' : 'auto',
          right: store.isRtl === 0 ? '0px' : 'auto',
          top: '-72px',
        }}
        position={'absolute'}
      >
        <MainBtn
          handelSubmit={() => {
            naviagete(`/helpdesk/ticket/in/${data.ticket_add_id}`)
          }}
          width='auto'
          text='sendMassage'
        />
      </Stack>
      <Stack gap='24px' p='25px' borderRadius={'8px'} bgcolor={'#fff'} mt='0'>
        <InputWithLabel
          width={{md: 'calc(50% - 16px)', xs: '100%'}}
          name={!IDontHave ? 'duns_num' : 'working_email'}
          data={data}
          disabled
          setData={setData}
          text={!IDontHave ? 'DUNumber' : 'workMail'}
        />{' '}
        {IDontHave && (
          <Stack direction={'row'} gap='32px'>
            <Stack width={{md: 'calc(50% - 16px)', xs: '100%'}}>
              <PupUpImage image={data.commercial_file}>
                <StaticInputFile text='commercialRegister' />
              </PupUpImage>
            </Stack>
            <Stack width={{md: 'calc(50% - 16px)', xs: '100%'}}>
              <PupUpImage image={data.passport}>
                <StaticInputFile text='pasportImg' />
              </PupUpImage>
            </Stack>
          </Stack>
        )}
        <Stack width={'100%'} height={'1px'} bgcolor={'#C5C5D8'}></Stack>
        {store.permissions &&
          store.permissions.includes('app_maker_cancel') &&
          data.status === 'pending' && (
            <Stack
              p='17px 24px'
              bgcolor='rgba(216, 25, 35, 0.10)'
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              borderRadius={'8px'}
              border='1px solid var(--2, #D8D8D8)'
            >
              <Label fs='16px' fw='600' text='cancelReqMsg' />
              {
                <MainBtn
                  isLoading={deleteLoading}
                  handelSubmit={() => {
                    setIsCancel(true)
                    setOpen(true)
                  }}
                  without
                  width='200px'
                  text='cancelReq'
                />
              }
            </Stack>
          )}
        <Stack alignItems={'center'} direction={'row'} gap='25px'>
          <Label text={android ? 'androidApp' : 'isoApp'} />
          {store.permissions &&
            store.permissions.includes('app_maker_delete') &&
            data.status === 'complete' && (
              <Stack
                onClick={() => {
                  setIsCancel(!true)

                  setOpen(true)
                }}
                sx={{cursor: 'pointer'}}
              >
                {deleteLoading ? <SimpleLoading /> : <Delete />}
              </Stack>
            )}
        </Stack>
        <Stack alignItems={'center'} direction='row' gap='5px'>
          {data.status && (
            <>
              <Label text='applicationSubmissionStatus' /> : <Status text={data.status} />
            </>
          )}
        </Stack>
        <Stack gap='15px'>
          <Label text='applicationIcon' />
          <PupUpImage image={data.app_icon}>
            <UploadImg
              name='app_icon'
              data={data}
              setData={setData}
              base64Image={base64Image}
              setBase64Image={setBase64Image}
              disabled
            />
          </PupUpImage>
        </Stack>
        <Stack flexWrap={'wrap'} gap='32px' direction={'row'}>
          <InputWithLabel
            width={{md: 'calc(50% - 16px)', xs: '100%'}}
            name={'account_mail'}
            data={data}
            setData={setData}
            text='appleEnterpriseAccount'
            disabled
          />{' '}
          {/* <InputWithLabel
            width={{md: 'calc(50% - 16px)', xs: '100%'}}
            name={'password'}
            data={data}
            setData={setData}
            text='password'
            disabled
          /> */}
        </Stack>
      </Stack>
      <DeletedMsg deleteMsg={'deleteAppMsg'} onClick={deleteApp} open={open} setOpen={setOpen} />
    </Stack>
  )
}
