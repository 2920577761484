import {Radio, Stack} from '@mui/material'
import React from 'react'
import Label from './Label'
import {useTranslation} from 'react-i18next'

export default function CheckInputWithLabel({text, check, value, onClick}: any) {
  return (
    <Stack
      alignItems={'center'}
      gap='1px'
      direction={'row'}
      sx={{cursor: 'pointer'}}
      onClick={onClick}
    >
      <Radio
        checked={check === value ? true : false}
        sx={{
          color: 'var(--orange-color)',
          '&.Mui-checked': {
            color: 'var(--orange-color)',
          },
        }}
      />
      <Stack mt='3px'>
        <Label text={text} />
      </Stack>
    </Stack>
  )
}
