import React from 'react'

export default function Canceled({color}: any) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.11328 4.95996L7.99994 8.36662L13.8466 4.97994'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8 14.406V8.35938'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.4071 6.11301V9.88637C14.4071 9.9197 14.4071 9.94633 14.4004 9.97967C13.9337 9.573 13.3337 9.33302 12.6671 9.33302C12.0404 9.33302 11.4604 9.55302 11.0004 9.91969C10.3871 10.4064 10.0004 11.1597 10.0004 11.9997C10.0004 12.4997 10.1404 12.973 10.3871 13.373C10.4471 13.4797 10.5204 13.5797 10.6004 13.673L9.38041 14.3464C8.62041 14.773 7.3804 14.773 6.6204 14.3464L3.0604 12.373C2.25374 11.9264 1.59375 10.8064 1.59375 9.88637V6.11301C1.59375 5.19301 2.25374 4.07302 3.0604 3.62636L6.6204 1.65301C7.3804 1.22634 8.62041 1.22634 9.38041 1.65301L12.9404 3.62636C13.7471 4.07302 14.4071 5.19301 14.4071 6.11301Z'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.3333 11.9997C15.3333 12.4997 15.1933 12.973 14.9467 13.373C14.8067 13.613 14.6267 13.8263 14.42 13.9997C13.9533 14.4197 13.34 14.6663 12.6667 14.6663C11.6933 14.6663 10.8467 14.1463 10.3867 13.373C10.14 12.973 10 12.4997 10 11.9997C10 11.1597 10.3867 10.4063 11 9.91968C11.46 9.55301 12.04 9.33301 12.6667 9.33301C14.14 9.33301 15.3333 10.5263 15.3333 11.9997Z'
        stroke={color || '#A8A9AD'}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M13.3782 12.6928L11.9648 11.2861'
        stroke={color || '#A8A9AD'}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M13.3665 11.3066L11.9531 12.7133'
        stroke={color || '#A8A9AD'}
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
