import * as Yup from 'yup'
import SetLang from '../utils/SetLangs'
// const languages = ['en']
let languages = SetLang() || ['en']

export const GeneralSettingValidation = (languages: any) => {
  return Yup.object().shape({
    title: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`titleErr`),
        }
      }, {})
    ),

    email: Yup.string().required('emailErr'),
  })
}
export const AddressSettingValidation = (languages: any) => {
  return Yup.object().shape({
    street_address: Yup.object().shape(
      languages.reduce((schema: any, lang: any) => {
        return {
          ...schema,
          [lang]: Yup.string().required(`addressErr`),
        }
      }, {})
    ),

    postal_code: Yup.string().required('postalCodeErr'),
  })
}

export const OrderSchema = Yup.object().shape({
  refund_duration: Yup.number().when('is_refund_order', {
    is: 1,
    then: Yup.number().required('refundErr'),
    otherwise: Yup.number().nullable(),
  }),
})
export const EditTaxSchema = Yup.object().shape({
  tax: Yup.string().required('taxErr'),
})
export const TaxSchema = Yup.object().shape({
  vendor_type: Yup.string().required('taxErr'), // Assuming this field is part of the schema
  tax: Yup.string().required('taxErr'),
  commercial_file: Yup.string().required('taxErr'),
  id_face_one: Yup.string().when('vendor_type', {
    is: 'citizen',
    then: Yup.string().required('taxErr'),
    otherwise: Yup.string().nullable(),
  }),
  id_face_two: Yup.string().when('vendor_type', {
    is: 'citizen',
    then: Yup.string().required('taxErr'),
    otherwise: Yup.string().nullable(),
  }),
  passport: Yup.string().when('vendor_type', {
    is: (val: string) => val !== 'citizen', // أي قيمة غير 'citizen'
    then: Yup.string().required('taxErr'),
    otherwise: Yup.string().nullable(),
  }),
})
