import {Typography} from '@mui/material'
import React from 'react'

export default function RedStart() {
  return (
    <Typography
      sx={{
        fontSize: '14px',
        color: 'red',
        fontWeight: 600,
        lineHeight: '16px',
        textTransform: 'capitalize',
      }}
    >
      *
    </Typography>
  )
}
