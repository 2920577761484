import {Tooltip} from '@mui/material'
import React from 'react'

export default function NameText({text}: any) {
  return (
    <Tooltip title={text}>
      <span className='  fs-7 fw-semibold'>
        {' '}
        {text && text.slice(0, 8)}
        {text && text.length > 8 && '...'} {!text && '-'}
      </span>
    </Tooltip>
  )
}
