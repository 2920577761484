import {useEffect, useState} from 'react'
import Options from '../../../Api/options'
import {useNavigate} from 'react-router-dom'
import {OptionColorschema, Textschema, Imageschema} from '../../../yup/AddOptionShcema'
import IOption from '../../../interface/IOption'
import IImageOption from '../../../interface/IOptionImage'
import {Try} from '@mui/icons-material'
import {CircularProgress, Stack} from '@mui/material'
import {toast} from 'react-toastify'
import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import ValidationFun from '../../../yup/ValidationFun'
interface ActionsButtonProps {
  setIsDelete?: React.Dispatch<React.SetStateAction<boolean>>
  option?: any
  id: string | undefined
  setError?: any
  formik?: any
  setActiveAdd?: any
}

const AddActionBtns = ({option, setErrors, id, setError, formik, setActiveAdd}: any) => {
  const store = useSelector((state: RootState) => state.vender)

  const [languages, setlanguages] = useState(['en'])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  const handelAdd = () => {
    if (option.option_code) {
      ValidationFun(option, OptionColorschema(languages), setErrors, () => {
        Options.addOption(
          option,
          id,
          (res: any) => {
            setIsLoading(!true)

            formik.setFieldValue('optionDetails', [
              ...formik.values.optionDetails.slice(0, formik.values.optionDetails.length - 1),
              {...res},
            ])
            setError('')
            setActiveAdd(false)
          },
          setIsLoading
        )
      })
    } else if (
      !option.option_code &&
      option.option_image &&
      option.option_image !== 'https://vendor.api.koninstore.com/storage/store/options'
    ) {
      console.log(
        option,
        !option.option_code,
        option.option_image !== 'https://vendor.api.koninstore.com/storage/store/options',
        'option11'
      )

      ValidationFun(option, Imageschema(languages), setErrors, (data: any) => {
        Options.addOption(
          data,
          id,
          (res: any) => {
            console.log('pop')

            formik.setFieldValue('optionDetails', [
              ...formik.values.optionDetails.slice(0, formik.values.optionDetails.length - 1),
              {...res},
            ])

            setActiveAdd(false)
          },
          setIsLoading
        )
      })
    } else {
      console.log(option, 'option')

      ValidationFun(option, Textschema(languages), setErrors, (data: any) => {
        Options.addOption(
          data,
          id,
          (res: any) => {
            formik.setFieldValue('optionDetails', [
              ...formik.values.optionDetails.slice(0, formik.values.optionDetails.length - 1),
              {
                ...res,
              },
            ])
            setError('')

            setActiveAdd(false)
          },
          setIsLoading
        )
      })

      // Textschema(languages)
      //   .validate(option)
      //   .then((valid: any) => {
      //     Options.addOption(
      //       valid,
      //       id,
      //       (res: any) => {
      //         formik.setFieldValue('optionDetails', [
      //           ...formik.values.optionDetails.slice(0, formik.values.optionDetails.length - 1),
      //           {
      //             ...res.data,
      //             option_image: 'https://vendor.api.koninstore.com/storage/store/options',
      //           },
      //         ])
      //         setError('')

      //         setActiveAdd(false)
      //       },
      //       setIsLoading
      //     )
      //   })
      //   .catch((error: any) => {
      //     setError(error.message)
      //   })
    }
  }

  return (
    <Stack mt='10px'>
      {isLoading ? (
        <CircularProgress size={26} />
      ) : (
        <button onClick={handelAdd} type='button' className='btn btn-icon btn-success btn-sm me-1'>
          <i className='bi bi-check2 fs-7'></i>
        </button>
      )}

      {/* X_X */}
    </Stack>
  )
}
export default AddActionBtns
