import React, {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState, useDispatch, withToastr} from '../../../store'
import {FetchStatus} from '../../../store/types'
import {ProductsAction} from '../../../store/vender/products/thunk'
import {ArrangementOrder} from '../../../types'

import {PaginationSection} from '../../customTable/pagnationSection'
import {EnhancedTableHead} from './EnhancedTableHead'
import {RowItem} from './Row'
import {TooBar} from './TooBar'

import {CircularProgress, Stack} from '@mui/material'
import Product from '../../../Api/product'
import TableHead from './TablHead'
import {useParams} from 'react-router-dom'
import NoDataTable from '../../customTable/NoDataTable'

const ReViewProductTable = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [search, setSearch] = useState('')
  const [timerId, setTimerId] = useState<number | null>(null)
  const [total, setTotal] = useState<number>(0)
  const [rows, setRows] = useState<any[]>([])
  const {id} = useParams()
  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = e.target.value
      setSearch(e.target.value)
      if (timerId) {
        clearTimeout(timerId)
      }

      setTimerId(
        window.setTimeout(() => {
          getCoupons(newSearchValue, rowsPerPage, 1)
          setPage(1)
        }, 500)
      )
    },
    [timerId]
  )

  const getCoupons = async (searchText: string, rowsPerPage: number, page: number) => {
    Product.getReview(
      id,
      searchText,
      rowsPerPage,
      page,
      (res: any) => {
        setTotal(res.pages.total)

        setRows(res.data)
      },
      setIsLoading
    )
  }
  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setPage(1)
  }
  useEffect(() => {
    getCoupons(search, rowsPerPage, page)
  }, [rowsPerPage, page])

  return (
    <Stack mt='31px' className={`card `}>
      <TooBar handleSearch={handleSearch} search={search} />
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <TableHead />
            {/*  id  status is pending  will  show  spinner  */}

            {/* help-products-cata */}
            {!isLoading ? (
              <tbody className='mb-4'>
                {rows.length === 0 ? (
                  <NoDataTable />
                ) : (
                  rows.map((e, i) => (
                    <RowItem setRows={setRows} rows={rows} item={e} i={i} key={i} />
                  ))
                )}
              </tbody>
            ) : (
              <CircularProgress />
            )}
          </table>
          <PaginationSection
            rowsPerPageOptions={[5, 20, 30, 40, 50, 60]}
            count={total || 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </Stack>
  )
}

export default ReViewProductTable
