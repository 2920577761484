import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import {TransitionProps} from '@mui/material/transitions'
import Form from './Form'
import {useTranslation} from 'react-i18next'
import MainBtn from '../customTable/MainBtn'
import {Stack} from '@mui/material'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function Create({
  rows,
  setRows,
  setAdded,
  setNum,
  num,
}: {
  rows: any
  setRows: any
  setAdded: any
  setNum?: any
  num?: any
}) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const currentUrl = window.location.href

  return (
    <div>
      <MainBtn
        num={num}
        backageKey='OPTION_CATALOG_NUM'
        width='auto'
        type='button'
        handelSubmit={handleClickOpen}
        text='create'
      />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)', // تعديلاتك الخاصة هنا
          },
          '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px', // تعديلاتك الخاصة هنا
          },
        }}
      >
        <Form
          setNum={setNum}
          setAdded={setAdded}
          open={open}
          rows={rows}
          setRows={setRows}
          setOpen={setOpen}
        />
      </Dialog>
    </div>
  )
}
