import {Stack} from '@mui/material'
import React, {useState} from 'react'
import PopUp from './PopUp'

export default function PupUpImage({children, image, video}: any) {
  const [open, setOpen] = useState(false)
  const openPopUp = () => {
    setOpen(true)
  }
  return (
    <Stack>
      <Stack sx={{cursor: 'pointer'}} onClick={openPopUp}>
        {children}
      </Stack>
      <PopUp setOpen={setOpen} open={open}>
        {video ? <video controls src={image}></video> : <img src={image} alt='' />}
      </PopUp>
    </Stack>
  )
}
