import React from 'react'
import ErrorMsg from './NewMsgErr'

export default function OldInput({
  isEdit,
  formik,
  name,
  errors,
  child,
  style,
  min,
  type,
  onBlur,
}: any) {
  console.log(name, errors && errors[`price`], 111)

  return (
    <>
      {' '}
      <input
        style={style}
        min={min}
        className='form-control mb-2 form-control-lg'
        disabled={isEdit}
        type={type || 'text'}
        {...formik?.getFieldProps(child ? `${name}[${child}]` : `${name}`)}
        onBlur={() => {
          onBlur()
        }}
      />
      {errors && !child && errors[name] && typeof errors[name] === 'string' ? (
        <ErrorMsg msgErr={errors[name]} />
      ) : errors &&
        child &&
        errors[`${name}.${child}`] &&
        typeof errors[`${name}.${child}`] === 'string' ? (
        <ErrorMsg child={child} msgErr={errors[`${name}.${child}`]} />
      ) : (
        ''
      )}
    </>
  )
}
