import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../../customTable/Label'
import OrderInfo from './OrderInfo'
import InputWithLabel from '../../customTable/LabelWithText'
import RestSaveButton from '../../customTable/RestSaveBtns'
import {useTranslation} from 'react-i18next'
import OrderApi from '../../../Api/order'

export default function AcceptForm({order, isItem, setOpenAccept, setOrder}: any) {
  const {t} = useTranslation()
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const [data, setData] = useState<any>({})
  const handelSubmit = () => {
    OrderApi.payCanceledOrder(
      order.id,
      data,
      () => {
        setOpenAccept(false)

        setOrder({
          ...order,
          cancel_data: {
            ...order.cancel_data,
            status: 'accepted',
            refund_amount: data.amount_refund,
          },
        })
      },
      setIsLoadingBtn
    )
  }
  return (
    <Stack gap='24px' p='32px 24px'>
      <Label fw='600' text='refund' />

      <OrderInfo mt='0' order={order} />
      <Stack borderBottom='1px dashed #B5B5C3'></Stack>
      <InputWithLabel
        type='number'
        name='amount_refund'
        setData={setData}
        text='refundAmount'
        data={data}
      />
      <RestSaveButton
        isLoading={isLoadingBtn}
        SaveText={t('accept')}
        isCancel
        cancelText={t('cancel')}
        submit={handelSubmit}
        cancel={() => {
          setOpenAccept(false)
        }}
      />
    </Stack>
  )
}
