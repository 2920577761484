import IBanner from '../interface/IBanner'
import ApisMethods from './apisMethods'

const Domain = {
  //*
  get: (callBack: any, setIsLoading: any, msgErr: any) => {
    ApisMethods().get(`/vendor/domain`, callBack, setIsLoading, msgErr)
  },
  putStatus: (id: number | undefined, status: number, callBack: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/banners/status/${id}`, {status: status}, callBack, setIsLoading)
  },

  add: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/domain`, data, callback, setIsLoading)
  },
  requestDNS: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/domain/request-dns`, data, callback, setIsLoading)
  },

  delete: (callback: any, setIsLoading: any) => {
    ApisMethods().destroy(`/vendor/domain`, callback, setIsLoading)
  },
}
export default Domain
