import React from 'react'

export default function Shipped({color}: any) {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.00244 9.33301H8.66911C9.40244 9.33301 10.0024 8.73301 10.0024 7.99967V1.33301H4.00244C3.00244 1.33301 2.12911 1.88633 1.67578 2.69967'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1.33203 11.333C1.33203 12.4397 2.22536 13.333 3.33203 13.333H3.9987C3.9987 12.5997 4.5987 11.9997 5.33203 11.9997C6.06536 11.9997 6.66536 12.5997 6.66536 13.333H9.33203C9.33203 12.5997 9.93203 11.9997 10.6654 11.9997C11.3987 11.9997 11.9987 12.5997 11.9987 13.333H12.6654C13.772 13.333 14.6654 12.4397 14.6654 11.333V9.33301H12.6654C12.2987 9.33301 11.9987 9.03301 11.9987 8.66634V6.66634C11.9987 6.29967 12.2987 5.99967 12.6654 5.99967H13.5253L12.3854 4.00635C12.1454 3.59301 11.7054 3.33301 11.2254 3.33301H9.9987V7.99967C9.9987 8.73301 9.3987 9.33301 8.66536 9.33301H7.9987'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.33333 14.6667C6.06971 14.6667 6.66667 14.0697 6.66667 13.3333C6.66667 12.597 6.06971 12 5.33333 12C4.59695 12 4 12.597 4 13.3333C4 14.0697 4.59695 14.6667 5.33333 14.6667Z'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.6654 14.6667C11.4017 14.6667 11.9987 14.0697 11.9987 13.3333C11.9987 12.597 11.4017 12 10.6654 12C9.92898 12 9.33203 12.597 9.33203 13.3333C9.33203 14.0697 9.92898 14.6667 10.6654 14.6667Z'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14.6667 8V9.33333H12.6667C12.3 9.33333 12 9.03333 12 8.66667V6.66667C12 6.3 12.3 6 12.6667 6H13.5267L14.6667 8Z'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1.33203 5.33301H5.33203'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1.33203 7.33301H3.9987'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M1.33203 9.33301H2.66536'
        stroke={color || '#A8A9AD'}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
