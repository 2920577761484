import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../../customTable/Label'
import OrderInfo from './OrderInfo'
import InputWithLabel from '../../customTable/LabelWithText'
import RestSaveButton from '../../customTable/RestSaveBtns'
import {useTranslation} from 'react-i18next'
import TextErea from '../../customTable/TextErea'
import OrderApi from '../../../Api/order'

export default function RejectForm({isItem, setOpenReject, order, setOrder}: any) {
  const {t} = useTranslation()
  const [data, setData] = useState<any>({})
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const handelSubmit = () => {
    if (isItem) {
      OrderApi.rejectItem(
        order.id,
        data,
        () => {
          setOpenReject(false)
          setOrder({
            ...order,
            refund_data: {
              ...order.refund_data,
              status: 'rejected',
              reject_reason: data.reject_reason,
            },
          })
        },
        setIsLoadingBtn
      )
    } else {
      OrderApi.rejectOrder(
        order.id,
        data,
        () => {
          setOrder({
            ...order,
            refund_data: {
              ...order.refund_data,
              status: 'rejected',
              reject_reason: data.reject_reason,
            },
          })
          setOpenReject(false)
        },
        setIsLoadingBtn
      )
    }
  }
  return (
    <Stack gap='24px' p='32px 24px'>
      <Label fw='600' text='rejectRefund' />
      <Stack gap='8px'>
        <Label text='reasonForRejectingRefund' />
        <TextErea change={setData} value={data} name='reject_reason' />
      </Stack>
      <RestSaveButton
        isLoading={isLoadingBtn}
        SaveText={t('accept')}
        isCancel
        cancelText={t('cancel')}
        submit={handelSubmit}
        cancel={() => {
          setOpenReject(!true)
        }}
      />
    </Stack>
  )
}
