import {Stack} from '@mui/material'
import React from 'react'
import EmptyTax from './EmptyTax'
import FormTax from './FormTax'
import EmptyFormTax from './EmptyFormTax'
import TaxApi from '../../../Api/SettingTax'
import Loading from '../../../componet/loading'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Error403 from '../../errors/components/Error403'

export default function Tax() {
  const [data, setData] = React.useState<any>(null)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (Number(localStorage.getItem('tax')) === 1) {
      TaxApi.get((res: any) => {
        setData({...res.data})
      }, setIsLoading)
    }
  }, [])
  const store = useSelector((state: RootState) => state.vender)
  return (
    <>
      {store.permissions && store.permissions.includes('vendor_tax') ? (
        <Stack width={'100%'} height={'60vh'} position={'relative'}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {!data ? (
                <EmptyTax setData={setData} data={data} />
              ) : (
                <Stack
                  p='24px'
                  borderRadius={'8px'}
                  bgcolor={'var(--bs-app-header-base-bg-color)'}
                  width={'100%'}
                >
                  <EmptyFormTax setData={setData} data={data} isEdit />
                </Stack>
              )}
            </>
          )}
        </Stack>
      ) : (
        <Error403 />
      )}
    </>
  )
}
