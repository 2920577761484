import {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import Img from '../../../componet/imag'
import {RootState, useDispatch} from '../../../store'
import {Language} from '../../../types'
import {useTranslation} from 'react-i18next'
import {Avatar, Stack} from '@mui/material'
import MainBtn from '../../customTable/MainBtn'
import Catalog from '../../../Api/catalog'
import NewSubCategoryTable from './subCatetogryTable'
import PupUpImage from '../../customTable/PupUpImage'
import TitlePage from '../../customTable/TitlePage'

interface ICategoryDetails {
  name: {
    [key in Language]: string
  }
  description: {
    [key in Language]: string
  }
  image: string
}

interface CardCatogoryProps {
  className?: string
  onClick?: () => void
  categoryDetails: ICategoryDetails
}

const CardCatogory = ({className, onClick, categoryDetails}: CardCatogoryProps) => {
  const lang = useLang()
  const {id} = useParams()
  const navigate = useNavigate()
  const {t} = useTranslation()

  const handelEditCatogory = () => {
    navigate(`edit-category/${id}`)
  }
  console.log(categoryDetails, 'categoryDetails')

  return (
    <Stack>
      <div className={`card  ' ${className} border-0`}>
        <div style={{padding: '0'}} className='card-header border-0 '>
          <div
            style={{padding: '19px 0 0px 0', margin: 'auto'}}
            className='card-title d-flex flex-column border-0'
          >
            <span
              style={{fontSize: '19px', textAlign: 'center', width: '100%'}}
              className=' fw-bold text-dark me-1 text-black  fs-2 fw-bold me-1  text-hover-primary fs-2 fw-bold me-1 '
            >
              {categoryDetails?.name &&
                typeof categoryDetails?.name === 'object' &&
                categoryDetails?.name[lang]}
            </span>

            <Stack
              alignItems={'center'}
              style={{padding: '0', margin: 'auto'}}
              className='card-body d-flex align-items-center  w-90'
            >
              <Stack
                alignItems={'center'}

                // className='me-7 mb-4'
              >
                <PupUpImage image={categoryDetails?.image || ''}>
                  <Stack
                    alignItems={'center'}
                    style={{width: '150px', height: '150px', marginTop: '19px'}}

                    // className='image-input-wrapper w-150px h-150px my-2 '
                  >
                    {' '}
                    <Avatar
                      sx={{width: 150, height: 150, borderRadius: '8px'}}
                      src={categoryDetails?.image || ''}
                      alt={
                        (categoryDetails?.name &&
                          typeof categoryDetails?.name === 'object' &&
                          categoryDetails?.name[lang]) ||
                        ''
                      }
                    />
                  </Stack>
                </PupUpImage>
              </Stack>
            </Stack>
            {/* <div
            dangerouslySetInnerHTML={{
              __html:
                (categoryDetails?.description &&
                  typeof categoryDetails?.description === 'object' &&
                  categoryDetails?.description[lang]) ||
                '',
            }}
          ></div> */}
          </div>
        </div>
        <div className='card-footer d-flex justify-content-center py-6 px-9'></div>
      </div>
    </Stack>
  )
}

export const SubCatogory = () => {
  const {id} = useParams<{id: string}>()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const {categoryDetails} = useSelector((state: RootState) => state.category)
  const [dataCard, setDataCard] = useState({})
  const getDataCard = () => {
    Catalog.getById(
      id,
      (res: any) => {
        console.log(res)

        setDataCard(res.data)
      },
      setIsLoading
    )
  }
  useEffect(() => {
    getDataCard()
  }, [])
  const navigate = useNavigate()
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Stack>
      <Stack
        width={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
        direction={'row'}
      >
        <TitlePage
          info={{
            title: t('subCategories'),
            home: t('categories'),
            linkHome: '/catalog/list',
          }}
        />
        {store.permissions.includes(`categories_update`) && (
          <MainBtn
            width='auto'
            handelSubmit={() => navigate(`/catalog/edit-category/${id}`)}
            text={'editCategory'}
          />
        )}
      </Stack>
      <div style={{marginTop: '6px'}} className='row g-6 g-xl-9'>
        <div
          className='col-md-12 col-xl-4 '
          style={{
            maxHeight: '451px',
          }}
        >
          {' '}
          <Stack height={'100%'}>
            <Stack top='100px' position={'sticky'}>
              <CardCatogory className='' categoryDetails={dataCard as ICategoryDetails} />{' '}
            </Stack>
          </Stack>
        </div>

        <div className='col-md-12 col-xl-8 '>
          <NewSubCategoryTable />
        </div>
      </div>
    </Stack>
  )
}
