import * as yup from 'yup'

import SetLang from '../utils/SetLangs'
// const languages = ['en', 'ar']
export const OptionValueschema = (languages: any) => {
  const OptionValueschem = yup.object().shape({
    isImage: yup.number().required(),
    option_image: yup.string().when('isImage', {
      is: 1,
      then: yup.string().required('imageErr'),
      otherwise: yup.string().nullable(),
    }),
    option_value: yup.object().when('isImage', {
      is: 1,
      then: yup.object().shape(
        languages.reduce((schema: any, lang: any) => {
          return {
            ...schema,
            [lang]: yup.string().required(`optionValueErr`),
          }
        }, {})
      ),
      otherwise: yup.object().shape(
        languages.reduce((schema: any, lang: any) => {
          return {
            ...schema,
            [lang]: yup.string(),
          }
        }, {})
      ),
    }),
    option_value_id: yup.number().when('isImage', {
      is: 0,
      then: yup.number().required('optionValueErr').positive('optionValueErr'),
      otherwise: yup.number().nullable(),
    }),
    sort: yup.number().required('sortErr').min(0, 'minmamSortErr'),
    price_value: yup.number().required('priceValueErr'),
  })
  return OptionValueschem
}
