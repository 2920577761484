import {Stack} from '@mui/material'
import React from 'react'
import Label from '../customTable/Label'
import FixedNumber from '../customTable/FixedNumber'

export default function Card({data, bgcolor}: any) {
  return (
    <Stack
      bgcolor={bgcolor}
      width={{lg: 'calc(25% - 24px)', sm: 'calc(50% - 16px)', xs: '100%'}}
      minWidth={'226px'}
      p='16px 24px'
      border='1px solid #E8E8E8'
      borderRadius={'12px'}
      gap='24px'
    >
      <Label fw='600' fs='14px' color='#6A647D' text={data.name} />
      <FixedNumber color='#3F4254' fs='32px' fw='700' num={data.value} />
    </Stack>
  )
}
