import {Stack} from '@mui/material'
import React from 'react'
import Label from './Label'
import FileIcon from '../../svg/File'

export default function StaticInputFile({width, text}: any) {
  return (
    <Stack
      gap='9px'
      direction={'row'}
      borderRadius={'6.18px'}
      p='5px'
      width={width}
      border='1px solid var(--bs-gray-300)'
    >
      <FileIcon />
      <Stack gap='2px'>
        <Label text={text || 'commercialRegister'} fw='400' fs={'12px'} />
        {/* <Label color='#57595A' text='500KB' fw='400' fs={'8px'} /> */}
      </Stack>
    </Stack>
  )
}
