import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../../modules/customTable/Label'
import RangeChart from './RangeChart'

export default function Statistics({data}: any) {
  return (
    <Stack
      height={'430px'}
      // minWidth={'701px'}
      width={'100%'}
      gap='29px'
      borderRadius={'18px'}
      bgcolor={'#fff'}
      p='24px'
    >
      <Label color='#3F4254' text='statistics' fs='18px' fw='500' />
      <RangeChart
        color={'#0688E7'}
        name='returnedOrders'
        num={+data.refund_orders.percentage}
        value={data.refund_orders.count}
      />
      <RangeChart
        color={'#01C0F6'}
        name='totalReturnAmount'
        num={+data.refund_orders_amount.percentage}
        value={data.refund_orders_amount.count}
      />
      <RangeChart
        color={'#0688E7'}
        name='cancelledOrders'
        num={+data.cancel_orders.percentage}
        value={data.cancel_orders.count}
      />
      <RangeChart
        color={'#01C0F6'}
        name='activeProducts'
        num={+data.active_products.percentage}
        value={data.active_products.count}
      />
      <RangeChart
        color={'#0688E7'}
        name='inactiveProducts'
        num={+data.inactive_products.percentage}
        value={data.inactive_products.count}
      />
    </Stack>
  )
}
