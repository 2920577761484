import {Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import TitlePage from '../../customTable/TitlePage'
import Backages from './Backages'
import ActiveForm from './ActiveForm'
import SimpleLoading from '../../customTable/SimpleLoading'
import BackageTable from '../../subscriptions/backageTable'

export default function ApplicationMaker({android}: any) {
  const {t} = useTranslation()
  const [activeBackage, setActiveBackage] = useState(!false)
  const bannerInfo = {
    title: t('applicationMaker'),
    home: t('home'),
    linkHome: '/',
  }
  const [packageById, setPackageById] = useState(null)
  const [isLoadingTable, setIsLoadingTable] = useState(true)
  return (
    <Stack>
      <TitlePage info={bannerInfo} />
      <Stack mt='24px'>
        {!activeBackage ? (
          <>
            <Backages
              setIsLoadingTable={setIsLoadingTable}
              setPackageById={setPackageById}
              android={android}
            />
            {isLoadingTable ? <SimpleLoading /> : <BackageTable packageById={packageById} />}
          </>
        ) : (
          <ActiveForm android={android} />
        )}
      </Stack>
    </Stack>
  )
}
