import {useFormik} from 'formik'
import {useCallback, useEffect, useMemo, useState} from 'react'
import * as React from 'react'
import 'react-quill/dist/quill.snow.css'
import {useSelector} from 'react-redux'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {Boundary} from '../../../componet/Bondary'
import {RootState, useDispatch, withToastr} from '../../../store'
import * as Yup from 'yup'

import {FetchStatus} from '../../../store/types'
import {
  createSubCategoryAction,
  getCategoryName,
  getSubCategoryById,
  updateSubCategoryAction,
} from '../../../store/vender/catogory/thunk'
import {getProductName} from '../../../store/vender/products/thunk'
import {Language, languages} from '../../../types'
import {UsersListLoading} from '../../apps/user-management/users-list/components/loading/UsersListLoading'
import {TabsLanguages} from '../addCtogory/TabsLanguages'
import {ProductDetails} from './ProductDetails'
import {InitialValues, ValuesSchema} from './validationSchema'
import Catalog from '../../../Api/catalog'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, {AlertProps} from '@mui/material/Alert'
import {SubCategorySchema} from '../../../yup/subcategorySchema'
import ErrorMsg from '../../customTable/ErrorMsg'
import {useTranslation} from 'react-i18next'
import TabsWithFlag from '../../customTable/LangTabsWithFlag'
import Editor from '../../customTable/Editor'
import {toast} from 'react-toastify'
import {actions} from '../../../store/vender/Auth/auth.slice'
import {IconButton, Stack, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import MainBtn from '../../customTable/MainBtn'
import ValidationFun from '../../../yup/ValidationFun'
import OldInput from '../../customTable/OldInput'
import SubCatalog from '../../../Api/subCatalog'
import Product from '../../../Api/product'

const animatedComponents = makeAnimated()
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
export const AddSubCategory = () => {
  const dispatch = useDispatch()
  const {id} = useParams<{
    id?: string
  }>()
  const {subCatogory} = useSelector((state: RootState) => state.category)
  const [nameProduct, setNameProduct] = useState<any>([])
  const [nameCategory, setNameCategory] = useState<any>([])

  const getNameCategories = () => {
    Catalog.get((res: any) => {
      setNameCategory(res.data)
    }, setIsLoading)
  }
  const getNameProducts = () => {
    Product.get((res: any) => {
      setNameProduct(res.data)
    }, setIsLoading)
  }
  useEffect(() => {
    getNameCategories()
    getNameProducts()
  }, [])

  const location = useLocation()
  const navigate = useNavigate()
  const isEditCategory = location.pathname.includes('edit-sub-category')
  const store = useSelector((state: RootState) => state.vender)
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const [activeLang, setActiveLang] = useState<Language>('ar')
  const {t} = useTranslation()

  const fetchListProduct = useCallback(() => {
    if (id && isEditCategory) {
      SubCatalog.getById(
        id,
        (res: any) => {
          console.log(res.data)
          formik.setValues(res.data)
        },
        setIsLoading
      )
    }
  }, [dispatch, id, isEditCategory])

  //   useEffect
  useEffect(() => {
    fetchListProduct()
  }, [fetchListProduct, id])
  //   USEmemo

  const productListFormat = useMemo(() => {
    const list = nameProduct?.map((item: any) => {
      return {
        value: item.id,
        label: item.name && (item.name[store.lang] || item.name[store.defLang]),
      }
    })
    return list
  }, [nameProduct, activeLang])

  const nameCategoryListFormat = useMemo(() => {
    const list = nameCategory?.map((item: any) => {
      return {
        value: item.id,
        label: item.name && (item.name[store.lang] || item.name[store.defLang]),
      }
    })
    return list
  }, [nameCategory, activeLang])
  const [languages, setlanguages] = useState(['en'])

  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  const initialValues: InitialValues = useMemo(() => {
    let obj = {} as InitialValues
    obj.sort = ''
    obj.status = true
    obj.parent_id = ''
    obj.name = {} as {[key in Language]: string}
    obj.permalink = {} as {[key in Language]: string}
    obj.description = {} as {[key in Language]: string}
    obj.meta_title = {} as {[key in Language]: string}
    obj.meta_description = {} as {[key in Language]: string}
    obj.meta_keywords = {} as {[key in Language]: any}
    obj.products = []
    if (store.cashCataData.dataCashType === 'cata') {
      obj = store.cashCataData
    } else if (id && subCatogory && isEditCategory) {
      const productsData = (subCatogory as any).products?.map((x: any) => x.id)
      const parent_id = ''

      const {
        sort,
        status,
        name,
        description,
        permalink,
        meta_title,
        meta_description,
        meta_keywords,
      } = subCatogory as InitialValues

      obj.parent_id = parent_id
      obj.sort = sort || 0
      obj.status = status || false
      obj.name = name || ({} as {[key in Language]: string})
      obj.permalink = permalink || ({} as {[key in Language]: string})
      obj.description = description || ({} as {[key in Language]: string})
      obj.meta_title = meta_title || ({} as {[key in Language]: string})
      obj.meta_description = meta_description || ({} as {[key in Language]: string})
      obj.meta_keywords = meta_keywords || ({} as {[key in Language]: any})
      obj.products = productsData || []
    } else {
      for (const lang of store.storeLangs) {
        obj.name[lang] = ''
        obj.description[lang] = ''
        obj.meta_title[lang] = ''
        obj.meta_description[lang] = ''
        obj.meta_keywords[lang] = []
        obj.permalink[lang] = ''
        obj.products = []
      }
    }

    return obj
  }, [subCatogory, id, isEditCategory, nameCategoryListFormat])
  const [errors, setErrors] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const formik = useFormik<InitialValues>({
    initialValues: initialValues as InitialValues,
    // validationSchema: ValuesSchema,
    enableReinitialize: true,

    onSubmit: (values, {setSubmitting, resetForm}) => {
      if (id && subCatogory && isEditCategory) {
        ValidationFun(
          {
            id,
            ...values,
            //     parent_id: values.parent_id.value ? parseInt(values.parent_id.value) : null,
          },
          SubCategorySchema(languages),
          setErrors,
          () => {
            SubCatalog.put(
              {
                id,
                ...values,
                //     parent_id: values.parent_id.value ? parseInt(values.parent_id.value) : null,
              },
              () => {
                navigate(`/catalog/sub-category/${formik.values.parent_id}`)
              },
              setIsLoading
            )
          }
        )
      } else {
        const data = {
          ...values,
          sort: values.sort ? values.sort : 0,
        }
        ValidationFun(data, SubCategorySchema(languages), setErrors, () => {
          SubCatalog.add(
            data,
            () => {
              navigate(`/catalog/sub-category/${formik.values.parent_id}`)
            },
            setIsLoading
          )
        })
      }
    },
  })
  const [msgErr, setMsgErr] = useState('')
  //   need  to
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
    }),
    option: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
      color: 'var(--bs-heading-color, inherit)',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-body-bg)',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: 'var(--bs-app-bg-color)',
      color: 'var(--bs-heading-color, inherit)',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: 'var(--bs-heading-color, inherit)',
    }),
  }
  //  we  will be  add  the  formik   at  first  and  made validation

  useEffect(() => {
    if (store.defLang) {
      setActiveLang(store.defLang)
    }
    getLangs()
  }, [store.langs])
  const [langs, setLangs] = useState([])
  const getLangs = async () => {
    setLangs(store.langs)
  }
  const [open, setOpen] = React.useState(false)
  const handleClick = () => {
    setOpen(true)
  }
  const [tag, setTag] = useState('')
  const addTag = () => {
    if (!tag) {
      return
    }
    if (!formik.values.meta_keywords) {
      formik.setFieldValue(`meta_keywords[${activeLang}]`, [tag])
      setTag('')
    } else if (!formik.values.meta_keywords[`${activeLang}`]) {
      formik.setFieldValue(`meta_keywords[${activeLang}]`, [tag])

      setTag('')
    } else if (
      formik.values.meta_keywords[`${activeLang}`] &&
      !formik.values.meta_keywords[`${activeLang}`].includes(tag)
    ) {
      formik.setFieldValue(`meta_keywords[${activeLang}]`, [
        ...formik.values.meta_keywords[`${activeLang}`],
        tag,
      ])
      setTag('')
    } else {
      handleClick()
    }
  }
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      addTag()
    }
  }
  return (
    <div style={{marginTop: '28px'}} className='content d-flex flex-column flex-column-fluid'>
      <div className='post d-flex flex-column-fluid'>
        {/* //<!--begin::Container--> */}
        <div
          style={{
            paddingRight: '0',
            paddingLeft: '0',
            margin: 'auto 0',
            maxWidth: '10000000000000px',
          }}
          className='container-xxl'
        >
          <div
            // onSubmit={}
            style={{gap: '31px'}}
            className='form d-flex flex-column flex-lg-row'
            // noValidate
          >
            {/* //<!--begin::Aside column--> */}
            <div
              style={{marginTop: '0'}}
              className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 pt-1 '
            >
              <ProductDetails formik={formik} options={nameCategoryListFormat} />

              <div className='card card-flush py-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2>{t('status')}</h2>
                  </div>
                  <div className='card-toolbar'>
                    <div
                      style={{
                        background:
                          !formik.values.status || formik.values.status == 0 ? '#a2aca2' : 'green',
                      }}
                      className='rounded-circle  w-15px h-15px'
                    ></div>
                  </div>
                </div>

                <div className='card-body pt-0'>
                  {/* //<!--begin::Select2--> */}
                  <select
                    {...formik.getFieldProps('status')}
                    className='form-select mb-2'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Select an option'
                    value={!formik.values.status || formik.values.status == 0 ? 0 : 1}
                  >
                    {/* <option></option> */}
                    <option value={1}>{t('active')} </option>
                    <option value={0}> {t('inactive')}</option>
                  </select>
                  <div className='text-muted fs-7'>{t('setCategoryStatus')} </div>
                  {formik.touched.status && formik.errors.status && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.status}</div>
                    </div>
                  )}
                </div>
                {/* //<!--end::Card body--> */}
              </div>
            </div>
            {/* //<!--end::Aside column--> */}
            {/* //<!--begin::Main column--> */}

            <div
              style={{gap: '20px'}}
              className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'
            >
              <Stack position={'relative'}>
                <TabsWithFlag
                  languages={langs}
                  activeLang={activeLang}
                  setActiveLang={setActiveLang}
                  msgErr={errors && Object.keys(errors)[0]}
                />
              </Stack>
              <>
                {langs.map((e: any, i) => (
                  <>
                    {e.code === activeLang && (
                      <Stack key={i} sx={{background: 'var(--bs-app-bg-color)'}}>
                        <div className='card card-flush py-4'>
                          {/* //<!--begin::Card header--> */}
                          <div className='card-header'>
                            <div className='card-title'>
                              <h2>{t('general')}</h2>
                            </div>
                          </div>
                          {/* //<!--end::Card header--> */}
                          {/* //<!--begin::Card body--> */}
                          <div className='card-body pt-0'>
                            {/* //<!--begin::Input group--> */}
                            <div className='mb-10 fv-row'>
                              {/* //<!--begin::Label--> */}
                              <label className='required form-label'>{t('categoryName')}</label>

                              <OldInput
                                errors={errors}
                                formik={formik}
                                name={'name'}
                                child={activeLang}
                                onBlur={() => {
                                  if (id) {
                                    if (!formik.values.permalink[activeLang]) {
                                      formik.setFieldValue(
                                        `permalink[${activeLang}]`,
                                        formik.values.name[activeLang].trim().replace(/\s/g, '-')
                                      )
                                    }
                                  } else {
                                    formik.setFieldValue(
                                      `permalink[${activeLang}]`,
                                      formik.values.name[activeLang].trim().replace(/\s/g, '-')
                                    )
                                  }
                                  // formik.setFieldTouched(`name[${activeLang}]`, true)
                                  formik.setFieldTouched(`name[${activeLang}]`, true)
                                }}
                              />
                              <></>
                            </div>

                            <div>
                              {/* //<!--begin::Label--> */}
                              <label className='form-label required'>{t('description')}</label>
                              <Editor
                                errors={errors}
                                child={'description'}
                                formik={formik}
                                name={activeLang}
                              />

                              {/* //<!--end::Editor--> */}
                              {/* //<!--begin::Description--> */}
                              {/* <div style={{marginTop: '50px'}} className='text-muted fs-7'>
                        {t('setVisibility')}
                      </div> */}
                              <div style={{marginTop: '40px'}} className='mb-2 fv-row'>
                                {/* //<!--begin::Label--> */}
                                <label className='required form-label'>{t('permalink')} </label>
                                <OldInput
                                  errors={errors}
                                  formik={formik}
                                  name={'permalink'}
                                  child={activeLang}
                                  onBlur={() => {
                                    formik.setFieldValue(
                                      `permalink[${activeLang}]`,
                                      formik.values.permalink[activeLang].trim().replace(/\s/g, '-')
                                    )
                                  }}
                                />
                                {/* <input
                                  type='text'
                                  className='form-control form-control-lg '
                                  {...formik.getFieldProps(`permalink[${activeLang}]`)}
                                  onBlur={() => {
                                    formik.setFieldValue(
                                      `permalink[${activeLang}]`,
                                      formik.values.permalink[activeLang].trim().replace(/\s/g, '-')
                                    )
                                  }}
                                />
                                <ErrorMsg msgErr={msgErr} name='permalink' /> */}
                              </div>
                            </div>
                            {/* //<!--end::Input group--> */}
                          </div>
                          {/* //<!--end::Card header--> */}
                        </div>
                        {/* //<!--end::General options--> */}
                        {/* //<!--begin::Meta options--> */}
                        <div style={{marginTop: '34px'}} className='card card-flush py-4'>
                          {/* //<!--begin::Card header--> */}
                          <div className='card-header'>
                            <div className='card-title'>
                              <h2>{t('metaOptions')}</h2>
                            </div>
                          </div>
                          {/* //<!--end::Card header--> */}
                          {/* //<!--begin::Card body--> */}
                          <div className='card-body pt-0'>
                            {/* //<!--begin::Input group--> */}
                            <div className='mb-10'>
                              {/* //<!--begin::Label--> */}
                              <label className='form-label'>
                                {t('metaTagTitle')} {activeLang}
                              </label>
                              {/* //<!--end::Label--> */}
                              {/* //<!--begin::Input--> */}
                              <OldInput formik={formik} name={'meta_title'} child={activeLang} />

                              {/* //<!--end::Input--> */}
                              {/* //<!--begin::Description--> */}
                              <div className='text-muted fs-7'>{t('setMetaTagTitle')}</div>
                            </div>
                            {/* //<!--end::Input group--> */}
                            {/* //<!--begin::Input group--> */}
                            <div className='mb-10'>
                              {/* //<!--begin::Label--> */}
                              <label className='form-label'>
                                {' '}
                                {t('metaTagDescription')} {activeLang}
                              </label>
                              <textarea
                                style={{
                                  padding: '12.0625px 13px 12.5px',
                                  background: 'var(--bs-body-bg)',
                                  border: '1px solid var(--bs-gray-300)',
                                  borderRadius: '6.18px',
                                  width: '100%',
                                  color: 'var(--bs-text-dark)',
                                }}
                                rows={6}
                                {...formik.getFieldProps(`meta_description[${activeLang}]`)}
                              />
                              {formik.touched.meta_description &&
                                formik.touched.meta_description[activeLang] &&
                                formik.errors.meta_description &&
                                formik.errors.meta_description[activeLang] && (
                                  <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                      {formik.errors.meta_description &&
                                        formik.errors.meta_description[activeLang]}
                                    </div>
                                  </div>
                                )}
                            </div>

                            <div>
                              {/* //<!--begin::Label--> */}
                              <label className='form-label'>
                                {t('metaTagKeywords')} {activeLang}
                              </label>
                              {/* //<!--end::Label--> */}
                              {/* //<!--begin::Editor--> */}
                              <>
                                <Stack position={'relative'}>
                                  <input
                                    type='text'
                                    value={tag}
                                    onChange={(e) => setTag(e.target.value)}
                                    className='form-control form-control-lg'
                                    onKeyDown={handleKeyDown}
                                  />
                                  <Stack
                                    // disabled={tag ? false : true}
                                    position={'absolute'}
                                    height={'100%'}
                                    width={'43px'}
                                    right={store.isRtl === 0 ? 0 : 'auto'}
                                    left={store.isRtl !== 0 ? 0 : 'auto'}
                                    borderRadius={store.isRtl === 0 ? '0 8px 8px 0' : '8px 0 0 8px'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    sx={{
                                      background: !tag ? '#afb2c1' : 'var(--main-color)',
                                      color: 'white',
                                      cursor: 'pointer',
                                    }}
                                    onClick={addTag}
                                  >
                                    <Typography sx={{fontSize: '20px'}}>+</Typography>
                                  </Stack>
                                </Stack>
                                <Stack m='20px 0' gap='15px' flexWrap={'wrap'} direction={'row'}>
                                  {formik.values.meta_keywords &&
                                    formik.values.meta_keywords[`${activeLang}`] &&
                                    typeof formik.values.meta_keywords[`${activeLang}`] !==
                                      'string' &&
                                    formik.values.meta_keywords[`${activeLang}`].map(
                                      (e: any, i: any) => (
                                        <Stack
                                          gap='15px'
                                          minWidth={'60px'}
                                          p='3px 8px'
                                          borderRadius={'6px'}
                                          sx={{background: '#f5f8fa'}}
                                          direction={'row'}
                                          justifyContent={'space-between'}
                                          alignItems={'center'}
                                        >
                                          <Typography sx={{color: '#181c32'}}>{e}</Typography>
                                          <Typography
                                            onClick={() => {
                                              formik.setFieldValue(
                                                `meta_keywords[${activeLang}]`,
                                                formik.values.meta_keywords[`${activeLang}`].filter(
                                                  (a: any) => a !== e
                                                )
                                              )
                                            }}
                                            sx={{color: '#181c32', cursor: 'pointer'}}
                                          >
                                            {' '}
                                            <svg
                                              height='14'
                                              width='14'
                                              viewBox='0 0 20 20'
                                              aria-hidden='true'
                                              focusable='false'
                                              className='css-tj5bde-Svg'
                                            >
                                              <path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
                                            </svg>
                                          </Typography>
                                        </Stack>
                                      )
                                    )}
                                  <Snackbar
                                    open={open}
                                    autoHideDuration={6000}
                                    onClose={handleClose}
                                  >
                                    <Alert
                                      onClose={handleClose}
                                      severity='warning'
                                      sx={{width: '100%'}}
                                    >
                                      {t('msgtag')}
                                    </Alert>
                                  </Snackbar>
                                </Stack>
                              </>
                              {/* //<!--end::Editor--> */}
                              {/* //<!--begin::Description--> */}
                              <div className='text-muted fs-7'>
                                {t('setMetaTagKeywords')}
                                <code>,</code> {t('betweenEachKeyword')}
                              </div>
                            </div>
                            {/* //<!--end::Input group--> */}
                          </div>
                          {/* //<!--end::Card header--> */}
                        </div>
                      </Stack>
                    )}
                  </>
                ))}
              </>

              {/* </TabsLanguages> */}
              <div className='card card-flush py-4'>
                {/* //<!--begin::Card header--> */}
                <div className='card-body pt-0'>
                  <div style={{marginTop: '15px'}} className='d-flex flex-wrap gap-2 text-black'>
                    {/* //<!--begin::Label--> */}
                    <label className='form-label fs-2'>{t('sort')}</label>

                    <input
                      type='number'
                      min={0}
                      {...formik.getFieldProps(`sort`)}
                      className='form-control mb-2 form-control-lg'
                    />

                    <ErrorMsg msgErr={msgErr} name='sort' />
                  </div>
                </div>
                {/* //<!--end::Card header--> */}
                {/* //<!--begin::Card body--> */}
              </div>
              <div className='card card-flush py-4'>
                <div className='card-body pt-0'>
                  {/* //<!--begin::Input group--> */}
                  <div>
                    <div className='d-flex flex-wrap gap-5'>
                      {/* begin::Input group */}
                      <div style={{marginTop: '15px'}} className='fv-row w-100 flex-md-root'>
                        {/* begin::Label */}
                        <label className=' form-label'>{t('assignProducts')}</label>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          styles={customStyles}
                          options={productListFormat}
                          value={formik.values.products?.map((product: any) => {
                            return {
                              value: product,
                              label: productListFormat.find((item: any) => item.value === product)
                                ?.label,
                            }
                          })}
                          onChange={(options) => {
                            let products: Array<string> = []
                            options?.map((option: any) => {
                              products.push(option?.value)
                            })
                            formik.setFieldValue('products', products)
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.products && formik.errors.products && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.products}</div>
                          </div>
                        )}
                      </div>
                      {/* end::Input group */}
                      {/* begin::Input group */}
                    </div>
                  </div>
                </div>
                {/* //<!--end::Card header--> */}
              </div>

              {/* //<!--end::Automation--> */}

              <div style={{gap: '10px'}} className='d-flex justify-content-end'>
                {/* //<!--begin::Button--> */}

                <Link
                  onClick={() => {
                    dispatch(actions.setCashCataData({description: {}}))
                  }}
                  to={
                    formik.values.parent_id
                      ? `/catalog/sub-category/${formik.values.parent_id}`
                      : '/catalog/list'
                  }
                  className='btn btn-light me-5'
                >
                  {t('cancel')}
                </Link>
                {/* help-sub-cata */}
                <MainBtn
                  width='auto'
                  isLoading={isLoading}
                  handelSubmit={() => formik.handleSubmit()}
                  text='saveChanges'
                />
              </div>
              <div className='d-flex justify-content-end'>
                <ErrorMsg msgErr={msgErr} name='' />
              </div>
            </div>
            {/* //<!--end::Main column--> */}
          </div>
        </div>
        {/* //<!--end::Container--> */}
      </div>
      {/* </Boundary> */}
    </div>
  )
}
