import {Typography} from '@mui/material'
import {Stack} from '@mui/system'
import React from 'react'
import {useTranslation} from 'react-i18next'
import Label from '../../customTable/Label'
import FixedNumber from '../../customTable/FixedNumber'

export default function OrderInfo({order, mt}: any) {
  const {t} = useTranslation()
  return (
    <Stack gap='16px' width={'300px'} mt={mt || '30px'}>
      {mt && <Label color='#7E8299' text='paymentDetails' />}
      <Stack width='100%' justifyContent={'space-between'} direction={'row'}>
        <Typography sx={{fontSize: '15px', fontWeight: '400', color: '#7E8299'}}>
          {t('subtotal')}
        </Typography>
        <Typography sx={{fontSize: '15px', fontWeight: '400', color: '#7E8299'}}>
          <FixedNumber num={order.sub_total} />
        </Typography>
      </Stack>
      <Stack width='100%' justifyContent={'space-between'} direction={'row'}>
        <Typography sx={{fontSize: '15px', fontWeight: '400', color: '#7E8299'}}>
          {t('discount')}
        </Typography>
        <Typography sx={{fontSize: '15px', fontWeight: '400', color: '#7E8299'}}>
          <FixedNumber num={order.total - order.sub_total} />
        </Typography>
      </Stack>
      <Stack width='100%' justifyContent={'space-between'} direction={'row'}>
        <Typography sx={{fontSize: '15px', fontWeight: '400', color: '#7E8299'}}>
          {t('coupon')}
          {/* ({order.coupon_discount}) */}
        </Typography>
        <Typography sx={{fontSize: '15px', fontWeight: '400', color: '#7E8299'}}>
          <FixedNumber num={order.coupon_discount_amount} />
        </Typography>
      </Stack>
      <Stack width='100%' justifyContent={'space-between'} direction={'row'}>
        <Typography sx={{fontSize: '15px', fontWeight: '400', color: '#7E8299'}}>
          {t('shipping')}
        </Typography>
        <Typography sx={{fontSize: '15px', fontWeight: '400', color: '#7E8299'}}>
          <FixedNumber num={order.shipping_cost} />
        </Typography>
      </Stack>
      <Stack width='100%' justifyContent={'space-between'} direction={'row'}>
        <Typography sx={{fontSize: '15px', fontWeight: '400', color: '#7E8299'}}>
          {t('tax')}
        </Typography>
        <Typography sx={{fontSize: '15px', fontWeight: '400', color: '#7E8299'}}>
          <FixedNumber num={order.tax} />
        </Typography>
      </Stack>
      <Stack
        borderTop={'1px solid var(--bs-gray-300)'}
        pt={'16px'}
        width='100%'
        justifyContent={'space-between'}
        direction={'row'}
      >
        <Typography sx={{fontSize: '17px', fontWeight: '700', color: 'var(--bs-body-color)'}}>
          {t('grandTotal')}
        </Typography>
        <Typography sx={{fontSize: '17px', fontWeight: '700', color: 'var(--bs-body-color)'}}>
          <FixedNumber num={order.total} />
        </Typography>
      </Stack>
    </Stack>
  )
}
