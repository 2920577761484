import React, {useEffect} from 'react'
import Dialog from '@mui/material/Dialog'

import Slide from '@mui/material/Slide'
import {TransitionProps} from '@mui/material/transitions'
import {IconButton, Stack} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import {useIntl} from 'react-intl'
import OptionForm from './OptionForm'
import {useTranslation} from 'react-i18next'
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function AddRow({
  type,
  setOptionValue,
  optionValue,
  option_id,
  values,
  isSelected,
  setIsSelected,
  options,
  setIsGetOption,
  getSelectedOptions,
  setValues,
}: any) {
  const [open, setOpen] = React.useState(false)
  useEffect(() => {
    if (open && values.is_upload_image === 0) {
      setIsGetOption(true)
      getSelectedOptions()
    }
  }, [open])
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const intl = useIntl()
  const {t} = useTranslation()

  return (
    <div>
      {/* {intl.formatMessage({id: 'TEST'})} */}
      <Stack
        onClick={handleClickOpen}
        height={'37px'}
        sx={{borderTop: '1px solid var(--bs-gray-300)'}}
        direction={'row'}
        width='100%'
      >
        <Stack
          width={'96%'}
          sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
        ></Stack>

        <Stack alignItems={'center'} justifyContent={'center'} width={'42px'}>
          <IconButton
            sx={{background: '#4B6FED', borderRadius: '4px', width: '20px', height: '20px'}}
          >
            <AddIcon sx={{color: 'white', padding: '2px'}} />
          </IconButton>
        </Stack>
      </Stack>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)', // تعديلاتك الخاصة هنا
          },
          '& .MuiDialog-paperWidthSm': {
            maxWidth: '600px', // تعديلاتك الخاصة هنا
          },
        }}
      >
        <OptionForm
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          type={type}
          setValues={setValues}
          values={values}
          setOpen={setOpen}
          optionValue={optionValue}
          setOptionValue={setOptionValue}
          open={open}
          option_id={option_id}
          options={options}
        />
      </Dialog>
    </div>
  )
}
