import {Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import BasicSpeedDial from '../option/Actions'
import Actons from '../option/Actions'
import VariationsAction from './VariationsAction'
import EditPopUp from './EditPopUp'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../store'
import DeletedMsg from '../../../../customTable/DeleteMsg'
import {useParams} from 'react-router-dom'
import Variations from '../../../../../Api/variations'

export default function VariationsRow({item, image, i, setAdded, rows, setRows}: any) {
  const [isDelete, setIsDelete] = useState(true)
  const [open, setOpen] = React.useState(false)
  const [data, setData] = useState([])
  const [editItem, setEditItem] = useState(item)
  const store = useSelector((state: RootState) => state.vender)
  const [openMsg, setOpenMsg] = useState(false)
  const {id} = useParams()
  const [isLoadingVariations, setIsLoadingVariations] = useState(false)

  const handelDelete = () => {
    setAdded(true)
    Variations.destoyVariations(
      item,
      id,
      () => {
        setAdded(false)
        setRows(rows.filter((e: any) => e.id !== item.id))
        setIsDelete(false)
      },
      setIsLoadingVariations
    )
  }
  return (
    <>
      {isDelete && (
        <Stack
          sx={{
            borderTop: '1px solid var(--bs-gray-300)',
            borderBottom: '1px solid var(--bs-gray-300)',
          }}
          direction={'row'}
          width='100%'
        >
          <Stack
            width={'76px'}
            justifyContent={'center'}
            sx={{
              borderRight: '1px solid var(--bs-gray-300)',
              fontSize: '14px',
              fontWeight: 500,
              padding: '12px',
            }}
          >
            #{i + 1}
          </Stack>
          <Stack
            height={'120px'}
            width={'250px'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <img
              style={{maxWidth: '80px', maxHeight: '80px'}}
              src={editItem.image || image}
              alt=''
            />
          </Stack>
          <Stack
            width={'250px'}
            justifyContent={'center'}
            gap={'10px'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <Stack sx={{overflowX: 'auto'}}>
              {editItem.product_variations.map((e: any, i: number) => (
                <Stack gap={'5px'} direction={'row'} key={i}>
                  <Typography align='center'>
                    {e.option.name
                      ? e.option.name[store.lang] || e.option.name[store.defLang]
                      : 'any'}{' '}
                    :
                  </Typography>
                  <Typography align='center'>
                    {e.option_detail && e.option_detail.option_value
                      ? e.option_detail.option_value[store.lang] ||
                        e.option_detail.option_value[store.defLang]
                      : 'any'}{' '}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
          <Stack
            justifyContent={'center'}
            width={'93px'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <Typography align='center'>{editItem.quantity} </Typography>
          </Stack>
          <Stack
            justifyContent={'center'}
            width={'70px'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <Typography align='center'>{editItem.price} </Typography>
          </Stack>{' '}
          <Stack
            justifyContent={'center'}
            width={'70px'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <Typography align='center'>{editItem.weight} </Typography>
          </Stack>{' '}
          <Stack
            justifyContent={'center'}
            width={'70px'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <Typography align='center'>{editItem.height} </Typography>
          </Stack>
          <Stack
            justifyContent={'center'}
            width={'70px'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
          >
            <Typography align='center'>{editItem.width} </Typography>
          </Stack>
          <Stack
            justifyContent={'center'}
            sx={{borderRight: '1px solid var(--bs-gray-300)', padding: '12px'}}
            width={'70px'}
          >
            <Typography align='center'>{editItem.length}</Typography>
          </Stack>
          <Stack justifyContent={'center'} position={'relative'} width={'69px'}>
            <VariationsAction
              setAdded={setAdded}
              setOpen={setOpen}
              setIsDelete={setIsDelete}
              item={editItem}
              rows={rows}
              setRows={setRows}
              setOpenMsg={setOpenMsg}
            />
          </Stack>
          <EditPopUp
            oldItem={item}
            item={editItem}
            setItem={setEditItem}
            open={open}
            setOpen={setOpen}
          />
          <DeletedMsg open={openMsg} setOpen={setOpenMsg} onClick={handelDelete} />
        </Stack>
      )}
    </>
  )
}
