
import IBanner from '../interface/IBanner'
import ApisMethods from './apisMethods'

const Banner = {
  //*
  get: (
    searchText: string,
    rowsPerPage: number,
    page: number,
    callBack: any,
    setIsLoading: any
  ) => {
    ApisMethods().get(
      `/vendor/banners?page=${page}&per_page=${rowsPerPage}&search=${searchText}`,
      callBack,
      setIsLoading
    )
  },
  putStatus: (id: number | undefined, status: number, callBack: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/banners/status/${id}`, {status: status}, callBack, setIsLoading)
  },

  add: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/banners`, data, callback, setIsLoading)
  },
  put: (id: number | undefined, data: IBanner, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/banners/${id}`, data, callback, setIsLoading)
  },
  delete: (id: number | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(`/vendor/banners/${id}`, callback, setIsLoading)
  },
}
export default Banner
