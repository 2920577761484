import {Stack} from '@mui/material'
import React from 'react'
import NoData from '../../svg/NoData'
import Label from './Label'

export default function NoDataTable() {
  return (
    <td colSpan={7}>
      <Stack
        justifyContent={'center'}
        alignItems={'center'}
        gap='17px'
        m='auto'
        width='100%'
        height={'25vh'}
      >
        <NoData />
        <Label fs='18px' fw='500' text='noData' />
        <Label color='#B5B5C3' fs='12px' fw='500' text='noDataAvailable' />
      </Stack>
    </td>
  )
}
