/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from '../../../partials/layout/header-menus/Languages'
import {toAbsoluteUrl} from '../../../helpers'
import JwtService from '../../../../app/utils/TokenServices'
import {useDispatch} from 'react-redux'
import {actions} from '../../../../app/store/vender/Auth/auth.slice'
import {useTranslation} from 'react-i18next'
import {Avatar, Box, Stack} from '@mui/material'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../app/store'
import Vendor from '../../../../app/Api/vendor'

const HeaderUserMenu = ({languages}: any) => {
  const {currentUser, logout} = useAuth()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const handelLogout = async () => {
    await Vendor.logout(() => {
      JwtService.destroyToken()
      localStorage.clear()
      dispatch(actions.login(null))
    }, setIsLoading)
  }
  const {t} = useTranslation()

  useEffect(() => {
    dispatch(
      actions.getUserInfo({
        name: localStorage.getItem('name'),
        image: localStorage.getItem('image'),
        title: JSON.parse(localStorage.getItem('title') || '{}'),
        logo: localStorage.getItem('logoStore'),
      })
    )
  }, [])
  const store = useSelector((state: RootState) => state.vender)
  const {lang, defLang} = useSelector((state: RootState) => state.vender)

  return (
    <Box
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div style={{gap: '20px'}} className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-2'>
            <Avatar
              sx={{borderRadius: '8px'}}
              alt='Logo'
              src={store.userInfo?.logo || '/media/avatars/300-1.jpg'}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.first_name}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'> */}
              {store.userInfo?.title &&
                (store.userInfo?.title[lang] || store.userInfo?.title[defLang])}
              {/* </span> */}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          {t('myProfile')}
        </Link>
      </div>

      <div className='separator my-2'></div>
      <Stack sx={{display: {md: 'none', xs: 'block'}}}>
        <Languages languages={languages} />
      </Stack>
      <div className='menu-item px-5 my-1'>
        <Link to='/settings' className='menu-link px-5'>
          {t('storeSettings')}
        </Link>
      </div>
      <div className='menu-item px-5'>
        <a onClick={handelLogout} className='menu-link px-5'>
          {t('signOut')}
        </a>
      </div>
    </Box>
  )
}

export {HeaderUserMenu}
