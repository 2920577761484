import React from 'react'

export default function UpdateLogo() {
  return (
    <svg
      width='156'
      height='75'
      viewBox='0 0 156 75'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        opacity='0.6'
        x='0.5'
        y='0.5'
        width='155'
        height='74'
        rx='7.5'
        fill='white'
        stroke='#D8D8D8'
      />
      <g clip-path='url(#clip0_146_1218)'>
        <path
          d='M73.2086 29.1706H76.3701V44.5464C76.3701 44.7464 76.5241 44.91 76.7123 44.91H79.2791C79.4674 44.91 79.6214 44.7464 79.6214 44.5464V29.1706H82.7914C83.0781 29.1706 83.2364 28.8207 83.061 28.5843L78.2695 22.1395C78.2375 22.0961 78.1966 22.0609 78.1499 22.0367C78.1032 22.0126 78.0519 22 78 22C77.9481 22 77.8968 22.0126 77.8501 22.0367C77.8034 22.0609 77.7625 22.0961 77.7305 22.1395L72.939 28.5798C72.7636 28.8207 72.9219 29.1706 73.2086 29.1706ZM93.6578 43.1829H91.0909C90.9027 43.1829 90.7487 43.3465 90.7487 43.5465V50.5458H65.2513V43.5465C65.2513 43.3465 65.0973 43.1829 64.9091 43.1829H62.3422C62.154 43.1829 62 43.3465 62 43.5465V52.5456C62 53.3501 62.6118 54 63.369 54H92.631C93.3882 54 94 53.3501 94 52.5456V43.5465C94 43.3465 93.846 43.1829 93.6578 43.1829Z'
          fill='#009AFF'
        />
      </g>
      <defs>
        <clipPath id='clip0_146_1218'>
          <rect width='32' height='32' fill='white' transform='translate(62 22)' />
        </clipPath>
      </defs>
    </svg>
  )
}
