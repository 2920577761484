import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import FixedNumber from '../../../modules/customTable/FixedNumber'

export default function Card({name, per, num}: any) {
  const {t} = useTranslation()

  return (
    <Stack
      borderRadius={'12px'}
      minWidth={'240px'}
      width={{xl: 'calc(25% - 10px)', sm: 'calc(50% - 10px)', xs: '100%'}}
      bgcolor={'var(--bs-app-footer-bg-color)'}
      // textAlign={'center'}
      p='16px 12px 25px 24px'
      gap='30px'
    >
      <Stack justifyContent={'space-between'} direction={'row'}>
        <Typography sx={{color: '#A1A5B7', fontSize: '14px', fontWeight: '600'}}>{name}</Typography>
        <Typography
          sx={{
            color: per >= 0 ? '#50CD89' : 'red',
            background: per >= 0 ? '#50cd8936' : '#ff000036',
            padding: '1px 17px',
            borderRadius: '8px',
            height: '21.5px',
          }}
        >
          <Stack direction={'row'}>
            <FixedNumber isNum num={per} />%
          </Stack>
        </Typography>
      </Stack>
      <Typography sx={{fontSize: '30px', fontWeight: '700'}}>
        {' '}
        <FixedNumber isNum num={num} />
      </Typography>
    </Stack>
  )
}
