import {Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function StatusOrder({text, state}: any) {
  const {t} = useTranslation()
  return (
    <Stack
      justifyContent={'center'}
      height={'100%'}
      sx={{
        textAlign: 'center',
        padding: state ? '5px 6px' : '12.0625px 13px 12.5px',
        background:
          state && text === 'pending'
            ? '#ffd8424f'
            : state && text === 'preparing'
            ? '#5d90f138'
            : state && text === 'in_progress'
            ? '#63b2dd24'
            : state && text === 'refunded'
            ? '#f8f5ff7a'
            : state && text === 'not_active'
            ? '#5959597a'
            : state && (text === 'completed' || text === 'active' || text === 'complete')
            ? '#30eb8940'
            : state && (text === 'canceled' || text === 'blocked')
            ? '#ddb3b340'
            : '#009fff3d',
        border: state ? 'none' : 'auto',
        color:
          state && text === 'pending'
            ? '#FFC700'
            : state && text === 'preparing'
            ? '#7239EA'
            : state && text === 'in_progress'
            ? '#009EF7'
            : state && text === 'refunded'
            ? '#F1416C'
            : state && text === 'not_active'
            ? '#ffffff'
            : state && (text === 'completed' || text === 'active' || text === 'complete')
            ? '#50CD89'
            : state && (text === 'canceled' || text === 'blocked')
            ? '#e32046'
            : '#1a39e5',
        borderRadius: '8.18px',
        width: '100%',
        '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
          {
            paddingRight: '0px !important',
          },
        '& .css-1k0sxlf': {
          padding: state ? '5px 6px !important' : '12.0625px 13px 12.5px !important',
        },
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          border: 'none !important',
        },
      }}
    >
      {t(text)}
    </Stack>
  )
}
