import React, {useState, ChangeEvent} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Avatar, Box, Stack, Typography} from '@mui/material'
import Language from '../../interface/ILang'
interface IStateLang {
  activeLang: string
  setActiveLang: React.Dispatch<React.SetStateAction<string>>
  languages: any
  msgErr?: string
}
export default function TabsWithFlag({activeLang, setActiveLang, languages, msgErr}: IStateLang) {
  const handelTabs = (e: any) => {
    setActiveLang(e.code)
  }

  return (
    <Box display={'flex'}>
      <Stack
        sx={{
          background: 'var(--bs-body-bg)',
          // padding: '16px',
          borderRadius: '8px',
          border: '1px solid var(--bs-gray-300)',
        }}
        direction={'row'}
        // gap='10px'
      >
        {languages.map((e: any, i: any) => (
          <li
            key={i}
            style={{display: 'block', position: 'relative'}}
            className={`nav-item ${
              e.code === activeLang ? 'active tab-active bg-opacity-100' : 'bg-opacity-80'
            }`}
          >
            {' '}
            {msgErr?.includes(e.code) && (
              <Typography
                sx={{
                  width: '15px',
                  height: '15px',
                  background: 'red',
                  position: 'absolute',
                  borderRadius: '50%',
                  top: '-7px',
                }}
              ></Typography>
            )}
            <Stack
              gap={'4px'}
              direction={'row'}
              justifyContent={'center'}
              sx={{
                color:
                  e.code === activeLang
                    ? 'var(--bs-heading-color, inherit) !important'
                    : 'var(--bs-gray-800)',
                background: e.code === activeLang ? 'var(--bs-gray-300)' : '',
                padding: '12px 0',
                borderRadius: '8px',
                cursor: 'pointer',
                width: '106px',
              }}
              data-bs-toggle='tab'
              onClick={() => handelTabs(e)}
              //  href={`#${name}_${lang.lang}`}
            >
              <span className='fs-8 rounded '>
                <Avatar className='w-20px h-20px ' src={toAbsoluteUrl(e.flag)} alt={e.lang} />
              </span>
              <Typography>{e.lang}</Typography>
            </Stack>
          </li>
        ))}
      </Stack>
    </Box>
  )
}
