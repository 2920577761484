import React, {useState} from 'react'
import {ActionsCell} from '../../../componet/Menu'
import {Box, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Switch from '../../../componet/Toastr/Switch'
import Media from '../../../Api/media'
import PageApi from '../../../Api/Page'
import {useNavigate} from 'react-router-dom'
import MenuApi from '../../../Api/menu'
import SimpleLoading from '../../customTable/SimpleLoading'
import NameText from '../../customTable/NameText'

export default function Row({item, i, setNum}: any) {
  const [state, setState] = useState(item.status)
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const navigate = useNavigate()

  const handelEdit = () => {
    navigate(`/storeManagement/edit-menu/${item.id}`)
  }
  const [isLoadingStatus, setIsLoadingStatus] = useState(false)
  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    MenuApi.putStatus(
      item.id,
      state === 1 ? 0 : 1,
      () => {
        setState(state === 1 ? 0 : 1)
      },
      setIsLoadingStatus
    )
  }
  const [isDeleted, setDeleted] = useState(true)
  const [isLoadingDeleted, setLoadingDeleted] = useState(!true)
  const handelDelete = () => {
    MenuApi.delete(
      item.id,
      () => {
        setNum((prev: any) => prev - 1)
        setDeleted(false)
      },
      setLoadingDeleted
    )
  }
  const store = useSelector((state: RootState) => state.vender)

  return (
    <>
      {isDeleted && (
        <>
          {' '}
          <tr className='fw-bold text-muted '>
            <td>
              <a className='text-dark fw-bold text-hover-primary fs-6'>{i + 1}</a>
            </td>
            <td style={{textAlign: 'start'}}>
              <NameText
                text={
                  editItem.title && (editItem.title[store.lang] || editItem.title[store.defLang])
                }
              />
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>
                {(editItem.parent &&
                  editItem.parent.title &&
                  (editItem.parent.title[store.lang] || editItem.parent.title[store.defLang])) ||
                  '-'}
              </span>{' '}
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.position}</span>{' '}
            </td>{' '}
            <td style={{textAlign: 'start'}} className='text-start'>
              {isLoadingStatus ? (
                <SimpleLoading />
              ) : (
                <Switch
                  name='status'
                  value={state === 1 ? true : false}
                  handleChange={
                    store.permissions &&
                    store.permissions.includes('menus_update') &&
                    handleStatusChange
                  }
                />
              )}{' '}
            </td>
            <td style={{textAlign: 'start'}} className='text-start'>
              {isLoadingDeleted ? (
                <SimpleLoading />
              ) : (
                <ActionsCell
                  onEdit={
                    store.permissions && store.permissions.includes('menus_update') && handelEdit
                  }
                  onDelete={
                    store.permissions && store.permissions.includes('menus_delete') && handelDelete
                  }
                  onView={null}
                />
              )}
            </td>
          </tr>
        </>
      )}
    </>
  )
}
