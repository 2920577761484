import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../../modules/customTable/Label'
import FixedNumber from '../../../modules/customTable/FixedNumber'

export default function CustomerStatus({color, name, value}: any) {
  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Stack direction={'row'} gap='3px' alignItems={'center'}>
        <svg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <circle cx='3' cy='3' r='3' fill={color || '#50CD89'} />
        </svg>
        <Label color='#667085' text={name} fw='400' fs='14px' />
      </Stack>
      <FixedNumber isNum color='#000000' num={value} fw='600' fs='14px' />
    </Stack>
  )
}
