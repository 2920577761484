import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import JwtService from '../utils/TokenServices'
import {useDispatch} from 'react-redux'
import {actions} from '../store/vender/Auth/auth.slice'

type Callback = (response: any) => void
type ModuleFunction = (data: any) => any

export default function ApisMethods() {
  const post = async (
    url: string,
    data: any,
    callback: Callback,
    setIsLoading?: any,
    moduleFunc?: ModuleFunction
  ) => {
    try {
      setIsLoading(true)

      const newData = moduleFunc ? moduleFunc(data) : data

      const res = await Apiservices.post(url, newData)
      toast.success(res.data.message)
      callback(res.data.data)
      setIsLoading(false)
    } catch (error: any) {
      toast.error(error && error.response && error.response.data && error.response.data.message)
      setIsLoading(false)
    }
  }

  const put = async (
    url: string,
    data: any,
    callback: Callback,
    setIsLoading?: any,
    moduleFunc?: ModuleFunction
  ) => {
    try {
      setIsLoading(true)
      const newData = moduleFunc ? moduleFunc(data) : data
      const res = await Apiservices.put(url, newData)
      toast.success(res.data.message)
      callback(res.data.data)
      setIsLoading(false)
    } catch (error: any) {
      toast.error(error && error.response && error.response.data && error.response.data.message)
      setIsLoading(false)
    }
  }

  const destroy = async (url: string, callback: Callback, setIsLoading?: any, data?: any) => {
    try {
      setIsLoading(true)
      const res = await Apiservices.delete(url, {
        params: data,
      })
      toast.success(res.data.message)
      callback(res.data.data)
      setIsLoading(false)
    } catch (error: any) {
      toast.error(error && error.response && error.response.data && error.response.data.message)
      setIsLoading(false)
    }
  }

  const get = async (url: string, callback: Callback, setIsLoading?: any, msgErr?: any) => {
    try {
      setIsLoading(true)

      const res = await Apiservices.get(url)

      callback(res.data)
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)

      if (error.response.status == 401) {
        JwtService.destroyToken()
      }
      if (!msgErr) {
        toast.error(error && error.response && error.response.data && error.response.data.message)
      }
    }
  }

  return {
    post,
    put,
    destroy,
    get,
  }
}
